interface RequiredConstraints {
  frameRate: number;
  width: number;
  height: number;
  aspectRatio: number;
}

export const VideoConstraints: MediaTrackConstraintSet & RequiredConstraints = {
  aspectRatio: 368 / 302,
  frameRate: 15,
  width: 480,
  height: 480,
  resizeMode: 'crop-and-scale',
};
