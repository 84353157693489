import * as React from 'react';

import { Switch } from 'react-router-dom';

import { FBoxV, Error, PageNotFound } from '@badger/design-system';
import { makeStyles } from '@material-ui/core/styles';

import { Landing, SpaceMissing, UnsupportedWarning } from 'pages';
import { NavBar } from 'components';

import { GARoute } from './GARoute';
import background from 'assets/background.png';
import { AuthRoute } from './AuthRoute';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    overflow: 'auto',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    '--navBarHeight': 'clamp(44px, calc(1.5vw + 35px), 64px)',
  },
});

export const EventRouter = () => {
  const classes = useStyles();

  return (
    <FBoxV className={classes.root}>
      <NavBar />
      <Switch>
        <GARoute path={['/error']} component={Error} />
        <GARoute path={['/unsupported-warning']} component={UnsupportedWarning} />
        <GARoute path={['/space-missing']} component={SpaceMissing} />
        <AuthRoute exact path="/">
          <Landing />
        </AuthRoute>
        <GARoute component={PageNotFound} />
      </Switch>
    </FBoxV>
  );
};
