import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { FBoxV } from '@badger/design-system';

import {
  TablesWithUsers_socialSpace_tables,
  TablesWithUsers_socialSpace_tables_users,
} from 'gql/types/TablesWithUsers';

import { SocialProfileListItem } from './SocialProfileListItem';
import { SocialSpaceContext } from '../context';
import { SocialSpaceFormatRoaming } from 'gql/types/globals';

// TODO put somewhere common
import { useGetSocialSpaceFormat } from '../manage/format.gql';
import { useLazySocialSpaceUsers } from './SpaceUsersPanel.gql';

type Table = TablesWithUsers_socialSpace_tables;
type User = TablesWithUsers_socialSpace_tables_users;

interface Props {
  open: boolean;
}

function compareUsers(user1: User, user2: User) {
  return _.isEqual(_.omit(user1.profile, 'id'), _.omit(user2.profile, 'id'));
}

export const RecentUsersList = (props: Props) => {
  const { t } = useTranslation();
  const { spaceId, spaceSlug } = React.useContext(SocialSpaceContext);
  const { data: spaceFormat } = useGetSocialSpaceFormat({ slug: spaceSlug });

  const { spaceTablesUsers } = React.useContext(SocialSpaceContext);
  const [querySocialSpaceUsers, { data: spaceUsers, refetch: refetchSocialSpaceUsers }] = useLazySocialSpaceUsers({
    spaceId,
    activeLastMinutes: 6 * 60,
  });

  React.useEffect(() => {
    if (props.open) {
      if (spaceUsers) {
        refetchSocialSpaceUsers?.();
      } else {
        querySocialSpaceUsers();
      }
    }
  }, [props.open, querySocialSpaceUsers, refetchSocialSpaceUsers, spaceUsers]);

  const renderProfile = (user: User, localUser: boolean, showInviteToOneOnOne: boolean) => {
    const profile = user.profile;
    return (
      <SocialProfileListItem
        userId={user.id}
        key={profile.id}
        name={profile.name}
        socialBlurb={profile.socialBlurb}
        jobTitle={profile.jobTitle}
        company={profile.company}
        twitter={profile.twitter}
        linkedIn={profile.linkedIn}
        localUser={localUser}
        showInviteToOneOnOne={showInviteToOneOnOne}
      />
    );
  };

  const showOneOnOneInvite = () => {
    if (
      spaceFormat?.socialSpace?.format.roaming === SocialSpaceFormatRoaming.NO_ROAMING ||
      !spaceFormat?.socialSpace?.format.enableOneOnOne
    ) {
      return false;
    }
    return false;
  };

  const activeUsers = spaceTablesUsers?.tables.flatMap((table) => table.users) ?? [];
  const recentUsers = _.differenceWith(
    _.uniqWith(spaceUsers?.socialSpaceUsers, compareUsers),
    activeUsers,
    compareUsers
  );

  const renderRecentProfiles = () => {
    return (
      <FBoxV>{recentUsers.map((user) => user.profile.name && renderProfile(user, false, showOneOnOneInvite()))}</FBoxV>
    );
  };

  return (
    <FBoxV m={1} alignItems="stretch">
      {recentUsers.length > 0 && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{t('spaceUsers.recentUsers')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FBoxV>
              <Typography>{t('spaceUsers.recentUsersDescription')}</Typography>
              {renderRecentProfiles()}
            </FBoxV>
          </AccordionDetails>
        </Accordion>
      )}
    </FBoxV>
  );
};
