import React from 'react';

import { useSnackbar } from 'notistack';
import analytics from 'analytics';
import { CookieSetting, hasCookie, setCookieSetting } from 'cookieSettings';
import { CookieConsentSnackBar } from 'components/socialSpace/snackbar/CookieConsentSnackBar';
import { isIframe } from 'util/isIframe';

export function CookieBanner() {
  const { enqueueSnackbar } = useSnackbar();

  const onAcceptAll = React.useCallback(() => {
    setCookieSetting(CookieSetting.AllCookies);
    analytics.trackCookieBannerAcceptAll();
  }, []);

  const onDeclineOptional = React.useCallback(() => {
    setCookieSetting(CookieSetting.NoOptional);
    analytics.trackCookieBannerDeclineOptional();
  }, []);

  React.useEffect(() => {
    if (!isIframe && !hasCookie()) {
      const key = 'Cookie consent';
      enqueueSnackbar(
        <CookieConsentSnackBar snackKey={key} onAcceptAll={onAcceptAll} onDeclineOptional={onDeclineOptional} />,
        {
          key,
          persist: true,
          preventDuplicate: true,
        }
      );
    }
  }, [enqueueSnackbar, onAcceptAll, onDeclineOptional]);

  return null;
}
