import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AlarmIcon from '@material-ui/icons/Alarm';

import { style } from '@badger/design-system';

import { ActionSnackBar, ActionSnackBarProps } from './ActionSnackBar';

interface StyleProps {
  actionDelay: number;
  firstColor?: string;
  secondColor?: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  bar: {
    x: 0,
    y: 0,
    height: '100%',
    position: 'absolute',
    animation: '$countdown linear',
    animationDuration: (props) => `${props.actionDelay}s`,
    zIndex: -1,
    '--firstColor': (props) => props.firstColor ?? style.colors.backgroundDark,
    '--secondColor': (props) => props.secondColor ?? style.colors.primary,
  },
  '@keyframes countdown': {
    '0%': {
      width: '100%',
      backgroundColor: 'var(--firstColor)',
    },
    '100%': {
      width: '0%',
      backgroundColor: 'var(--secondColor)',
    },
  },
});

type Props = {
  completedMessage?: string;
} & StyleProps &
  ActionSnackBarProps;

export function ProgressSnackBar(props: Props) {
  const classes = useStyles(props);
  const [completed, setCompleted] = React.useState(false);

  const onCompleted = () => {
    setCompleted(true);
    if (!props.noAction) {
      props.action();
    }
  };

  return (
    <ActionSnackBar
      {...props}
      icon={props.icon ?? <AlarmIcon />}
      message={completed && props.completedMessage ? props.completedMessage : props.message}
    >
      <Box className={classes.bar} onAnimationEnd={onCompleted} />
    </ActionSnackBar>
  );
}
