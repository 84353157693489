import React from 'react';
import _ from 'lodash';

import { Tooltip, Typography, TypographyProps } from '@material-ui/core';
import Truncate from 'react-truncate';

type Props = TypographyProps & {
  children: string;
  lines?: number | false;
};

export function TruncateTypography(props: Props) {
  const [ready, setReady] = React.useState(false);

  const [tooltipEnabled, setTooltipEnabled] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(false);

  React.useEffect(() => {
    if (!ready) {
      //@ts-ignore
      if (document.fonts?.ready?.then) {
        //@ts-ignore
        document.fonts?.ready?.then(() => setReady(true));
      } else {
        setReady(true);
      }
    }
  }, [ready]);

  const onShowTooltip = () => {
    if (tooltipEnabled) {
      setShowTooltip(true);
    }
  };

  return (
    <Tooltip open={showTooltip} onOpen={onShowTooltip} onClose={() => setShowTooltip(false)} title={props.children}>
      <Typography {..._.omit(props, 'children', 'lines')}>
        <Truncate onTruncate={setTooltipEnabled} trimWhitespace lines={props.lines}>
          {props.children}
        </Truncate>
      </Typography>
    </Tooltip>
  );
}
