import React from 'react';
import AgoraRTC, { ConnectionDisconnectedReason } from 'agora-rtc-sdk-ng';

const AGORA_APP_ID = '3c5887021fbc4c6990447634415c5324';

export interface Credentials {
  uid: string;
  channelName: string;
  token: string;
}

type ConnectionState = 'DISCONNECTED' | 'CONNECTING' | 'RECONNECTING' | 'CONNECTED' | 'DISCONNECTING' | 'KICKED';

export function useAgoraClient() {
  const [connectionState, setConnectionState] = React.useState<ConnectionState>();
  const client = React.useMemo(() => AgoraRTC.createClient({ mode: 'live', codec: 'vp8' }), []);

  const onConnectionStateChange = React.useCallback(
    (newState: ConnectionState, prevState: ConnectionState, reason?: ConnectionDisconnectedReason) => {
      if (reason === 'UID_BANNED') {
        setConnectionState('KICKED');
      } else {
        setConnectionState(newState);
      }
    },
    []
  );

  React.useEffect(() => {
    client.on('connection-state-change', onConnectionStateChange);
    return () => {
      client.off('connection-state-change', onConnectionStateChange);
    };
  }, [client, onConnectionStateChange]);

  const disconnect = React.useCallback(async () => {
    client?.leave();
  }, [client]);

  React.useEffect(() => {
    if (client) {
      return () => {
        client.leave();
      };
    }
  }, [client]);

  React.useEffect(() => {
    window.addEventListener('beforeunload', disconnect);
    window.addEventListener('pagehide', disconnect);
    return () => {
      disconnect();
      window.removeEventListener('beforeunload', disconnect);
      window.removeEventListener('pagehide', disconnect);
    };
  }, [disconnect]);

  const connect = React.useCallback(
    async (credentials: Credentials, role: 'host' | 'audience' = 'audience') => {
      await client.setClientRole(role);
      if (client.connectionState === 'CONNECTED' || client.connectionState === 'CONNECTING') {
        await client.renewToken(credentials.token);
        return;
      }
      const uid = Number.parseInt(credentials.uid);
      await client.join(AGORA_APP_ID, credentials.channelName, credentials.token, uid);
    },
    [client]
  );

  return {
    connect,
    disconnect,
    client,
    connectionState,
  };
}
