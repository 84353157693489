import * as React from 'react';

import { useRouteMatch, useLocation, Route, RouteProps } from 'react-router-dom';
import { CircularProgress, Box } from '@material-ui/core';

import { useJoinSocialSpace } from './EnterSpaceRoute.gql';
import queryString from 'query-string';
import { EnterSpacePasswordDialog } from 'dialogs/EnterSpacePasswordDialog';

import { useRegisterGuest } from 'auth.gql';
import { useHasAccount } from 'auth';
import Analytics from 'analytics';

interface Props extends RouteProps {
  children: React.ReactNode;
}

export const EnterSpaceRoute = ({ children, ...rest }: Props) => {
  const [registerGuest] = useRegisterGuest();

  const userHasAccount = useHasAccount();

  React.useEffect(() => {
    const _registerGuest = async () => {
      if (!userHasAccount) {
        await registerGuest();
      }
    };

    _registerGuest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    Analytics.trackPageView(window.location.pathname + window.location.search);
  }, []);

  const [spaceJoined, setSpaceJoined] = React.useState(false);
  const [needsPassword, setNeedsPassword] = React.useState(false);
  const [password, setPassword] = React.useState<string>();

  const spaceSlugMatch = useRouteMatch<{ spaceSlug: string }>('/in/:spaceSlug');
  const location = useLocation();
  const spaceSlug = spaceSlugMatch?.params?.spaceSlug;

  const [joinSocialSpace] = useJoinSocialSpace((data) => {
    if (data?.joinSocialSpace?.space?.id) {
      setSpaceJoined(true);
    } else {
      if (data.joinSocialSpace.error?.type === 'AUTHORIZATION_FAILED') {
        setNeedsPassword(true);
      }
    }
  });

  React.useEffect(() => {
    const password = queryString.parse(location.search).pw;
    setPassword(password ? `${password}` : undefined);
  }, [location.search]);

  React.useEffect(() => {
    if (userHasAccount && !spaceJoined) {
      joinSocialSpace({
        variables: {
          input: {
            spaceSlug,
            password,
          },
        },
      });
    }
  }, [spaceJoined, joinSocialSpace, password, spaceSlug, userHasAccount]);

  const onSetPassword = (password: string) => {
    setNeedsPassword(false);
    setPassword(password);
  };

  const loading = () => (
    <Box height="80vh" width="100%" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  );

  return (
    <Route
      {...rest}
      render={() =>
        !userHasAccount ? (
          loading()
        ) : spaceJoined ? (
          children
        ) : needsPassword ? (
          <EnterSpacePasswordDialog open={true} onSetPassword={onSetPassword} />
        ) : (
          loading()
        )
      }
    />
  );
};
