import { getLocal, setLocal } from 'local';
import { isSupported as twilioSupported } from 'twilio-video';

export function isWebRTCSupported() {
  return (
    typeof navigator === 'object' &&
    typeof navigator.mediaDevices === 'object' &&
    typeof navigator.mediaDevices.getUserMedia === 'function' &&
    typeof RTCPeerConnection === 'function'
  );
}

export function ignoreNotSupported() {
  setLocal('ignoreSupportedWarning', '1');
}

export function isSupported() {
  if (getLocal('ignoreSupportedWarning')) {
    return true;
  }
  return twilioSupported;
}
