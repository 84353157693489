import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';

import { RegisterPushSubscription, RegisterPushSubscriptionVariables } from 'gql/types/RegisterPushSubscription';
import { GetUserSubscription } from 'gql/types/GetUserSubscription';

const REGISTER_PUSH_SUBSCRIPTION = gql`
  mutation RegisterPushSubscription($input: RegisterPushSubscriptionInput!) {
    registerPushSubscription(input: $input) {
      error {
        type
      }
    }
  }
`;

export function useRegisterPushSubscription() {
  return useMutation<RegisterPushSubscription, RegisterPushSubscriptionVariables>(REGISTER_PUSH_SUBSCRIPTION);
}

const GET_USER_SUBSCRIPTION = gql`
  query GetUserSubscription {
    userSubscription {
      id
      vapidPublicKey
      endpoints
      spaceIds
      tags
    }
  }
`;

export function useGetUserSubscription() {
  return useQuery<GetUserSubscription>(GET_USER_SUBSCRIPTION);
}
