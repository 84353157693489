import * as React from 'react';
import { captureException } from '@sentry/browser';
import { Redirect, BrowserRouter, Route, Switch } from 'react-router-dom';

import { useRefreshToken } from 'auth.gql';
import { CHECK_INTERVAL, shouldRefresh } from 'auth';

import { CookieBanner } from 'components';

import { SocialSpaceRouter } from './SocialSpaceRouter';
import { EventRouter } from './EventRouter';
import { EnterSpaceRoute } from './EnterSpaceRoute';
import { GARoute } from './GARoute';
import { ChatError, ThanksForSharing } from 'pages';
import { isSupported } from 'util/isSupported';
import { EmbedPublicSpaces } from 'pages/EmbedPublicSpaces';

const Router = () => {
  const [initComplete, setInitComplete] = React.useState(false);

  const [refreshToken] = useRefreshToken();

  React.useEffect(() => {
    const refreshTokenInterval = setInterval(() => {
      const _refreshToken = async () => {
        await refreshToken();
        // TODO: When we have a login page, we should redirect there if this fails.
        // Though, not clear how this could happen. Unless it's a browser where setInterval will not fire in
        // a background tab.
      };

      if (shouldRefresh()) {
        _refreshToken();
      }
    }, CHECK_INTERVAL);

    return () => {
      clearInterval(refreshTokenInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const refresh = async () => {
      try {
        await refreshToken();

        setInitComplete(true);
      } catch (error) {
        captureException(error);
      }
    };

    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      {!isSupported() && <Redirect to="/unsupported-warning" push />}
      {initComplete ? (
        <Switch>
          <EnterSpaceRoute path="/in/">
            <SocialSpaceRouter />
          </EnterSpaceRoute>
          <GARoute path="/chat/error">
            <ChatError />
          </GARoute>
          <Route path="/embedPublicSpaces">
            <EmbedPublicSpaces />
          </Route>
          <Route path="/thanksForSharing">
            <ThanksForSharing />
          </Route>
          <Route path={['/error', '/']}>
            <EventRouter />
          </Route>
        </Switch>
      ) : (
        <div />
      )}
      <CookieBanner />
    </BrowserRouter>
  );
};

export default Router;
