import React from 'react';

import { Badge } from '@material-ui/core';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';

import { SocialSpaceContext } from '../context';

import { NavBarButton } from './NavBarButton';

interface Props {
  selected?: boolean;
  onClick?: () => void;
}

export function ChatButton(props: Props) {
  const [unreadMessages, setUnreadMessages] = React.useState(0);
  const { spaceSlug } = React.useContext(SocialSpaceContext);

  React.useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (event.data?.data?.name === spaceSlug) {
        if (event.data.eventName === 'unread-changed-by-subscription') {
          const messageAlert = event.data.data.alert;
          if (!messageAlert) {
            setUnreadMessages(0);
          }
        }
        // t is the system type of message
        else if (event.data.eventName === 'new-message' && !event.data.data.t) {
          setUnreadMessages(unreadMessages + 1);
        }
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [spaceSlug, unreadMessages]);

  const onClick = () => {
    setUnreadMessages(0);
    props.onClick?.();
  };

  return (
    <NavBarButton selected={props.selected} onClick={onClick}>
      <Badge badgeContent={unreadMessages} color="secondary">
        <ChatIcon />
      </Badge>
    </NavBarButton>
  );
}
