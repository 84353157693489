import React, { ReactNode } from 'react';
import _ from 'lodash';

import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useLocation } from 'react-router-dom';

const StyledAccordion = withStyles({
  root: {
    color: '#ffffff',
    border: 0,
    boxShadow: 'none',
    backgroundColor: 'inherit',
    '&$expanded': {
      margin: 0,
    },
    '& .MuiIconButton-root': {
      color: '#ffffff',
    },
    '& .MuiAccordionSummary-root': {
      justifyContent: 'flex-start',
    },
    '& .MuiAccordionSummary-content': {
      flexGrow: 0,
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px 16px',
    },
  },
  expanded: {},
})(Accordion);

export type LandingAccordionProps = AccordionProps & {
  id?: string;
  title: string;
  children: ReactNode;
};

export function LandingAccordion(props: LandingAccordionProps) {
  const location = useLocation();

  const linkedTo = props.id && location.hash === `#${props.id}`;
  const defaultExpanded = props.defaultExpanded !== undefined ? props.defaultExpanded : linkedTo || undefined;

  const scrollTo = (ref: HTMLElement | null) => {
    if (ref && linkedTo) {
      setTimeout(() => {
        ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);
    }
  };

  return (
    <StyledAccordion {..._.omit(props, 'id', 'title', 'children')} defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography id={props.id} ref={scrollTo} variant="h4">
          {props.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </StyledAccordion>
  );
}
