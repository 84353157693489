import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

import { RedirectSocialSpace, RedirectSocialSpaceVariables } from 'gql/types/RedirectSocialSpace';

export const REDIRECT_SOCIAL_SPACE = gql`
  mutation RedirectSocialSpace($input: RedirectSocialSpaceInput!) {
    redirectSocialSpace(input: $input) {
      error {
        type
      }
    }
  }
`;

export function useRedirectSocialSpace() {
  return useMutation<RedirectSocialSpace, RedirectSocialSpaceVariables>(REDIRECT_SOCIAL_SPACE);
}
