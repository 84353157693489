import React from 'react';

import { IAgoraRTCClient, IAgoraRTCRemoteUser, UID } from 'agora-rtc-sdk-ng';
import { logger } from 'logging';
import { RemoteVideoUsers, VideoUser } from 'util/VideoUser';
import { parseBroadcasterUserId } from 'util/broadcast';
import { useUserId } from 'auth';

export function useAgoraRemoteUsers(client: IAgoraRTCClient): [RemoteVideoUsers] {
  const [hosts, setHosts] = React.useState(new Map<string, VideoUser>());
  const userId = useUserId();

  const broadcasterIsCurrentUser = React.useCallback(
    (uid: UID) => {
      const numericUserId = Number.parseInt(userId ?? '0');
      return parseBroadcasterUserId(uid) === numericUserId;
    },
    [userId]
  );

  const userJoined = React.useCallback(
    async (user: IAgoraRTCRemoteUser) => {
      if (broadcasterIsCurrentUser(user.uid)) {
        return;
      }
      const uid = `${user.uid}`;
      let host = hosts.get(uid);
      if (!host) {
        host = new VideoUser(uid);
        const newHosts = new Map(hosts);
        newHosts.set(uid, host);
        setHosts(newHosts);
      }

      // must be after setting hosts to notify every interested party
      if (user.videoTrack) {
        await client.subscribe(user, 'video');
        host.emit('videoTrackChanged', user.videoTrack.getMediaStreamTrack());
      }
      if (user.audioTrack) {
        await client.subscribe(user, 'audio');
        host.emit('audioTrackChanged', user.audioTrack.getMediaStreamTrack());
      }

      return host;
    },
    [broadcasterIsCurrentUser, client, hosts]
  );

  React.useEffect(() => {
    client.on('user-joined', userJoined);
    return () => {
      client.off('user-joined', userJoined);
    };
  }, [client, userJoined]);

  const userLeft = React.useCallback(
    (user: IAgoraRTCRemoteUser) => {
      const uid = `${user.uid}`;
      const newHosts = new Map(hosts);
      newHosts.delete(uid);
      setHosts(newHosts);
    },
    [hosts]
  );

  React.useEffect(() => {
    client.on('user-left', userLeft);
    return () => {
      client.off('user-left', userLeft);
    };
  }, [client, userLeft]);

  const userPublished = React.useCallback(
    async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
      if (broadcasterIsCurrentUser(user.uid)) {
        return;
      }
      await client.subscribe(user, mediaType);

      const uid = `${user.uid}`;
      const host = hosts.get(uid) ?? (await userJoined(user));
      if (!host) {
        return;
      }

      if (mediaType === 'video') {
        host.emit('videoTrackChanged', user.videoTrack?.getMediaStreamTrack());
      }
      if (mediaType === 'audio') {
        host.emit('audioTrackChanged', user.audioTrack?.getMediaStreamTrack());
      }
    },
    [broadcasterIsCurrentUser, client, hosts, userJoined]
  );

  React.useEffect(() => {
    client.on('user-published', userPublished);
    return () => {
      client.off('user-published', userPublished);
    };
  }, [client, userPublished]);

  const userUnpublished = React.useCallback(
    (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
      const uid = `${user.uid}`;
      const host = hosts.get(uid);

      if (!host) {
        logger.warn('Unpublished track before host joined', { user });
        return;
      }

      if (mediaType === 'video') {
        host.emit('videoTrackChanged', undefined);
      }
      if (mediaType === 'audio') {
        host.emit('audioTrackChanged', undefined);
      }
    },
    [hosts]
  );

  React.useEffect(() => {
    client.on('user-unpublished', userUnpublished);
    return () => {
      client.off('user-unpublished', userUnpublished);
    };
  }, [client, userUnpublished]);

  return [hosts];
}
