export const de = {
  common: {
    ok: 'Ok',
    cancel: 'Abbrechen',
    submit: 'Absenden',
    edit: 'Editieren',
    open: 'Öffnen',
    show: 'Zeigen',
    send: 'Versenden',
    hide: 'Ausblenden',
    set: 'Setzen',
    clear: 'Löschen',
    close: 'Schließen',
    minutes: 'Minuten',
    seconds: 'Sekunden',
    save: 'Speichern',
    reset: 'Zurücksetzen',
    privacy: 'Unsere Datenschutzerklärung verrät wie wir deine Daten verarbeiten',
  },
  accountMenu: {
    logout: 'Ausloggen',
  },
  browsePanel: {
    toLobby: 'Exit zur Lobby um alle Gruppen zu sehen',
    startBroadcast: 'Starte eine Ansage',
    broadcastRunning: 'Ansage läuft...',
  },
  loginRegisterPopover: {
    button: 'Login / Registrieren',
    registerTab: 'Registrieren',
    loginTab: 'Login',
    emailInput: 'Email',
    passwordInput: 'Passwort',
    registerButton: 'Registrieren',
    loginButton: 'Login',
    errorAccountExists: 'Konto existiert bereits',
    errorUnknown: 'Unbekannter Fehler 😓',
    errorAccountNotFound: 'Konto existiert nicht.',
    errorWrongPassword: 'Falsches Passwort',
  },
  landing: {
    newPublicSpace: {
      title: 'Vermisst du einen Humby?',
      message: 'Sag uns welches Thema du gerne hier haben würdest...',
      placeholder: 'Humby-Name',
      submit: 'Anfragen',
    },
    newPrivateSpace: {
      title: 'Du willst einen privaten Humby?',
      message: `Du kannst einen privaten Humby erstellen der hier nicht sichtbar ist.
        Nur Leute die du einlädst, können teilnehmen.
        
        Wie soll der Humby heißen?`,
      placeholder: 'Humby-Name',
      submit: 'Erstellen',
    },
    copyright: '©2021 Humby GmbH, ',
    imprint: 'Impressum',
  },
  manageSpace: {
    title: 'Glückwunsch 🎉 Du hast jetzt einen Humby!',
    message:
      'Wenn du nicht mit einem Humby-Account eingeloggt bist, empfehlen wir dir diese Seite zu deinen Bookmarks hinzuzufügen, sodass du hierher zurückfindest 😉',
    shareTitle: 'Get this party started',
    shareMessage: 'Teile diesen Link mit allen die an deiner Social Video Experience teilnehmen sollen',
    shareLink: 'Leute einladen',
    join: 'Jetzt teilnehmen',
    manageTitle: 'Manage Humby',
    manageMessage:
      'Dein Humby ist bereit für Besucher und du musst nichts mehr tun. Aber hier gibt es ein paar Einstellungen, um deinen Humby für deine Veranstaltung anzupassen. Während du im Humby selbst bist, kannst du jederzeit über das Menü oben rechts Button hierher zurückkehren.',
    addPassword: 'Passwort hinzufügen',
    setTimerButton: 'Setzen',
    resetTimerButton: 'Zurücksetzen',
    settings: {
      title: 'Einstellungen',
      description: 'Hier kannst du grundlegende Einsellungen für deinen Humby vornehmen.',
      allowTableSwitching: 'Nutzer können die Gruppe wechseln',
      seatsSoftLimit: 'Anfangsgröße der Gruppen',
      seatsHardLimit: 'Maximale Anzahl an Leuten pro Gruppe',
      enableTableBlowUp: 'Sprenge Gruppen die zu groß sind',
      seatsBlowUpLimit: 'Maximale Anzahl an Leuten pro Gruppe bevor sie nach einiger Zeit aufgelöst wird',
      enableOneOnOne: 'Nutzer können andere zu einem Gespräch unter vier Augen einladen',
      groupShuffling: 'Leute kommen zurück in die gleiche Gruppe nach Verlassen und Zurückkommen zum Humby',
      disabled: 'deaktiviert',
      invalidSeatsSoftLimit: 'Anzahl zwischen 2 und 50 (4 empfohlen)',
      invalidSeatsHardLimit: 'Anzahl zwischen 2 und 50 (6 empfohlen)',
    },
    setTimer: {
      title: 'Timer Setzen',
      description:
        'Setze einen Timer. Der Timer wird allen Leuten in diesem Humby angezeigt. Du kannst ihn so oft du willst neu setzen. Nichts passiert, wenn der Timer abgelaufen ist. Wenn du willst, dass etwas passiert, schaue dir die anderen Optionen an.',
    },
    redirect: {
      title: 'Weiterleiten',
      description:
        'Wenn du hier einen Link eingibst und auf den Button drückst, schicken wir alle Nutzer in diesem Humby automatisch zum Link',
      helperText: 'Zum Beispiel: www.example.com/myevent',
    },
    instructions: {
      title: 'Anleitung',
      description:
        'Gib hier eine Anleitung für diesen Humby ein. Leute können die Anleitung jederzeit über die link Tab Bar einsehen.',
    },
    endSession: {
      title: 'Sitzung Beenden',
      description:
        'Wenn du hier einen Text eingibst und auf den Knopf drückst, beenden wir die Sitzung und schicken alle in diesem Humby zu einer Seite mit deiner Nachricht.',
      button: 'Jetzt beenden',
    },
    notification: {
      title: 'Benachrichtigung',
      description: 'Sende eine Benachrichtung an alle in diesem Humby, kann Links enthalten.',
      helperText: 'Zum Beispiel: Der nächste Talk startet jetzt: www.example.com/myevent',
    },
    startBroadcast: {
      title: 'Starte Ansage',
      description:
        'Du kannst zu allen in diesem Humby sprechen während sie in ihren Gruppen bleiben. Du bekommst noch eine Videovorschau bevor es losgeht.',
      start: 'Starte eine Ansage',
    },
    breakUpTable: {
      title: 'Große Gruppen aufbrechen',
      description:
        'Leute können sich in kleinen Gruppen besser unterhalten. Wir können große Gruppen automatisch aufbrechen.',
      enableBreakUpTables: 'Große Gruppen aufbrechen',
      breakUpTableLimit: 'Maximale Größe der Gruppen bevor wir sie aufbrechen',
      invalidSeatsBlowUpLimit: 'Anzahl zwischen 3 und 49 (5 empfohlen)',
    },
  },
  publicSpaces: {
    latest: 'Neuste Humbys',
    active: 'Aktive Humbys',
    empty: 'Leere Humbys',
    filtered: 'Humbys mit folgenden Tags:',
  },
  enterSpacePassword: {
    message: 'Dieser Humby ist passwortgeschützt 🔒',
    password: 'Passwort',
    submit: 'Enter Humby!',
  },
  closeSession: {
    placeholder: 'Der Organisator hat die Veranstaltung beendet',
  },
  spaceLanding: {
    title: 'Hi! Du bist dabei der Diskussion in einer Gruppe beizutreten',
    enterNameMessage: 'Nur noch den Namen eingeben',
    namePlaceholder: 'Dein Name',
    noNameError: 'Bitte gib deinen Namen ein',
    join: "Let's Humby",
    welcomeBack: 'Willkommen zurück!',
  },
  socialProfile: {
    name: 'Your Name',
    noNamePlaceholder: 'Humbyer',
    socialBlurb: 'Was ist dein Lieblingsthema?',
    jobTitle: 'Job Title',
    at: 'bei',
    company: 'Firma',
    noNameError: 'Bitte gib deinen Namen ein',
    noBlurbError: 'Erzähl uns eine Kleinigkeit über dich',
    jobTitleAtCompany: '{{jobTitle}} bei {{company}}',
    twitter: 'Twitter handle',
    linkedIn: 'LinkedIn Profil URL',
    inviteToOneOnOne: 'Zum 1:1 einladen',
    inviteToOneOnOneSent: 'Einladung an {{name}} wurde gesendet',
    inviteToBroadcast: 'Auf die Bühne einladen',
    inviteToBroadcastSent: 'Einladung auf die Bühne wurde geschickt',
  },
  socialProfilePrompt: {
    message: 'Erstelle dein Profil, triff die richtigen Leute',
  },
  table: {
    me: 'ich',
    tableEmpty: 'Die Gruppe, der du beitreten wolltest, existiert nicht mehr.',
    connectingFailed: 'Die Verbindung zur Gruppe ist fehlgeschlagen.',
    disconnected: 'Die Verbindung zur Gruppe wurde beendet',
    getYourHumby: 'Erstelle deinen eigenen Humby',
    giveFeedback: 'Gib uns dein Feedback über Humby',
  },
  tableOverview: {
    toLobby: 'Verlassen / Zur Lobby',
    blowingUp: 'Gruppe wird aufgelöst',
    private: 'Privat',
    full: 'Voll',
  },
  broadcast: {
    title: 'Du bist dabei eine Ansage an disen gesamten Humby zu starten',
    message: `Sobald du start drückst, wird dein Video an alle Gruppen in diesem Humby übertragen. Wir reduzieren außerdem die Lautstärke in den Gruppen so dass du sicher gehört wirst.

    Du kannst einen Gast einladen über die Gruppen- oder Teilnehmerübersicht.
    
    Vergiss nicht stop zu drücken sobald du fertig bist, damit die Gruppen wieder frei reden können :-)`,
    guestMessage: 'Vergiss nicht den Verlassen Knopf zu drücken, wenn du fertig bist :-)',
    start: 'Starte die Ansage',
    stop: 'Stoppe die Ansage',
    starting: 'Startet in {{count}} Sekunde (klicke zum Abbrechen)',
    starting_plural: 'Startet in {{count}} Sekunden (klicke zum Abbrechen)',
    kicked: 'Der Organisator hat deine Ansage beendet',
    join: 'Broadcast beitreten',
    leave: 'Broadcast verlassen',
    inviteNeeded: 'Du musst vom Organisator auf die Bühne eingeladen werden.',
    stopTheirScreenshare: 'Beende die Bildschirmfreigabe',
    askToLeave: 'Bitte sie zu gehen',
    kick: 'Sofort von der Bühne entfernen',
    screenshareInfo: 'Du kannst deinen Bildschirm teilen sobald der Organisator von der Bühne geht',
    stopScreenshare: 'Beende die Bildschirmfreigabe',
    startScreenshare: 'Teile den Bildschirm',
  },
  broadcastView: {
    starting: `Eine Ansage vom Organisator startet gleich...
    Der Präsentator kann euch nicht hören. Ihr könnt euch weiterhin in eurer Gruppe unterhalten.`,
    seconds: 'Sekunden',
  },
  spaceUsers: {
    activeUsers: 'Aktiv',
    activeUsersDescription: 'Momentan aktive Teilnehmer',
    recentUsers: 'Kürzlich aktiv',
    recentUsersDescription: 'Teilnehmer die in den letzten 6h aktiv waren',
  },
  instructionsPanel: {
    instructions: 'Anleitung',
    noInstructionsSet: 'Keine Anleitung für diesen Humby vorhanden',
  },
  organizerNotifications: {
    redirect: 'Wir leiten die Teilnehmer weiter.',
    redirectComplete: 'Teilnehmer wurden weiter geleitet',
    endSession: 'Wir beenden die Sitzung.',
    endSessionComplete: 'Sitzung wurde beendet.',
  },
  npsSnackBar: {
    title: 'Wie wahrscheinlich ist es, dass du Humby weiterempfiehlst?',
    footerLeft: 'sehr unwahrscheinlich',
    footerRight: 'sehr wahrscheinlich',
  },
  inviteButton: {
    invite: 'Einladen',
  },
  inviteMenu: {
    title: 'Lade jemanden ein zu {{spaceName}}',
    messageTitle: 'Mach mit bei {{spaceName}}',
    message: 'Hi 👋\nIch bin gerade auf Humby!\nKlicke den Link um mitzumachen',
    copy: 'Link kopieren',
  },
  thanksForSharing: {
    message: 'Danke fürs Teilen!',
    closeMessage: 'Du kannst diesen Tab nun schließen.',
    close: 'Schließen',
  },
  joinTableButton: {
    label: 'Beitreten',
  },
  lobby: {
    message:
      'Du bist in der Lobby von diesem Humby. Such dir eine Grupe aus, ruhe dich einen Moment aus oder versuch dein Glück mit einer Zufallsgruppe.',
    joinRandomTable: 'Zufallsgruppe beitreten',
    createTable: 'Neue Gruppe erstellen',
  },
  cookie: {
    acceptAll: 'Alle akzeptieren',
    declineOptional: 'Nur notwendige akzeptieren',
    message:
      'Wir nutzen optionale Analyse-Cookies von dritten um zu verstehen, wie du Humby nutzt und einen besseren Service anbieten zu können.',
    policyLink: 'Mehr Infos findest du in unserer Datenschutzerklärung.',
  },
  spaceStats: {
    stats: "$t(spaceStats.user, {'count': {{users}} }) an $t(spaceStats.table, {'count': {{tables}} })",
    user: 'Ein Nutzer',
    user_plural: '{{count}} Nutzer',
    table: 'eine Gruppe',
    table_plural: '{{count}} Gruppen',
  },
  blowUpTableSnackBar: {
    message: 'Diese Gruppe ist zu voll und wird explodieren...',
    action: 'Rette dich',
  },
  profilePromptSnackBar: {
    message: 'Erzähl uns etwas über dich',
    action: 'Profil editieren',
  },
  joinSingleUserTableSnackBar: {
    message: 'Jemand ist alleine, fange ein Gespräch an!',
    action: 'Gehe rüber',
  },
  joinOtherTableSnackBar: {
    message: 'Es scheint niemand kommt in deine Gruppe, du solltest zu einer anderen wechseln',
    action: 'Wechseln',
  },
  organizerNotificationSnackBar: {
    message: 'Nachricht vom Veranstalter',
  },
  redirectSnackBar: {
    message: 'Der Veranstalter leitet dich weiter.',
    action: 'Jetzt verlassen',
  },
  endSnackBar: {
    message: 'Der Veranstalter beendet diese Sitzung...',
    action: 'Jetzt verlassen',
  },
  endSession: {
    placeholder: 'Diese Sitzung wurde beendet.',
  },
  spaceMissing: {
    message: 'Dieser Humby existiert nicht.',
  },
  chatError: {
    message: 'Initialisierung des Chats ist fehlgeschlagen. Reinitialisierung in {{retryTimeout}}.',
    retry: 'Sofort versuchen',
  },
  navBarMenu: {
    loginRegister: 'Einloggen/Registrieren',
    editProfile: 'Profil bearbeiten',
    createNewSpace: 'Erstelle deinen eigenen Humby',
    feedback: 'Feedback geben',
    instructions: 'Anleitung',
    help: 'Hilfe',
    logout: 'Ausloggen',
  },
  navBarSpacesSubMenu: {
    manage: 'Manage Humby',
    join: 'Join Humby',
  },
  invite: {
    inviteToOneOnOne: '{{name}} hat dich zu einem 1:1 eingeladen',
    accept: 'Annehmen',
    dismiss: 'Ignorieren',
    declined: 'Du hast die Einladung ausgeschlagen',
    wasAccepted: 'Deine Einladung wurde angenommen',
    wasNotAccepted: 'Deine Einladung wurde nicht angenommen',
  },
  inviteToBroadcast: {
    invited:
      'Der Organisator dieses Humby hat dich eingeladen der Live-Ansage beizutreten.\nWenn du die Einladung annimmst, wird dein Video und Ton an alle Teilnehmer in diesem Humby gesendet.',
    accept: 'Annehmen',
    decline: 'Ablehnen',
    declined: 'Du hast die Einladung ausgeschlagen',
    wasAccepted: 'Die Einladung auf die Bühne wurde akzeptiert',
    wasNotAccepted: 'Die Einladung auf die Bühne wurde ausgeschlagen',
    askedToLeave: 'Der Organisator bittet dich von der Bühne',
    leave: 'Verlassen',
  },
  videoPreview: {
    audioDevice: 'Mikrofon {{number}}',
    videoDevice: 'Kamera {{number}}',
  },
  microphoneMeter: {
    ok: 'Dein Mikrofon funktioniert',
    noSignal: 'Kein Signal von deinem Mikrofon',
    muted: 'Dein Mikrofon ist stumm geschaltet',
  },
  videoFeed: {
    audioNotAllowedTitle: 'Keine Erlaubnis für das Mikrofon',
    audioNotAllowedMessage:
      'Du hast den Zugriff auf das Mikrofon nicht erlaubt. Gucke nach einem Mikrofon neben der Adressleiste um die Einstellung zu ändern und versuche es erneut.',
    audioNotAvailableTitle: 'Mikrofon konnte nicht initialisiert werden',
    audioNotAvailableMessage:
      'Dein Mikrofon konnte nicht initialisiert werden. Gucke ob es nicht von einem anderen Programm verwendet wird und versuche es erneut.',
    videoNotAllowedTitle: 'Keine Erlaubnis für die Kamera',
    videoNotAllowedMessage:
      'Du hast den Zugriff auf die Kamera nicht erlaubt. Gucke nach einer Kamera neben der Adressleiste um die Einstellung zu ändern und versuche es erneut.',
    videoNotAvailableTitle: 'Kamera konnte nicht initialisiert werden',
    videoNotAvailableMessage:
      'Deine Kamera konnte nicht initialisiert werden. Gucke ob sie nicht von einem anderen Programm verwendet wird und versuche es erneut.',
  },
  retryErrorDialog: {
    ignore: 'Ignorieren',
    retry: 'Erneut versuchen',
  },
  unsupportedWarning: {
    messageUnsupported: 'Dein Browser wird nicht unterstützt',
    messagePotentiallyUnsupported: 'Dein Browser wird eventuell nicht unterstützt und es könnte zu Problemen kommen',
    ignore: 'Trotzdem versuchen',
    messageLinux: 'Bitte benutze zu Chrome oder Firefox',
    messageWindows: 'Bitte benutze zu Edge, Chrome oder Firefox',
    messageMac: 'Bitte benutze zu Safari, Chrome oder Firefox',
    messageIOs: 'Bitte benutze zu Safari',
    messageAndroid: 'Bitte benutze Chrome',
    messageUnknown: 'Bitte benutze Chrome, Firefox, Edge oder Safari auf einem unterstützten Gerät',
  },
  videoProfileOverlay: {
    me: '(ich)',
  },
  filters: {
    filters: 'Video-Filter',
    refract: 'Unterwasser',
    linear: 'Linear',
    discrete: 'Diskret',
    colorOffset: 'Farbverschiebung',
    edge: 'Kantenerkennung',
    comic: 'Comic',
    disable: 'Deaktivieren',
  },
  pushSubscriptionDialog: {
    title: 'Benachrichtige mich wenn jemand kommt',
    emptyMessage: 'Momentan ist niemand hier, aber wir können dich benachrichtigen, sobald jemand einem Humby beitritt',
    ok: 'Benachrichtige mich',
    no: 'Nein danke',
  },
  push: {
    title: 'Komm zu Humby: {{spaceName}}',
    message: 'Jemand ist gerade einem Humby beigetreten, der dich interessiert',
    join: 'Jetzt mitmachen',
    someoneJoins: 'Jemand anderes tritt gerade bei',
  },
};
