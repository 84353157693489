import moment from 'moment';

import { RegisterGuest } from 'gql/types/RegisterGuest';
import { Register } from 'gql/types/Register';
import { Login } from 'gql/types/Login';
import { RefreshToken } from 'gql/types/RefreshToken';
import { Role } from 'gql/types/globals';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
import Analytics from 'analytics';
import { datadogLogs } from '@datadog/browser-logs';
import { logger } from 'logging';
import {
  DocumentNode,
  makeVar,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useApolloClient,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import React from 'react';
import posthog from 'posthog-js';
import { useLogoutMutation } from 'auth.gql';

const EXPIRY_MARGIN = 150000;
export const CHECK_INTERVAL = 30000;

// FOR TESTING
// const EXPIRY_MARGIN = 5000;
// export const CHECK_INTERVAL = 2000;

export const token = makeVar('');
let tokenExpiry: null | number = null;
const hasAccount = makeVar(false);
const role = makeVar<null | Role>(null);
const userId = makeVar<null | string>(null);

export function useUserId() {
  return useReactiveVar(userId);
}

export function useHasAccount() {
  return useReactiveVar(hasAccount);
}

export function useRole() {
  return useReactiveVar(role);
}

export function useUserQuery<TData, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const userId = useUserId();
  const userIdRef = React.useRef(userId);

  const result = useQuery<TData, TVariables>(query, options);

  React.useEffect(() => {
    if (userId !== userIdRef.current) {
      userIdRef.current = userId;
      result.refetch();
    }
  }, [result, userId]);
  return result;
}

type TokenRequestResult = RegisterGuest | RefreshToken | Register | Login;

function isGuestRegisterToken(result: TokenRequestResult): result is RegisterGuest {
  return (result as RegisterGuest).signUpGuest !== undefined;
}

function isRefreshToken(result: TokenRequestResult): result is RefreshToken {
  return (result as RefreshToken).refreshToken !== undefined;
}

function isRegisterToken(result: TokenRequestResult): result is Register {
  return (result as Register).signUp !== undefined;
}

function isLoginToken(result: TokenRequestResult): result is Login {
  return (result as Login).login !== undefined;
}

function getAuthData(data: TokenRequestResult) {
  if (isGuestRegisterToken(data)) {
    return data.signUpGuest;
  } else if (isRefreshToken(data)) {
    return data.refreshToken;
  } else if (isRegisterToken(data)) {
    return data.signUp;
  } else if (isLoginToken(data)) {
    return data.login;
  }
}

export function onTokenReceived(data: TokenRequestResult) {
  const authData = getAuthData(data);

  if (authData && !authData.error && authData.token) {
    userId(authData.userId);
    token(authData.token);
    tokenExpiry = authData.tokenExpiry;
    role(authData.role);
    hasAccount(true);

    if (authData.userId && authData.role) {
      Analytics.setUserProperties(authData.userId, authData.role);
      LogRocket.identify(authData.userId, {
        role: authData.role,
      });
      const id = authData.userId;
      Sentry.configureScope((scope) => {
        scope.setUser({
          id,
          role: authData.role,
        });
      });
      datadogLogs.addLoggerGlobalContext('userId', authData.userId);
      posthog.identify(id, { role: authData.role });
      logger.debug({ userId: id });
    }
  }
}

export function setToken(newToken: string, newTokenExpiry: number) {
  token(newToken);
  tokenExpiry = newTokenExpiry;
}

export function shouldRefresh() {
  return hasAccount() && tokenExpiry !== null && tokenExpiry - EXPIRY_MARGIN < moment().valueOf();
}

export async function linkAuthContext() {
  if (token()) {
    return {
      headers: {
        authorization: `Bearer ${token()}`,
      },
    };
  } else {
    return {
      headers: {},
    };
  }
}

export function useLogout() {
  const [logoutMutation] = useLogoutMutation();
  const client = useApolloClient();

  const logout = React.useCallback(async () => {
    await logoutMutation();
    localStorage.removeItem('token');
    await client.resetStore();
    posthog.reset(true);

    window.location.reload(true);
  }, [client, logoutMutation]);

  return logout;
}
