import React from 'react';

import { VideoContainer } from './table/VideoContainer';
import { VideoUser } from 'util/VideoUser';

interface Props {
  user: VideoUser;
}

export function BroadcastView(props: Props) {
  const [userId, setUserId] = React.useState<string>('');
  const [audioTrack, setAudioTrack] = React.useState<MediaStreamTrack | undefined>(props.user.audioTrack);
  const [videoTrack, setVideoTrack] = React.useState<MediaStreamTrack | undefined>(props.user.videoTrack);

  React.useEffect(() => {
    setUserId(props.user.userId);
    setAudioTrack(props.user.audioTrack);
    setVideoTrack(props.user.videoTrack);

    props.user.on('audioTrackChanged', setAudioTrack);
    props.user.on('videoTrackChanged', setVideoTrack);
    return () => {
      props.user.off('audioTrackChanged', setAudioTrack);
      props.user.off('videoTrackChanged', setVideoTrack);
    };
  }, [props.user]);

  return <VideoContainer userId={userId} audioTrack={audioTrack} videoTrack={videoTrack} />;
}
