import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, Button } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { FBox, FBoxV } from '@badger/design-system';

import { SocialSpaceContext } from 'components/socialSpace';

import { SpaceOptionFrame } from './SpaceOptionFrame';

import { useEndSpaceSession } from './EndSessionOption.gql';

export function EndSessionOption() {
  const { t } = useTranslation();

  const { spaceId, spaceTablesUsers } = React.useContext(SocialSpaceContext);

  const [endMessage, setEndMessage] = React.useState(spaceTablesUsers?.endSessionMessage || '');

  const [endSpaceSession] = useEndSpaceSession();

  const endSession = () => {
    endSpaceSession({
      variables: {
        input: {
          spaceId,
          time: moment().toISOString(),
          message: endMessage,
        },
      },
    });
  };

  return (
    <SpaceOptionFrame
      title={t('manageSpace.endSession.title')}
      description={t('manageSpace.endSession.description')}
      IconComponent={ExitToAppIcon}
    >
      <>
        <FBoxV mt={2} width="100%">
          <TextField onChange={(event) => setEndMessage(event.target.value)} variant="outlined" value={endMessage} />
        </FBoxV>
        <FBox mt={1} justifyContent="flex-end">
          <Button onClick={endSession} variant="contained" color="primary">
            {t('manageSpace.endSession.button')}
          </Button>
        </FBox>
      </>
    </SpaceOptionFrame>
  );
}
