import gql from 'graphql-tag';

import { OwnedSocialSpaces } from 'gql/types/OwnedSocialSpaces';
import { JoinedSocialSpaces } from 'gql/types/JoinedSocialSpaces';
import { useUserQuery } from 'auth';

const SOCIAL_SPACE_MEMBER_FRAGMENT = gql`
  fragment SocialSpaceMemberFragment on SocialSpace {
    id
    slug
    name
  }
`;

const OWNED_SOCIAL_SPACES = gql`
  query OwnedSocialSpaces {
    ownedSocialSpaces {
      ...SocialSpaceMemberFragment
    }
  }
  ${SOCIAL_SPACE_MEMBER_FRAGMENT}
`;

const JOINED_SOCIAL_SPACES = gql`
  query JoinedSocialSpaces {
    joinedSocialSpaces {
      ...SocialSpaceMemberFragment
    }
  }
  ${SOCIAL_SPACE_MEMBER_FRAGMENT}
`;

export function useOwnedSocialSpaces(onCompleted?: (data: OwnedSocialSpaces) => void) {
  return useUserQuery<OwnedSocialSpaces>(OWNED_SOCIAL_SPACES, {
    onCompleted,
  });
}

export function useJoinedSocialSpaces(onCompleted?: (data: JoinedSocialSpaces) => void) {
  return useUserQuery<JoinedSocialSpaces>(JOINED_SOCIAL_SPACES, {
    onCompleted,
  });
}
