import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';
import { ReactComponent as BroadcastIcon } from 'assets/icons/broadcast.svg';

import { FBox } from '@badger/design-system';

import Analytics from 'analytics';
import { SocialSpaceContext } from 'components/socialSpace';

import { SpaceOptionFrame } from './SpaceOptionFrame';

import { useHistory } from 'react-router-dom';

export function StartBroadcast() {
  const { t } = useTranslation();
  const history = useHistory();

  const { spaceSlug } = React.useContext(SocialSpaceContext);

  const onStartBroadcast = () => {
    Analytics.trackManageStartBroadcast();
    history.push(`/in/${spaceSlug}/broadcast`);
  };

  return (
    <SpaceOptionFrame
      title={t('manageSpace.startBroadcast.title')}
      description={t('manageSpace.startBroadcast.description')}
      IconComponent={BroadcastIcon}
    >
      <FBox justifyContent="flex-end" mt={1}>
        <Button onClick={onStartBroadcast} variant="contained" color="primary">
          {t('manageSpace.startBroadcast.start')}
        </Button>
      </FBox>
    </SpaceOptionFrame>
  );
}
