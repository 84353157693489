import * as React from 'react';

import { VideoStreamContext } from 'components/socialSpace/VideoStreamContext';
import { logger } from 'logging';
import { getLocal } from 'local';

export const useLocalVideo = () => {
  const { videoDevice } = React.useContext(VideoStreamContext);

  const [videoTrack, setVideoTrack] = React.useState<MediaStreamTrack>();

  React.useEffect(() => {
    const device = videoDevice ?? getLocal('videoDeviceId');

    const video = device ? { deviceId: device } : { facingMode: 'user' };

    navigator.mediaDevices
      .getUserMedia({ audio: false, video })
      .then((stream) => {
        const videoTrack = stream.getVideoTracks()[0];
        setVideoTrack(videoTrack);
      })
      .catch((error) => {
        logger.debug('Initializing stream failed', { error });
      });
  }, [videoDevice]);

  React.useEffect(() => {
    if (videoTrack) {
      return () => {
        videoTrack.stop();
      };
    }
  }, [videoTrack]);

  return videoTrack;
};
