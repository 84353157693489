import moment, { duration, Duration } from 'moment';

export function setCookie(name: string, value: string, shelfLife: Duration) {
  const expires = moment().add(shelfLife);
  document.cookie = `${name}=${value}; expires=${expires.utc()}; path=/`;
}

export function getCookie(name: string) {
  const value = '; ' + document.cookie;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
}

export function deleteCookie(name: string) {
  setCookie(name, '', duration(-1, 'days'));
}
