import React from 'react';
import { useTranslation } from 'react-i18next';
import Truncate from 'react-truncate';

import { Tooltip, Button, Link, Card, Typography } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { FBoxV, FBox } from '@badger/design-system';

import Analytics from 'analytics';

import { SocialProfileDialog } from 'dialogs';

import { SocialSpaceContext } from '../context';
import { useInviteToBroadcast, useInviteToOneOnOne, useKickUser } from './SocialProfileListItem.gql';
import { ActionSnackBar } from '../snackbar/ActionSnackBar';
import { ReactComponent as BroadcastIcon } from 'assets/icons/broadcast.svg';
import { useUserId, useRole } from 'auth';
import { Role } from 'gql/types/globals';

const useStyles = makeStyles({
  listItem: {
    marginTop: 2,
    position: 'relative',
    overflow: 'visible',
  },
  twitterIcon: {
    width: 16,
    height: 16,
    marginLeft: 4,
    color: '#1DA1F2',
  },
  linkedInIcon: {
    width: 16,
    height: 16,
    marginLeft: 4,
    color: '#2867B2',
  },
});

interface Props {
  userId: string;
  name: string;
  socialBlurb: string;
  jobTitle: string;
  company: string;
  twitter: string;
  linkedIn: string;
  localUser?: boolean;
  showInviteToOneOnOne?: boolean;
}

export function SocialProfileListItem(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { spaceId, spaceTablesUsers } = React.useContext(SocialSpaceContext);

  const [profileDialogOpen, setProfileDialogOpen] = React.useState(false);
  const [blurbTooltipEnabled, setBlurbTooltipEnabled] = React.useState(false);
  const [showBlurbTooltip, setShowBlurbTooltip] = React.useState(false);
  const [inviteToOneOnOne] = useInviteToOneOnOne();
  const [inviteToBroadcast] = useInviteToBroadcast();
  const [kickUser] = useKickUser();

  const userId = useUserId();
  const role = useRole();

  const enableBlurbTooltip = React.useCallback(
    (truncated: boolean) => {
      setBlurbTooltipEnabled(truncated);
    },
    [setBlurbTooltipEnabled]
  );

  const onBlurbTooltipOpen = () => {
    if (blurbTooltipEnabled) {
      Analytics.trackSocialProfileListItemBlurbMouseover();

      setShowBlurbTooltip(true);
    }
  };

  const onBlurbTooltipClosed = () => {
    setShowBlurbTooltip(false);
  };

  const openProfileDialog = () => {
    Analytics.trackSocialProfileListItemEditProfile();

    setProfileDialogOpen(true);
  };

  const closeProfileDialog = () => {
    setProfileDialogOpen(false);
  };

  const onInviteToOneOnOne = () => {
    Analytics.trackSocialProfileListItemOneOnOne();

    inviteToOneOnOne({
      variables: {
        input: {
          userId: props.userId,
          spaceId,
        },
      },
    }).then(() => {
      const key = `INVITE_TO_ONE_ON_ONE_${props.userId}`;
      enqueueSnackbar(
        <ActionSnackBar
          snackKey={key}
          message={t('socialProfile.inviteToOneOnOneSent', {
            name: props.name ?? t('socialProfile.noNamePlaceholder'),
          })}
          noAction
        />,
        {
          key,
        }
      );
    });
  };

  const onInviteToBroadcast = () => {
    Analytics.trackSocialProfileListItemInviteToBroadcast();

    inviteToBroadcast({
      variables: {
        input: {
          userId: props.userId,
          spaceId,
        },
      },
    }).then(() => {
      const key = `INVITE_TO_BROADCAST_${props.userId}`;
      enqueueSnackbar(
        <ActionSnackBar
          snackKey={key}
          message={t('socialProfile.inviteToBroadcastSent', {
            name: props.name ?? t('socialProfile.noNamePlaceholder'),
          })}
          noAction
        />,
        {
          key,
        }
      );
    });
  };

  const onKickUser = async () => {
    const kick = window.confirm(`Do you really want to PERMANENTLY KICK '${props.name}' (${props.userId})?`);
    if (kick) {
      const kicked = await kickUser({
        variables: {
          input: {
            userId: props.userId,
          },
        },
      });
      if (kicked.errors || !kicked.data || kicked.data.kickUser.error) {
        alert(
          `Failed to kick, errors: graphql error: ${kicked.errors}, API error: ${kicked.data?.kickUser?.error?.type}`
        );
      } else {
        alert(`Permanently kicked ${props.name}`);
      }
    }
  };

  let subtitle = '';

  if (props.jobTitle !== '' && props.company !== '') {
    subtitle = t('socialProfile.jobTitleAtCompany', { jobTitle: props.jobTitle, company: props.company });
  } else {
    subtitle = props.jobTitle || props.company;
  }

  const socialLinkAvailable = props.linkedIn !== '' || props.twitter.length > 1;

  const showInviteToBroadcast =
    spaceTablesUsers?.owner?.id === userId &&
    spaceTablesUsers?.broadcasting &&
    spaceTablesUsers.broadcasting.length < 2;

  return (
    <Card className={classes.listItem} variant="elevation">
      <FBoxV p={1}>
        <FBox justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{props.name || t('socialProfile.noNamePlaceholder')}</Typography>
          {socialLinkAvailable && (
            <FBox>
              {props.twitter.length > 1 && (
                <Link
                  target="_blank"
                  onClick={() => Analytics.trackSocialProfileListItemTwitter()}
                  href={`http://www.twitter.com/${props.twitter.substring(1)}`}
                >
                  <TwitterIcon className={classes.twitterIcon} />
                </Link>
              )}
              {props.linkedIn !== '' && (
                <Link
                  target="_blank"
                  onClick={() => Analytics.trackSocialProfileListItemLinkedIn()}
                  href={props.linkedIn}
                >
                  <LinkedInIcon className={classes.linkedInIcon} />
                </Link>
              )}
            </FBox>
          )}
        </FBox>
        {subtitle !== '' && <Typography variant="body2">{subtitle}</Typography>}
        {props.socialBlurb !== '' && (
          <Tooltip
            open={showBlurbTooltip}
            onOpen={onBlurbTooltipOpen}
            onClose={onBlurbTooltipClosed}
            title={props.socialBlurb}
          >
            <Typography variant="body1">
              <Truncate onTruncate={enableBlurbTooltip} trimWhitespace lines={2}>
                {props.socialBlurb}
              </Truncate>
            </Typography>
          </Tooltip>
        )}
        {props.localUser && (
          <>
            <FBox mt={1}>
              <Button color="primary" variant="contained" size="small" onClick={openProfileDialog}>
                {t('common.edit')}
              </Button>
            </FBox>
            <SocialProfileDialog open={profileDialogOpen} onClose={closeProfileDialog} />
          </>
        )}
      </FBoxV>
      <FBoxV alignItems="flex-start">
        {props.showInviteToOneOnOne && (
          <Button startIcon={<PeopleAltIcon />} onClick={onInviteToOneOnOne}>
            {t('socialProfile.inviteToOneOnOne')}
          </Button>
        )}
        {showInviteToBroadcast && (
          <Button startIcon={<BroadcastIcon style={{ width: '20px', height: '20px' }} />} onClick={onInviteToBroadcast}>
            {t('socialProfile.inviteToBroadcast')}
          </Button>
        )}
        {role === Role.ADMIN && <Button onClick={onKickUser}>kick</Button>}
      </FBoxV>
    </Card>
  );
}
