import * as React from 'react';

import { AppBar, style } from '@badger/design-system';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  bar: {
    // FIXME hack until design system has all correct transparent colors
    backgroundColor: `${style.colors.backgroundDark}dd`,
    height: 'var(--navBarHeight, 64px)',
    overflow: 'hidden',
    zIndex: theme.zIndex.drawer + 1,
    '& .MuiToolbar-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative',
      width: '100%',
      height: '100%',
    },
  },
  bottomBar: {
    position: 'fixed',
    top: 'auto',
    bottom: '0',
  },
}));

interface Props {
  children: React.ReactNode;
  bottom?: boolean;
}

export function Bar(props: Props) {
  const classes = useStyles();

  return <AppBar className={`${classes.bar} ${props.bottom ? classes.bottomBar : ''}`}>{props.children}</AppBar>;
}
