import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { OuterPageContainer } from '@badger/design-system';
import { PublicSpaces } from 'components';

const useStyles = makeStyles({
  container: {
    flexDirection: 'row',
    overflow: 'auto',
  },
});

export const EmbedPublicSpaces = () => {
  const classes = useStyles();

  return (
    <OuterPageContainer className={classes.container}>
      <PublicSpaces />
    </OuterPageContainer>
  );
};
