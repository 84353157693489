import { logger } from 'logging';

export function getLocal(key: string) {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    logger.warn('No access to localStorage', error);
    return;
  }
}

export function setLocal(key: string, value: string) {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    logger.warn('No access to localStorage', error);
  }
}
