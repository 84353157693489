import gql from 'graphql-tag';
import { TablesWithUsers, TablesWithUsersVariables } from 'gql/types/TablesWithUsers';
import { QuerySocialSpaceInput } from 'gql/types/globals';
import { useLiveQuery } from 'hooks/useLiveQuery';

export const GET_TABLES_WITH_USERS = gql`
  query TablesWithUsers($input: QuerySocialSpaceInput!) {
    socialSpace(input: $input) {
      id
      name
      instructions
      timerStartTime
      timerDurationSeconds
      endSessionTime
      endSessionMessage
      broadcasting {
        id
        userId
        type
      }
      owner {
        id
      }
      format {
        id
        roaming
        enableOneOnOne
      }
      tables {
        id
        users {
          id
          profile {
            id
            name
            socialBlurb
            company
            jobTitle
            twitter
            linkedIn
            pictureUrl
          }
        }
        joinable
        actionTags
      }
    }
  }
`;

export function useGetTablesWithUsers(input: QuerySocialSpaceInput, onCompleted?: (data: TablesWithUsers) => void) {
  return useLiveQuery<TablesWithUsers, TablesWithUsersVariables>(GET_TABLES_WITH_USERS, {
    variables: {
      input,
    },
    onCompleted,
  });
}
