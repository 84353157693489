import React from 'react';
import { useTranslation } from 'react-i18next';

import AlarmIcon from '@material-ui/icons/Alarm';

import { ProgressSnackBar } from './ProgressSnackBar';

interface Props {
  actionDelay: number;
  action: () => void;
}

export function EndSnackBar(props: Props) {
  const { t } = useTranslation();

  return (
    <ProgressSnackBar
      {...props}
      icon={<AlarmIcon />}
      message={t('endSnackBar.message')}
      actionText={t('endSnackBar.action')}
      noClose
    />
  );
}
