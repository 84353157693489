import { getLocal } from 'local';
import * as React from 'react';
import { VideoFilterType } from './VideoStreamFilter';

export type VideoFilter = (track: MediaStreamTrack) => MediaStreamTrack;

interface VideoContext {
  audioMuted: boolean;
  videoMuted: boolean;
  setAudioMuted: (muted: boolean) => void;
  setVideoMuted: (muted: boolean) => void;
  audioDevice: string;
  videoDevice: string;
  setAudioDevice: (deviceId: string) => void;
  setVideoDevice: (deviceId: string) => void;
  audioTrack?: MediaStreamTrack;
  videoTrack?: MediaStreamTrack;
  initialized: boolean;
  setVideoFilter: (filter: VideoFilterType | null) => void;
}

function noop() {
  /**/
}

export const VideoStreamContext = React.createContext<VideoContext>({
  audioMuted: getLocal('audioMuted') === 'true',
  videoMuted: getLocal('videoMuted') === 'true',
  setAudioMuted: noop,
  setVideoMuted: noop,
  audioDevice: getLocal('audioDeviceId') ?? '',
  videoDevice: getLocal('videoDeviceId') ?? '',
  setAudioDevice: noop,
  setVideoDevice: noop,
  initialized: false,
  setVideoFilter: noop,
});

export function playTrack(
  videoElement: HTMLVideoElement | HTMLAudioElement,
  ...tracks: (MediaStreamTrack | undefined)[]
) {
  const mediaStream = videoElement.srcObject instanceof MediaStream ? videoElement.srcObject : new MediaStream();

  mediaStream.getVideoTracks().forEach((track) => mediaStream.removeTrack(track));
  tracks.forEach((track) => track && mediaStream.addTrack(track));
  videoElement.srcObject = mediaStream;
  videoElement.autoplay = true;
  //@ts-ignore
  videoElement.playsInline = true;

  videoElement.srcObject = mediaStream;
  videoElement.play();
}
