import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, makeStyles } from '@material-ui/core';

import { style } from '@badger/design-system';

import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { InviteMenu, useInviteMenu } from './InviteMenu';
import analytics from 'analytics';

const useStyles = makeStyles({
  button: {
    color: style.colors.primary,
    backgroundColor: '#ffffff',
    opacity: 0.6,
    '&:hover': {
      backgroundColor: '#ffffff',
      opacity: 0.7,
    },
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  },
  innerButton: {
    fontSize: 16,
    minHeight: 48,
    marginTop: 8,
    width: '80%',
    height: '15%',
  },
  icon: {
    width: '30%',
    height: 'auto',
    marginBottom: '2%',
  },
});

export const GroupInviteButton = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [mousePosition, setMousePosition] = React.useState<{ x: number; y: number } | null>(null);
  const { onShare, onClose, anchorEl, share } = useInviteMenu();

  const onOpen = (event: React.MouseEvent) => {
    analytics.trackGroupShare();
    setMousePosition({ x: event.clientX, y: event.clientY });
    onShare(event);
  };

  return (
    <>
      <Button className={classes.button} onClick={onOpen} variant="outlined" color="primary">
        <ShareIcon className={classes.icon} />
        <Button className={classes.innerButton} variant="outlined" color="primary">
          {t('inviteButton.invite')}
        </Button>
      </Button>
      <InviteMenu
        onClose={onClose}
        anchorEl={anchorEl}
        anchorReference="anchorPosition"
        anchorPosition={mousePosition ? { top: mousePosition.y, left: mousePosition.x } : undefined}
        share={share}
      />
    </>
  );
};
