import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, Button } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { FBox, FBoxV } from '@badger/design-system';

import Analytics from 'analytics';

import { SocialSpaceContext } from 'components/socialSpace';

import { SpaceOptionFrame } from './SpaceOptionFrame';

import { useSetSpaceInstructions } from './InstructionsOption.gql';

export function InstructionsOption() {
  const { t } = useTranslation();

  const { spaceId, spaceTablesUsers } = React.useContext(SocialSpaceContext);

  const [instructions, setInstructions] = React.useState(spaceTablesUsers?.instructions || '');

  const [setSpaceInstructions] = useSetSpaceInstructions();

  const onSetSpaceInstructions = () => {
    Analytics.trackManageSetInstructions(instructions.length);

    setSpaceInstructions({
      variables: {
        input: {
          spaceId,
          instructions,
        },
      },
    });
  };

  const onClearSpaceInstructions = () => {
    Analytics.trackManageClearInstructions();

    setSpaceInstructions({
      variables: {
        input: {
          spaceId,
          instructions: '',
        },
      },
    });
  };

  return (
    <SpaceOptionFrame
      title={t('manageSpace.instructions.title')}
      description={t('manageSpace.instructions.description')}
      IconComponent={InfoIcon}
    >
      <>
        <FBoxV mt={2} width="100%">
          <TextField
            rows={6}
            multiline
            onChange={(event) => setInstructions(event.target.value)}
            variant="outlined"
            value={instructions}
          />
        </FBoxV>
        <FBox justifyContent="flex-end" mt={1}>
          <Button onClick={onSetSpaceInstructions} variant="contained" color="primary">
            {t('common.set')}
          </Button>
          <FBox ml={1}>
            <Button onClick={onClearSpaceInstructions} variant="contained" color="primary">
              {t('common.clear')}
            </Button>
          </FBox>
        </FBox>
      </>
    </SpaceOptionFrame>
  );
}
