import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';
import { FBoxV } from '@badger/design-system';
import { ActionSnackBar } from './ActionSnackBar';
import { FormattedMessage } from 'components/FormattedMessage';

interface Props {
  snackKey: string;
  message: string;
}

export function OrganizerNotificationSnackBar(props: Props) {
  const { t } = useTranslation();

  return (
    <ActionSnackBar noAction snackKey={props.snackKey}>
      <FBoxV>
        <Typography variant="caption">{t('organizerNotificationSnackBar.message')}</Typography>
        <FormattedMessage>{props.message}</FormattedMessage>
      </FBoxV>
    </ActionSnackBar>
  );
}
