import * as React from 'react';

import { Route, RouteProps } from 'react-router-dom';

import Analytics from 'analytics';

export const GARoute = (props: RouteProps) => {
  Analytics.trackPageView(window.location.pathname + window.location.search);

  return <Route {...props} />;
};
