import React from 'react';

import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FBox } from '@badger/design-system';

import { ReactComponent as MicIcon } from 'assets/icons/videoControls/mic.svg';
import { ReactComponent as MicOffIcon } from 'assets/icons/videoControls/micOff.svg';
import { ReactComponent as VideocamIcon } from 'assets/icons/videoControls/video.svg';
import { ReactComponent as VideocamOffIcon } from 'assets/icons/videoControls/videoOff.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/videoControls/more.svg';
import { VideoDeviceSelectionMenu } from './VideoDeviceSelectionMenu';
import { VideoStreamContext } from '../VideoStreamContext';
import analytics from 'analytics';

const useStyles = makeStyles({
  root: {
    justifyContent: 'space-between',
    width: 'min(100%, 192px)',
    minHeight: '42px',
    flexShrink: 1,
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& .MuiIconButton-label': {
      '& > svg': {
        color: '#ffffff',
        width: '42px',
        height: '42px',
      },
      boxShadow: 'none',
      color: 'rgba(0,0,0,0)',
    },
  },
  icon: {
    width: 16,
    height: 16,
  },
});

export function VideoControls() {
  const classes = useStyles();
  const { audioMuted, videoMuted, setAudioMuted, setVideoMuted } = React.useContext(VideoStreamContext);

  const onVideoMuted = () => {
    setVideoMuted(!videoMuted);
    analytics.trackVideoMuted(videoMuted);
  };

  const onAudioMuted = () => {
    setAudioMuted(!audioMuted);
    analytics.trackAudioMuted(audioMuted);
  };

  return (
    <FBox className={classes.root}>
      <IconButton onClick={onVideoMuted}>{videoMuted ? <VideocamOffIcon /> : <VideocamIcon />}</IconButton>
      <IconButton onClick={onAudioMuted}>{audioMuted ? <MicOffIcon /> : <MicIcon />}</IconButton>
      <VideoDeviceSelectionMenu icon={<MoreIcon />} />
    </FBox>
  );
}
