import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@material-ui/core';
import { FBoxV } from '@badger/design-system';

import { addBreadcrumb } from '@sentry/browser';

import { baseUrl } from 'config';
import Analytics from 'analytics';
import { duration } from 'moment';

export const ChatError = () => {
  const { t } = useTranslation();
  const [currentTimeout, setCurrentTimeout] = React.useState(5000);

  const onRetry = () => {
    window.parent.postMessage({ chat: 'authorizationError' }, baseUrl);
    Analytics.trackChatErrorRetry();
  };

  React.useEffect(() => {
    const postError = () => {
      window.parent.postMessage({ chat: 'authorizationError' }, baseUrl);
      addBreadcrumb({
        message: 'Sending chat authorization error to parent window',
        data: {
          baseUrl,
          currentTimeout,
        },
      });
      setCurrentTimeout(currentTimeout + 5000);
    };
    setTimeout(postError, currentTimeout);
  }, [currentTimeout]);

  return (
    <FBoxV p={8} width="100%" justifyContent="center" alignItems="center" height="60vh">
      <Typography align="center" variant="h4">
        {t('chatError.message', {
          retryTimeout: duration(currentTimeout, 'milliseconds').humanize(),
        })}
      </Typography>
      <Button onClick={onRetry}>{t('chatError.retry')}</Button>
    </FBoxV>
  );
};
