import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const useTags = () => {
  const location = useLocation();

  const params = queryString.parse(location.search, { arrayFormat: 'comma' });
  const tags = params.tag ? (typeof params.tag === 'string' ? [params.tag] : params.tag) : undefined;

  return tags;
};
