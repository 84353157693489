import { duration } from 'moment';
import { getCookie, setCookie } from 'util/cookie';

const COOKIE_SETTINGS = 'CookieChoice';

export enum CookieSetting {
  AllCookies = 'AllCookies',
  NoOptional = 'OnlyRequired',
}

function getCookieSetting() {
  const setting = getCookie(COOKIE_SETTINGS);

  switch (setting) {
    case CookieSetting.AllCookies:
      return CookieSetting.AllCookies;
    case CookieSetting.NoOptional:
      return CookieSetting.NoOptional;
  }
}

export function hasCookie() {
  return !!getCookieSetting();
}

const cookieFunctions = new Map<CookieSetting, [() => void]>();

export function addCookieFunction(fun: () => void, setting: CookieSetting = CookieSetting.AllCookies) {
  if (getCookieSetting() === setting) {
    fun();
  } else {
    const funs = cookieFunctions.get(setting);
    if (funs) {
      funs.push(fun);
    } else {
      cookieFunctions.set(setting, [fun]);
    }
  }
}

export function setCookieSetting(setting: CookieSetting) {
  setCookie(COOKIE_SETTINGS, setting, duration(1, 'year'));

  const funs = cookieFunctions.get(setting);
  funs?.forEach((fun) => fun());
  cookieFunctions.delete(setting);
}
