import React from 'react';

import { useScreenShare } from 'hooks/screenShare';
import { Credentials, useAgoraClient } from 'hooks/agoraClient';
import { useJoinScreenshareBroadcast, useLeaveScreenshareBroadcast } from './AgoraScreenshare.gql';
import analytics from 'analytics';
import { SocialSpaceContext } from '.';
import { ReactComponent as ScreenshareIcon } from 'assets/icons/screenshare.svg';
import { Box, Button, makeStyles, Paper, Typography } from '@material-ui/core';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { useTranslation } from 'react-i18next';
import { ScreenshareContainer } from './table/ScreenshareContainer';
import { FBoxVCenter } from '@badger/design-system';

const useStyles = makeStyles({
  broadcast: {
    alignItems: 'stretch',
    alignContent: 'stretch',
    flexGrow: 1,
    height: '100%',
  },
  message: {
    padding: '1vh',
    color: 'white',
  },
  videoArea: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: '1vh',
    paddingBottom: '0.5vh',
  },
  videoBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5vw',
  },
  actionPlaceholder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  startStopButton: {
    height: 56,
    minWidth: 120,
    padding: '0 30px',
  },
  '@keyframes pulse': {
    '0% 100%': {
      opacity: '1',
    },
    '50%': {
      opacity: '0.5',
    },
  },
});

export function AgoraScreenshare() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { screenTrack, startScreenShare, stopScreenShare } = useScreenShare();
  const { client, connect, disconnect, connectionState } = useAgoraClient();
  const { spaceId } = React.useContext(SocialSpaceContext);
  const [credentials, setCredentials] = React.useState<Credentials>();

  const [joinScreenshare] = useJoinScreenshareBroadcast();
  const [leaveScreenshare] = useLeaveScreenshareBroadcast();

  const leave = React.useCallback(() => {
    leaveScreenshare({
      variables: {
        input: {
          spaceId,
        },
      },
    });
    disconnect();
    stopScreenShare();
  }, [disconnect, leaveScreenshare, spaceId, stopScreenShare]);

  React.useEffect(() => {
    if (connectionState === 'KICKED') {
      stopScreenShare();
    }
  }, [connectionState, stopScreenShare]);

  React.useEffect(() => {
    if (credentials) {
      return () => {
        stopScreenShare();
      };
    }
  }, [credentials, stopScreenShare]);

  React.useEffect(() => {
    if (credentials && screenTrack) {
      connect(credentials, 'host');
      return () => {
        leave();
      };
    }
  }, [connect, credentials, leave, screenTrack]);

  React.useEffect(() => {
    return () => {
      leave();
    };
  }, [leave]);

  React.useEffect(() => {
    if (screenTrack && connectionState === 'CONNECTED') {
      const localScreenTrack = AgoraRTC.createCustomVideoTrack({
        mediaStreamTrack: screenTrack,
      });
      client.publish(localScreenTrack);
    }
  }, [client, connectionState, screenTrack]);

  const onJoinScreenshare = async () => {
    startScreenShare();
    analytics.trackBroadcastStartScreenshare();
    const screenshare = await joinScreenshare({
      variables: {
        input: {
          spaceId,
        },
      },
    });
    setCredentials(screenshare.data?.joinScreenshareBroadcast.broadcastCredentials ?? undefined);
  };

  const onLeaveScreenshare = () => {
    analytics.trackBroadcastStopScreenshare();
    leave();
  };

  return (
    <Box className={classes.videoBox}>
      {screenTrack ? (
        <>
          <ScreenshareContainer screenTrack={screenTrack} />
          <Button onClick={onLeaveScreenshare} variant="contained" color="primary">
            {t('broadcast.stopScreenshare')}
          </Button>
        </>
      ) : (
        <Paper onClick={onJoinScreenshare} className={classes.actionPlaceholder}>
          <FBoxVCenter>
            <ScreenshareIcon />
            <Typography>{t('broadcast.startScreenshare')}</Typography>
          </FBoxVCenter>
        </Paper>
      )}
    </Box>
  );
}
