import { addCookieFunction, CookieSetting } from 'cookieSettings';
import LogRocket from 'logrocket';

import setupLogRocketReact from 'logrocket-react';

import { logRocketAppId } from './config';

addCookieFunction(() => {
  if (logRocketAppId) {
    LogRocket.init(logRocketAppId);
    setupLogRocketReact(LogRocket);
  }
}, CookieSetting.AllCookies);
