import React from 'react';
import moment from 'moment';
import numeral from 'numeral';

import { Paper, BoxProps, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FBox, style } from '@badger/design-system';

import { NAVBAR_HEIGHT } from './navbar/constants';

import { SocialSpaceContext } from './context';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: style.colors.backgroundDark,
    minWidth: 80,
    padding: 4,
    marginTop: NAVBAR_HEIGHT + 16,
    [theme.breakpoints.up('md')]: {
      marginTop: 8,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 8,
    },
    [`${theme.breakpoints.only('sm')} and (orientation: landscape)`]: {
      marginTop: 8,
    },
  },
  text: {
    fontVariantNumeric: 'tabular-nums',
    color: 'white',
  },
}));

export const SpaceTimer = (props: BoxProps) => {
  const classes = useStyles();
  const { spaceTablesUsers } = React.useContext(SocialSpaceContext);

  const intervalRef = React.useRef<ReturnType<typeof setInterval>>();

  const [seconds, setSeconds] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [prevTimerStartTime, setPrevTimerStartTime] = React.useState('');

  const setTime = React.useCallback(() => {
    if (spaceTablesUsers?.timerStartTime && spaceTablesUsers?.timerDurationSeconds) {
      const timerEndTime = moment(spaceTablesUsers.timerStartTime).add(spaceTablesUsers.timerDurationSeconds, 'second');

      const timeLeft = moment.duration(timerEndTime.diff(moment()));

      setSeconds(timeLeft.seconds());
      setMinutes(timeLeft.minutes());
    }
  }, [setSeconds, setMinutes, spaceTablesUsers]);

  React.useEffect(() => {
    if (spaceTablesUsers?.timerStartTime && spaceTablesUsers?.timerDurationSeconds) {
      if (spaceTablesUsers.timerStartTime !== prevTimerStartTime) {
        setPrevTimerStartTime(spaceTablesUsers.timerStartTime);

        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }

        const id = setInterval(() => {
          setTime();
        }, 300);

        intervalRef.current = id;
      }
    }
  }, [spaceTablesUsers, setTime, prevTimerStartTime, setPrevTimerStartTime]);

  if (seconds > 0 || minutes > 0) {
    return (
      <FBox {...props}>
        <Paper className={classes.container}>
          <Typography className={classes.text} align="center" variant="h4">
            {`${minutes}:${numeral(seconds).format('00')}`}
          </Typography>
        </Paper>
      </FBox>
    );
  } else {
    return null;
  }
};
