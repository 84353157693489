import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { Typography, Button, makeStyles } from '@material-ui/core';
import { FBoxV } from '@badger/design-system';
import background from 'assets/background.png';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    '& .MuiTypography-root': {
      color: '#ffffff',
    },
  },
});

export const ThanksForSharing = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onClose = () => {
    window.close();
  };

  return (
    <FBoxV className={classes.root}>
      <FBoxV p={8} width="100%" justifyContent="center" alignItems="center" height="60vh">
        <Typography align="center" variant="h4">
          <FBoxV>
            {t('thanksForSharing.message')}
            <Typography align="center" variant="body1">
              {t('thanksForSharing.closeMessage')}
            </Typography>
            <Button onClick={onClose} color="primary">
              {t('thanksForSharing.close')}
            </Button>
          </FBoxV>
        </Typography>
      </FBoxV>
    </FBoxV>
  );
};
