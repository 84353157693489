import * as React from 'react';
import { Switch } from 'react-router-dom';

import { Table, SpaceLanding } from 'pages';

import { Broadcast } from '../pages/Broadcast';
import { GARoute } from './GARoute';
import { VideoStreamContextProvider } from 'components/socialSpace/VideoStreamContextProvider';

export const SocialSpaceVideoRouter = () => {
  return (
    <VideoStreamContextProvider>
      <Switch>
        <GARoute path="/in/:spaceSlug/group/:tableId">
          <Table />
        </GARoute>
        <GARoute path="/in/:spaceSlug/broadcast">
          <Broadcast />
        </GARoute>
        <GARoute path="/in/:spaceSlug">
          <SpaceLanding />
        </GARoute>
      </Switch>
    </VideoStreamContextProvider>
  );
};
