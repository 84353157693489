import * as React from 'react';

import { Route, RouteProps } from 'react-router-dom';
import { CircularProgress, Box } from '@material-ui/core';

import { useRegisterGuest } from 'auth.gql';
import { useHasAccount } from 'auth';
import Analytics from 'analytics';

interface Props extends RouteProps {
  children: React.ReactNode;
}

export const AuthRoute = ({ children, ...rest }: Props) => {
  const [registerGuest] = useRegisterGuest();

  const userHasAccount = useHasAccount();

  React.useEffect(() => {
    if (!userHasAccount) {
      registerGuest();
    }
  }, [registerGuest, userHasAccount]);

  React.useEffect(() => {
    Analytics.trackPageView(window.location.pathname + window.location.search);
  }, []);

  const loading = () => (
    <Box height="80vh" width="100%" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  );

  return <Route {...rest} render={() => (!userHasAccount ? loading() : children)} />;
};
