import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { style } from '@badger/design-system';

import { chatUrl } from 'config';
import { SidePanelContainer } from './SidePanelContainer';
import { SocialSpaceContext } from '../context';
import { addBreadcrumb } from '@sentry/browser';

const useStyles = makeStyles({
  closeIcon: {
    color: style.colors.primary,
  },
  container: {
    height: '100%',
    zIndex: 10,
    right: 0,
  },
  chatIframe: {
    width: '100%',
    height: '100%',
    borderWidth: 0,
    opacity: 0.8,
  },
});

interface Props {
  open: boolean;
}

export const ChatPanel = (props: Props) => {
  const classes = useStyles();

  const [alertUnreadMessages, setAlertUnreadMessages] = React.useState(false);
  const [unreadMessages, setUnreadMessages] = React.useState(0);

  const { spaceSlug } = React.useContext(SocialSpaceContext);

  const fullChatUrl = `${chatUrl}/channel/${spaceSlug}?layout=embedded`;

  const iframeRef = React.useRef<HTMLIFrameElement>();

  const reload = React.useCallback(() => {
    if (iframeRef.current) {
      iframeRef.current.src = fullChatUrl;
    }
    addBreadcrumb({
      message: 'Received chat authorization error from panel',
      data: {
        hasIframeRef: !!iframeRef.current,
        fullChatUrl,
      },
    });
  }, [fullChatUrl]);

  React.useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (event.data.chat === 'authorizationError') {
        reload();
      }
      if (event.data?.data?.name === spaceSlug) {
        if (event.data.eventName === 'unread-changed-by-subscription') {
          const messageAlert = event.data.data.alert;
          setAlertUnreadMessages(messageAlert);
          if (!messageAlert) {
            setUnreadMessages(0);
          }
        }
        // t is the system type of message
        else if (event.data.eventName === 'new-message' && !event.data.data.t) {
          setUnreadMessages(unreadMessages + 1);
        }
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [spaceSlug, alertUnreadMessages, unreadMessages, reload]);

  return (
    <SidePanelContainer open={props.open}>
      <iframe
        ref={(e) => {
          iframeRef.current = e ?? undefined;
        }}
        hidden={!props.open}
        title="Lobby Chat"
        src={fullChatUrl}
        className={classes.chatIframe}
      />
    </SidePanelContainer>
  );
};
