import { UID } from 'agora-rtc-sdk-ng';
import { BroadcasterType } from 'gql/types/globals';

export function parseBroadcasterType(uid: UID) {
  const numericUid = typeof uid === 'string' ? Number.parseInt(uid) : uid;

  const type = numericUid % 3;
  switch (type) {
    case 0:
      return BroadcasterType.VIDEO;
    case 1:
      return BroadcasterType.SCREENSHARE;
    case 2:
      return BroadcasterType.AUDIENCE;
  }
}

export function parseBroadcasterUserId(uid: UID) {
  const numericUid = typeof uid === 'string' ? Number.parseInt(uid) : uid;
  return Math.floor(numericUid / 3);
}
