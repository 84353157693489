import React from 'react';
import { useSnackbar } from 'notistack';
import { BlowUpTableSnackBar } from 'components/socialSpace/snackbar';

import { SocialTableActionsEventType } from 'gql/types/globals';

import { useJoinNextSocialTable, useLazyGetSocialSpace } from 'social.gql';

import { useLeaveSocialTable, useSocialTableActionsSubscription } from './tableSubscriptions.gql';
import { useGotoTable } from './urls';
import { useJoinAndGotoTable } from './joinAndGotoTable';
import { logger } from 'logging';

interface Props {
  spaceId: string;
  tableId: string;
  spaceSlug: string;
}

export function useTableSubscriptions(props: Props) {
  const { spaceId, tableId, spaceSlug } = props;
  //useSingleUserJoinOtherTableNotification(props);

  const [joinNextSocialTable] = useJoinNextSocialTable();
  const [joinNextSocialTableBySlug] = useLazyGetSocialSpace(async (data) => {
    if (data?.socialSpace?.id) {
      const joined = await joinNextSocialTable({
        variables: {
          input: {
            spaceId: data.socialSpace.id,
          },
        },
      });
      const nextTableId = joined?.data?.joinNextSocialTable?.table?.id;
      const nextFeedCredentials = joined?.data?.joinNextSocialTable?.videoChatCredentials;
      if (nextTableId && nextFeedCredentials) {
        gotoTable(spaceSlug, nextTableId, nextFeedCredentials);
      }
    }
  });
  const gotoTable = useGotoTable();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [leaveSocialTable] = useLeaveSocialTable();
  React.useEffect(() => {
    return () => {
      leaveSocialTable({
        variables: {
          input: {
            spaceId,
            tableId,
          },
        },
      });
    };
  }, [leaveSocialTable, spaceId, tableId]);

  const joinAndGoToTable = useJoinAndGotoTable(props);

  useSocialTableActionsSubscription(
    {
      input: {
        spaceId,
        tableId,
      },
    },
    (message) => {
      const data = message.data?.socialTableActions;
      switch (data?.type) {
        case SocialTableActionsEventType.BLOW_UP_TABLE: {
          const tableId = data.newTable?.id;
          const action = () => {
            logger.info('Table blowing up', { tableId });
            if (tableId) {
              joinAndGoToTable(tableId);
            } else {
              joinNextSocialTableBySlug({
                variables: {
                  input: {
                    slug: spaceSlug,
                  },
                },
              });
            }
            closeSnackbar(SocialTableActionsEventType.BLOW_UP_TABLE);
          };
          enqueueSnackbar(<BlowUpTableSnackBar actionDelay={30} action={action} />, {
            key: SocialTableActionsEventType.BLOW_UP_TABLE,
            persist: true,
            preventDuplicate: true,
          });
          break;
        }
        /*
        case SocialTableActionsEventType.JOIN_SINGLE_USER_TABLE:
          if (data.newTable) {
            const tableId = data.newTable.id;
            const key = SocialTableActionsEventType.JOIN_SINGLE_USER_TABLE;
            const action = () => {
              logger.info('Join single user table', { data });
              joinAndGoToTable(tableId);
              closeSnackbar(key);
            };
            enqueueSnackbar(<JoinSingleUserTableSnackBar snackKey={key} action={action} />, {
              key,
              autoHideDuration: 15000,
              preventDuplicate: true,
            });
          }
          break;
        */
      }
    }
  );

  // make sure we close notifications when switching table
  React.useEffect(() => {
    return () => {
      closeSnackbar(SocialTableActionsEventType.JOIN_SINGLE_USER_TABLE);
      closeSnackbar(SocialTableActionsEventType.BLOW_UP_TABLE);
    };
  }, [closeSnackbar]);
}
