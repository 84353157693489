import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  Typography,
  useTheme,
  CircularProgress,
  TextField,
  Tabs,
  Tab,
  Button,
  Dialog,
  Link,
  makeStyles,
} from '@material-ui/core';

import { FBoxV, FBox } from '@badger/design-system';

import Analytics from 'analytics';
import { Role, SignUpErrorType, LoginErrorType } from 'gql/types/globals';
import { useRegister, useLogin } from 'auth.gql';

enum TabIndex {
  REGISTER,
  LOGIN,
}

const useStyles = makeStyles({
  privacyLink: {
    color: 'black',
    textDecoration: 'underline',
    textAlign: 'center',
    padding: 8,
    paddingTop: 0,
  },
});

interface Props {
  open: boolean;
  onClose: (loggedIn: boolean) => void;
  onLoginRegister?: () => void;
}

export const LoginRegisterDialog = (props: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles();

  const [tabIndex, setTabIndex] = React.useState(TabIndex.LOGIN);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const [register, registerState] = useRegister((data) => {
    if (data.signUp && !data.signUp.error && data.signUp.token) {
      props.onClose(true);
    } else if (data.signUp.error) {
      if (data.signUp.error.type === SignUpErrorType.USER_EXISTS) {
        setErrorMessage(t('loginRegisterPopover.errorAccountExists'));
      } else if (data.signUp.error.type === SignUpErrorType.UNKNOWN) {
        setErrorMessage(t('loginRegisterPopover.errorUnknown'));
      }
    }
  });

  const [login, loginState] = useLogin((data) => {
    if (!data.login?.error && data.login?.token) {
      props.onClose(true);
    } else if (data.login.error) {
      if (data.login.error.type === LoginErrorType.ACCOUNT_NOT_FOUND) {
        setErrorMessage(t('loginRegisterPopover.errorAccountNotFound'));
      } else if (data.login.error.type === LoginErrorType.AUTHENTICATION_FAILED) {
        setErrorMessage(t('loginRegisterPopover.errorWrongPassword'));
      } else if (data.login.error.type === LoginErrorType.UNKNOWN) {
        setErrorMessage(t('loginRegisterPopover.errorUnknown'));
      }
    }
  });

  const loading = loginState.loading || registerState.loading;

  const handleTabChange = (event: React.ChangeEvent<{}>, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };

  const styles = {
    loadingIndicator: {
      color: theme.palette.background.default,
    },
  };

  const onRegisterPress = (event: React.FormEvent) => {
    Analytics.trackLoginRegisterDialogRegister();

    event.preventDefault();

    register({
      variables: {
        input: {
          email,
          password,
          role: Role.VISITOR,
        },
      },
    });
  };

  const onLoginPress = (event: React.FormEvent) => {
    Analytics.trackLoginRegisterDialogLogin();

    event.preventDefault();

    login({
      variables: {
        input: {
          email,
          password,
        },
      },
    });
  };

  const renderForm = (onSubmitTitle: string, onSubmit: (event: React.FormEvent) => void, register?: boolean) => {
    return (
      <form onSubmit={onSubmit}>
        <FBoxV p={1}>
          <TextField
            label={t('loginRegisterPopover.emailInput')}
            value={email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (errorMessage) {
                setErrorMessage('');
              }

              setEmail(event.target.value);
            }}
            variant="outlined"
            size="small"
            style={{ marginTop: 8 }}
            autoComplete="username"
          />
          <TextField
            label={t('loginRegisterPopover.passwordInput')}
            value={password}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (errorMessage) {
                setErrorMessage('');
              }

              setPassword(event.target.value);
            }}
            variant="outlined"
            type="password"
            size="small"
            style={{ marginTop: 16 }}
            autoComplete={register ? 'new-password' : 'current-password'}
          />
          <FBox mt={1} height={20}>
            <Typography color="error" variant="body2">
              {errorMessage}
            </Typography>
          </FBox>
          <Button style={{ marginTop: 8 }} type="submit" variant="contained" color="secondary">
            {loading ? <CircularProgress size={24} style={styles.loadingIndicator} /> : onSubmitTitle}
          </Button>
        </FBoxV>
      </form>
    );
  };

  return (
    <Dialog open={props.open} onClose={() => props.onClose(false)}>
      <FBoxV>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label={t('loginRegisterPopover.registerTab')} />
          <Tab label={t('loginRegisterPopover.loginTab')} />
        </Tabs>
        {tabIndex === TabIndex.LOGIN ? (
          renderForm(t('loginRegisterPopover.loginButton'), onLoginPress)
        ) : (
          <>
            {renderForm(t('loginRegisterPopover.registerButton'), onRegisterPress, true)}
            <Link className={classes.privacyLink} target="_blank" href="https://www.humby.io/legal/privacy-policy">
              {t('common.privacy')}
            </Link>
          </>
        )}
      </FBoxV>
    </Dialog>
  );
};
