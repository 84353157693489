import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';

import { SocialSpaceUsers, SocialSpaceUsersVariables } from 'gql/types/SocialSpaceUsers';
import { QuerySocialSpaceUsersInput } from 'gql/types/globals';

export const SOCIAL_SPACE_USERS = gql`
  query SocialSpaceUsers($input: QuerySocialSpaceUsersInput!) {
    socialSpaceUsers(input: $input) {
      id
      email
      profile {
        id
        name
        company
        socialBlurb
        jobTitle
        twitter
        linkedIn
        pictureUrl
      }
    }
  }
`;

export function useLazySocialSpaceUsers(input: QuerySocialSpaceUsersInput) {
  return useLazyQuery<SocialSpaceUsers, SocialSpaceUsersVariables>(SOCIAL_SPACE_USERS, {
    variables: {
      input,
    },
  });
}
