import * as React from 'react';

import { playTrack } from 'components/socialSpace/VideoStreamContext';
import { VideoConstraints } from './table/VideoConstraints';

export type VideoFilterType =
  | 'video_filter_refract'
  | 'video_filter_offset'
  | 'video_filter_linear'
  | 'video_filter_discrete'
  | 'video_filter_color_offset'
  | 'video_filter_edge'
  | 'video_filter_comic';

export function useFilteredVideoTrack(videoTrack: MediaStreamTrack | undefined, filterName: VideoFilterType | null) {
  const [filteredVideoTrack, setFilteredVideoTrack] = React.useState<MediaStreamTrack>();
  const [context, setContext] = React.useState<CanvasRenderingContext2D | null>(null);

  React.useEffect(() => {
    if (context) {
      context.filter = filterName ? `url(#${filterName})` : 'none';
    }
  }, [context, filterName]);

  React.useEffect(() => {
    if (!videoTrack) {
      setFilteredVideoTrack(undefined);
      setContext(null);
      return;
    }
    const video = document.createElement('video');
    playTrack(video, videoTrack);

    const frameRate = videoTrack.getSettings().frameRate ?? VideoConstraints.frameRate;
    const width = videoTrack.getSettings().width ?? VideoConstraints.width;
    const height = videoTrack.getSettings().height ?? VideoConstraints.height;

    //const canvas = canvasRef.current;
    const canvas = document.createElement('canvas');
    if (!canvas) {
      return;
    }
    canvas.width = width;
    canvas.height = height;

    const context = canvas.getContext('2d');
    setContext(context);
    if (!context) {
      return;
    }

    let running = true;
    const loop = () => {
      if (running && !video.ended) {
        context.drawImage(video, 0, 0);
        //not supported yet
        //stream.requestFrame();
        setTimeout(loop, 1000 / frameRate);
      }
    };
    loop();

    //@ts-ignore
    const stream = canvas.captureStream(frameRate);
    setFilteredVideoTrack(stream.getVideoTracks()[0]);
    return () => {
      running = false;
    };
  }, [videoTrack]);

  React.useEffect(() => {
    if (filteredVideoTrack) {
      return () => {
        filteredVideoTrack.stop();
      };
    }
  }, [filteredVideoTrack]);

  return filteredVideoTrack;
}

export const VideoStreamFilter = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={0} height={0}>
    <filter id="video_filter_refract">
      <feTurbulence baseFrequency="0.01" />
      <feColorMatrix type="hueRotate">
        <animate attributeName="values" values="0;360" dur="3s" repeatCount="indefinite" />
      </feColorMatrix>
      <feDisplacementMap in="SourceGraphic" scale="20" xChannelSelector="R" yChannelSelector="G" />
    </filter>
    <filter id="video_filter_offset">
      <feOffset in="SourceGraphic" dx="10" dy="10" />
    </filter>
    <filter id="video_filter_linear">
      <feComponentTransfer>
        <feFuncR type="linear" slope="0.5" intercept="0"></feFuncR>
        <feFuncG type="linear" slope="0.5" intercept="0.25"></feFuncG>
        <feFuncB type="linear" slope="0.5" intercept="0.5"></feFuncB>
      </feComponentTransfer>
    </filter>
    <filter id="video_filter_discrete">
      <feComponentTransfer>
        <feFuncG type="discrete" tableValues="0.0 0.15 0.8 1.0" />
      </feComponentTransfer>
    </filter>
    <filter
      id="video_filter_color_offset"
      x="-20%"
      y="-20%"
      width="140%"
      height="140%"
      filterUnits="objectBoundingBox"
      primitiveUnits="userSpaceOnUse"
      colorInterpolationFilters="linearRGB"
    >
      <feColorMatrix
        type="hueRotate"
        values="90"
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        in="SourceGraphic"
        result="colormatrix2"
      />
      <feColorMatrix
        type="hueRotate"
        values="270"
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        in="SourceGraphic"
        result="colormatrix3"
      />
      <feOffset dx="5" dy="5" x="0%" y="0%" width="100%" height="100%" in="colormatrix2" result="offset3" />
      <feBlend
        mode="difference"
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        in="colormatrix3"
        in2="offset3"
        result="blend1"
      />
      <feBlend mode="color" x="0%" y="0%" width="100%" height="100%" in="blend1" in2="SourceGraphic" result="blend2" />
    </filter>
    <filter id="video_filter_edge">
      <feConvolveMatrix order="3 3" preserveAlpha="true" kernelMatrix="-1 -1 -1 -1 8 -1 -1 -1 -1" />
    </filter>
    <filter
      id="video_filter_comic"
      x="-20%"
      y="-20%"
      width="140%"
      height="140%"
      filterUnits="objectBoundingBox"
      primitiveUnits="userSpaceOnUse"
      colorInterpolationFilters="linearRGB"
    >
      <feColorMatrix
        type="saturate"
        values="2"
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        in="convolveMatrix"
        result="colormatrix5"
      />
      <feGaussianBlur
        stdDeviation="3 3"
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        in="colormatrix5"
        edgeMode="duplicate"
        result="blur"
      />
      <feComponentTransfer x="0%" y="0%" width="100%" height="100%" in="blur" result="componentTransfer">
        <feFuncR type="identity" />
        <feFuncG type="discrete" tableValues="0.0 0.15 0.5 0.7 1.0" />
        <feFuncB type="identity" />
        <feFuncA type="identity" />
      </feComponentTransfer>
      <feComponentTransfer x="0%" y="0%" width="100%" height="100%" in="blur" result="componentTransfer3">
        <feFuncR type="discrete" tableValues="0.0 0.15 0.5 0.7 1.0" />
        <feFuncG type="discrete" tableValues="0.0 0.15 0.5 0.7 1.0" />
        <feFuncB type="discrete" tableValues="0.0 0.15 0.5 0.7 1.0" />
        <feFuncA type="identity" />
      </feComponentTransfer>
      <feConvolveMatrix
        order="3 3"
        kernelMatrix="-1 -1 -1 -1 8 -1 -1 -1 -1"
        divisor="0.1"
        bias="0"
        targetX="0"
        targetY="0"
        edgeMode="duplicate"
        preserveAlpha="true"
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        in="componentTransfer3"
        result="convolveMatrix"
      />
      <feColorMatrix
        type="luminanceToAlpha"
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        in="convolveMatrix"
        result="colormatrix4"
      />
      <feBlend
        mode="multiply"
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        in="componentTransfer"
        in2="colormatrix4"
        result="blend2"
      />
    </filter>
  </svg>
);
