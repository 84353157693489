import React from 'react';

import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemText, ListItemIcon, Collapse, Menu, Grow } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MicIcon from '@material-ui/icons/Mic';
import VideocamIcon from '@material-ui/icons/Videocam';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { VideoStreamContext } from '../VideoStreamContext';
import Analytics from 'analytics';
import { VideoFilterType } from '../VideoStreamFilter';

interface Props {
  icon?: React.ReactElement;
}

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
  },
});

export function VideoDeviceSelectionMenu(props: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const [audioDevices, setAudioDevices] = React.useState<MediaDeviceInfo[]>();
  const [videoDevices, setVideoDevices] = React.useState<MediaDeviceInfo[]>();

  const { videoDevice, audioDevice, setVideoDevice, setAudioDevice, setVideoFilter } = React.useContext(
    VideoStreamContext
  );

  const [filtersOpen, setFiltersOpen] = React.useState(false);

  const listDevices = React.useCallback(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const audio: MediaDeviceInfo[] = [];
      const video: MediaDeviceInfo[] = [];

      devices.forEach((device) => {
        switch (device.kind) {
          case 'audioinput':
            audio.push(device);
            break;
          case 'videoinput':
            video.push(device);
            break;
        }
      });
      setAudioDevices(audio);
      setVideoDevices(video);
    });
  }, []);

  React.useEffect(() => {
    // This is not always send by the browser, but if it is, we should react to it
    navigator.mediaDevices.addEventListener('devicechange', listDevices);

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', listDevices);
    };
  }, [listDevices]);

  React.useEffect(() => {
    // Rescan on each open in case the browser was lazy
    if (anchorEl) {
      listDevices();
    }
  }, [listDevices, anchorEl]);

  const onMenuOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    Analytics.trackDeviceSelectionOpen();
    setAnchorEl(event.currentTarget);
  };

  const onMenuClose = () => {
    Analytics.trackDeviceSelectionClose();
    setAnchorEl(null);
  };

  const onAudioDeviceChanged = (deviceId: string) => {
    Analytics.trackDeviceSelectionChangeAudio();
    setAudioDevice(deviceId);
  };
  const onVideoDeviceChanged = (deviceId: string) => {
    Analytics.trackDeviceSelectionChangeVideo();
    setVideoDevice(deviceId);
  };

  const onFiltersOpen = () => {
    setFiltersOpen(!filtersOpen);
    Analytics.trackFiltersOpen(filtersOpen);
  };

  const onSetVideoFilter = (filter: VideoFilterType | null) => {
    Analytics.trackFiltersChange(filter);
    setVideoFilter(filter);
  };

  return (
    <>
      <IconButton aria-label="audio video settings" color="primary" onClick={onMenuOpen}>
        {props.icon ? props.icon : <MoreVertIcon className={classes.icon} />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        open={open}
        onClose={onMenuClose}
        TransitionComponent={Grow}
      >
        <Collapse in={!filtersOpen}>
          <List>
            {audioDevices?.map((device, index) => (
              <ListItem
                key={device.deviceId}
                button
                onClick={() => onAudioDeviceChanged(device.deviceId)}
                selected={device.deviceId === audioDevice}
              >
                <ListItemIcon>
                  <MicIcon />
                </ListItemIcon>
                <ListItemText>{device.label || t('videoPreview.audioDevice', { number: index + 1 })}</ListItemText>
              </ListItem>
            ))}
          </List>
          <List>
            {videoDevices?.map((device, index) => (
              <ListItem
                key={device.deviceId}
                button
                onClick={() => onVideoDeviceChanged(device.deviceId)}
                selected={device.deviceId === videoDevice}
              >
                <ListItemIcon>
                  <VideocamIcon />
                </ListItemIcon>
                <ListItemText>{device.label || t('videoPreview.videoDevice', { number: index + 1 })}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Collapse>
        <ListItem button onClick={onFiltersOpen}>
          <ListItemIcon>
            <CameraEnhanceIcon />
          </ListItemIcon>
          <ListItemText>{t('filters.filters')}</ListItemText>
          {filtersOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={filtersOpen}>
          <List>
            <ListItem button onClick={() => onSetVideoFilter('video_filter_refract')}>
              <ListItemText>{t('filters.refract')}</ListItemText>
            </ListItem>
            <ListItem button onClick={() => onSetVideoFilter('video_filter_linear')}>
              <ListItemText>{t('filters.linear')}</ListItemText>
            </ListItem>
            <ListItem button onClick={() => onSetVideoFilter('video_filter_discrete')}>
              <ListItemText>{t('filters.discrete')}</ListItemText>
            </ListItem>
            <ListItem button onClick={() => onSetVideoFilter('video_filter_color_offset')}>
              <ListItemText>{t('filters.colorOffset')}</ListItemText>
            </ListItem>
            <ListItem button onClick={() => onSetVideoFilter('video_filter_edge')}>
              <ListItemText>{t('filters.edge')}</ListItemText>
            </ListItem>
            <ListItem button onClick={() => onSetVideoFilter('video_filter_comic')}>
              <ListItemText>{t('filters.comic')}</ListItemText>
            </ListItem>
            <ListItem button onClick={() => onSetVideoFilter(null)}>
              <ListItemText>{t('filters.disable')}</ListItemText>
            </ListItem>
          </List>
        </Collapse>
      </Menu>
    </>
  );
}
