import gql from 'graphql-tag';
import { useMutation, useSubscription } from '@apollo/client';
import { SubscriptionResult } from '@apollo/react-common';

import { LeaveSocialTable, LeaveSocialTableVariables } from 'gql/types/LeaveSocialTable';
import { SocialTableActions, SocialTableActionsVariables } from 'gql/types/SocialTableActions';

export const LEAVE_SOCIAL_TABLE = gql`
  mutation LeaveSocialTable($input: LeaveSocialTableInput!) {
    leaveSocialTable(input: $input) {
      error {
        type
      }
    }
  }
`;

export const SOCIAL_TABLE_ACTIONS = gql`
  subscription SocialTableActions($input: SubscribeToSocialTableActionsInput!) {
    socialTableActions(input: $input) {
      tableId
      type
      newTable {
        id
      }
    }
  }
`;

export function useLeaveSocialTable() {
  return useMutation<LeaveSocialTable, LeaveSocialTableVariables>(LEAVE_SOCIAL_TABLE);
}

export function useSocialTableActionsSubscription(
  variables: SocialTableActionsVariables,
  onSubscriptionData?: (data: SubscriptionResult<SocialTableActions>) => void
) {
  return useSubscription<SocialTableActions, SocialTableActionsVariables>(SOCIAL_TABLE_ACTIONS, {
    variables,
    onSubscriptionData: (data) => {
      onSubscriptionData?.(data.subscriptionData);
    },
  });
}
