import gql from 'graphql-tag';
import { useMutation, useSubscription } from '@apollo/client';
import { SubscriptionResult } from '@apollo/react-common';

import { UserSocialEvents } from 'gql/types/UserSocialEvents';
import { AcceptInviteToOneOnOne, AcceptInviteToOneOnOneVariables } from 'gql/types/AcceptInviteToOneOnOne';
import { DeclineInviteToOneOnOne, DeclineInviteToOneOnOneVariables } from 'gql/types/DeclineInviteToOneOnOne';
import { AcceptInviteToBroadcast, AcceptInviteToBroadcastVariables } from 'gql/types/AcceptInviteToBroadcast';
import { DeclineInviteToBroadcast, DeclineInviteToBroadcastVariables } from 'gql/types/DeclineInviteToBroadcast';

export const SUBSCRIBE_TO_USER_SOCIAL_EVENTS = gql`
  subscription UserSocialEvents {
    userSocialEvents {
      id
      type
      sender {
        id
        profile {
          id
          name
          socialBlurb
          company
          jobTitle
          twitter
          linkedIn
          pictureUrl
        }
      }
      newTable {
        id
      }
      videoChatCredentials {
        roomName
        roomPassword
      }
    }
  }
`;

export function useUserSocialEventsSubscription(
  onSubscriptionData?: (data: SubscriptionResult<UserSocialEvents>) => void
) {
  return useSubscription<UserSocialEvents>(SUBSCRIBE_TO_USER_SOCIAL_EVENTS, {
    onSubscriptionData: (data) => {
      onSubscriptionData?.(data.subscriptionData);
    },
  });
}

export const ACCEPT_INVITE_TO_ONE_ON_ONE = gql`
  mutation AcceptInviteToOneOnOne($input: AcceptInviteToOneOnOneInput!) {
    acceptInviteToOneOnOne(input: $input) {
      table {
        id
      }
      videoChatCredentials {
        roomName
        roomPassword
      }
      error {
        type
      }
    }
  }
`;
export function useAcceptInviteToOneOnOne(onCompleted?: (data: AcceptInviteToOneOnOne) => void) {
  return useMutation<AcceptInviteToOneOnOne, AcceptInviteToOneOnOneVariables>(ACCEPT_INVITE_TO_ONE_ON_ONE, {
    onCompleted,
  });
}

export const DECLINE_INVITE_TO_ONE_ON_ONE = gql`
  mutation DeclineInviteToOneOnOne($input: DeclineInviteToOneOnOneInput!) {
    declineInviteToOneOnOne(input: $input) {
      error {
        type
      }
    }
  }
`;
export function useDeclineInviteToOneOnOne(onCompleted?: (data: DeclineInviteToOneOnOne) => void) {
  return useMutation<DeclineInviteToOneOnOne, DeclineInviteToOneOnOneVariables>(DECLINE_INVITE_TO_ONE_ON_ONE, {
    onCompleted,
  });
}

export const ACCEPT_INVITE_TO_BROADCAST = gql`
  mutation AcceptInviteToBroadcast($input: AcceptInviteToBroadcastInput!) {
    acceptInviteToBroadcast(input: $input) {
      broadcastCredentials {
        uid
        channelName
        token
      }
      error {
        type
      }
    }
  }
`;
export function useAcceptInviteToBroadcast(onCompleted?: (data: AcceptInviteToBroadcast) => void) {
  return useMutation<AcceptInviteToBroadcast, AcceptInviteToBroadcastVariables>(ACCEPT_INVITE_TO_BROADCAST, {
    onCompleted,
  });
}

export const DECLINE_INVITE_TO_BROADCAST = gql`
  mutation DeclineInviteToBroadcast($input: DeclineInviteToBroadcastInput!) {
    declineInviteToBroadcast(input: $input) {
      error {
        type
      }
    }
  }
`;
export function useDeclineInviteToBroadcast(onCompleted?: (data: DeclineInviteToBroadcast) => void) {
  return useMutation<DeclineInviteToBroadcast, DeclineInviteToBroadcastVariables>(DECLINE_INVITE_TO_BROADCAST, {
    onCompleted,
  });
}
