import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetUserSubscription, useRegisterPushSubscription } from './PushSubscriptionDialog.gql';
import _ from 'lodash';
import { Button, Dialog, Typography } from '@material-ui/core';
import { FBox, FBoxV } from '@badger/design-system';
import { captureException } from 'logrocket';
import { isIframe } from 'util/isIframe';
import analytics from 'analytics';

const EMPTY_SHOW_TIMEOUT = 2000;

function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

interface Props {
  spaceId?: string;
  tags?: string[];
  empty?: boolean;
}
export function PushSubscriptionDialog(props?: Props) {
  const { t } = useTranslation();
  const [permission, setPermission] = React.useState<PushPermissionState>();

  const [registerPushSubscription] = useRegisterPushSubscription();
  const { data } = useGetUserSubscription();

  const [showDialog, setShowDialog] = React.useState(false);

  React.useEffect(() => {
    if (!data?.userSubscription) {
      return;
    }

    const vapidPublicKey = data.userSubscription.vapidPublicKey;
    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.pushManager
          ?.permissionState({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey,
          })
          .then((state) => {
            setPermission(state);
          });
      });
    }
  }, [data]);

  const getSubscription = React.useCallback(
    async (registration: ServiceWorkerRegistration) => {
      const subscription = await registration.pushManager.getSubscription();

      if (subscription) {
        return subscription;
      }

      if (!data?.userSubscription) {
        return;
      }

      const vapidPublicKey = data.userSubscription.vapidPublicKey;
      const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

      return registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey,
      });
    },
    [data]
  );

  const registerPush = React.useCallback(
    async function () {
      try {
        if (!data?.userSubscription) {
          return;
        }
        const registration = await navigator.serviceWorker.ready;
        if (!registration) {
          return;
        }
        const subscription = await getSubscription(registration);
        if (!subscription) {
          return;
        }

        const spaceIds = _.union(data.userSubscription.spaceIds, props?.spaceId);
        const tags = _.union(data.userSubscription.tags, props?.tags);
        registerPushSubscription({
          variables: {
            input: {
              subscription,
              endpoint: subscription.endpoint,
              userAgent: navigator.userAgent,
              spaceIds,
              tags,
            },
          },
        });
      } catch (error) {
        captureException(error);
      }
    },
    [data, getSubscription, props, registerPushSubscription]
  );

  React.useEffect(() => {
    if (!data || !permission) {
      return;
    }

    /*
    if (permission === 'denied') {
      return;
    }
    */
    if (permission === 'granted') {
      registerPush();
    } else if (props?.empty) {
      setTimeout(() => {
        analytics.track('push-subscription-dialog/open');
        setShowDialog(true);
      }, EMPTY_SHOW_TIMEOUT);
    }
  }, [data, permission, props, registerPush]);

  if (isIframe) {
    return null;
  }

  const onSubmit = () => {
    analytics.track('push-subscription-dialog/allow');
    setShowDialog(false);
    registerPush();
  };

  const onCancel = () => {
    analytics.track('push-subscription-dialog/dismiss');
    setShowDialog(false);
  };

  return (
    <Dialog open={showDialog}>
      <FBoxV p={2} m={1} alignItems="center">
        <Typography variant="h5">{t('pushSubscriptionDialog.title')}</Typography>
        <FBox pt={2} pb={2}>
          <Typography variant="body1">{t('pushSubscriptionDialog.message')}</Typography>
        </FBox>
        <FBox alignSelf="stretch" justifyContent="space-evenly">
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            {t('pushSubscriptionDialog.ok')}
          </Button>
          <Button variant="contained" onClick={onCancel}>
            {t('pushSubscriptionDialog.no')}
          </Button>
        </FBox>
      </FBoxV>
    </Dialog>
  );
}
