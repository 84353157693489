import * as React from 'react';

import { Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { style } from '@badger/design-system';

interface StyleProps {
  selected?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles({
  navBarButtonContainer: {
    position: 'relative',
    height: '100%',
    width: '71px',
  },
  navBarButton: {
    height: '100%',
    width: '71px',
    padding: 0,
    color: 'white',
  },
  navBarButtonOpen: {
    color: style.colors.secondary,
  },
  navBarButtonHighlight: {
    visibility: 'hidden',
    backgroundColor: style.colors.secondary,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '4px',
  },
  navBarButtonHighlightOpen: {
    visibility: 'visible',
  },
});

type Props = StyleProps & {
  children: React.ReactNode;
};

export const NavBarButton = (props: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.navBarButtonContainer}>
      <IconButton
        className={`${classes.navBarButton} ${props.selected ? classes.navBarButtonOpen : ''}`}
        onClick={props.onClick}
      >
        {props.children}
      </IconButton>
      <Box className={`${classes.navBarButtonHighlight} ${props.selected ? classes.navBarButtonHighlightOpen : ''}`} />
    </Box>
  );
};
