import React from 'react';

import MicOffIcon from '@material-ui/icons/MicOff';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { style } from '@badger/design-system';

import { ReactComponent as ProfilePictureIcon } from 'assets/icons/profilePicture.svg';
import { VideoProfileOverlay } from './VideoProfileOverlay';
import { playTrack } from '../VideoStreamContext';
import { useUserId } from 'auth';
import { useUserProfile } from './VideoProfileOverlay.gql';

const useStyles = makeStyles({
  videoContainerRoot: {
    position: 'relative',
    width: '100%',
    height: '100%',
    // FIXME hack until design system has all correct transparent colors
    backgroundColor: `${style.colors.backgroundDark}b2`,
    '& > video': {
      position: 'relative',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '8px',
    },
  },
  overlay: {
    position: 'absolute',
    bottom: -1,
    left: 0,
    right: 0,
  },
  videoPlaceholder: {
    position: 'absolute',
    color: 'white',
    top: 'calc(50% - 14px)',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
  },
  videoPlaceholderIcon: {
    width: '80%',
  },
  micMutedOverlay: {
    position: 'absolute',
    color: 'white',
    left: 0,
    top: 0,
    width: 'min(20%, 100px)',
    height: 'min(20%, 100px)',
    margin: 10,
  },
});

interface Props {
  userId: string;
  audioTrack?: MediaStreamTrack;
  videoTrack?: MediaStreamTrack;
}

export function VideoContainer(props: Props) {
  const classes = useStyles();

  const { data } = useUserProfile(props.userId);
  const userId = useUserId();

  const localUser = React.useMemo(() => props.userId === userId, [props.userId, userId]);
  const videoRef = React.useRef<HTMLVideoElement>();
  const audioRef = React.useRef<HTMLAudioElement>();

  React.useEffect(() => {
    if (audioRef.current) {
      if (props.audioTrack) {
        playTrack(audioRef.current, props.audioTrack);
      } else {
        audioRef.current.srcObject = null;
      }
    }
  }, [props.audioTrack]);

  React.useEffect(() => {
    if (videoRef.current) {
      if (props.videoTrack) {
        playTrack(videoRef.current, props.videoTrack);
      } else {
        videoRef.current.srcObject = null;
      }
    }
  }, [props.videoTrack]);

  const renderAvatar = () => {
    const pictureUrl = data?.userProfile?.profile?.pictureUrl;
    return pictureUrl ? (
      <img src={pictureUrl} alt="" className={classes.videoPlaceholder} />
    ) : (
      <ProfilePictureIcon className={`${classes.videoPlaceholder} ${classes.videoPlaceholderIcon}`} />
    );
  };

  return (
    <Paper className={classes.videoContainerRoot}>
      {renderAvatar()}
      {!localUser && (
        <audio
          ref={(e) => {
            audioRef.current = e ?? undefined;
          }}
          autoPlay
        />
      )}
      <video
        hidden={!props.videoTrack || !props.videoTrack.enabled}
        ref={(e: HTMLVideoElement) => {
          videoRef.current = e ?? undefined;
        }}
        autoPlay={false}
        muted
        controls={false}
      />
      {(!props.audioTrack || !props.audioTrack.enabled) && <MicOffIcon className={classes.micMutedOverlay} />}
      <div className={classes.overlay}>
        <VideoProfileOverlay userId={props.userId} localUser={localUser} />
      </div>
    </Paper>
  );
}
