import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { OuterPageContainer } from '@badger/design-system';
import { CircularProgress } from '@material-ui/core';
import { LobbyFooter } from 'components/socialSpace/LobbyFooter';

const useStyles = makeStyles({
  container: {
    flexDirection: 'row',
    overflow: 'auto',
  },
});

export const Feedback = () => {
  const classes = useStyles();

  React.useEffect(() => {
    (function (a, b, c, e, f) {
      const s = a.createElement('script');
      s.src = b;
      s.setAttribute('data-form-id', e);
      s.setAttribute('data-runner-id', c);
      s.setAttribute('data-url-params', f);
      s.setAttribute('data-scale', 'true');
      a.head.appendChild(s);
    })(
      window.document,
      'https://form.questionscout.com/qs-form-script.min.js',
      'muljhfnqehgylkw',
      '602fb0ccc441eb412085d1e9',
      '[]'
    );
  }, []);

  return (
    <>
      <OuterPageContainer className={classes.container}>
        <script id="muljhfnqehgylkw">
          <CircularProgress />
        </script>
        <LobbyFooter />
      </OuterPageContainer>
    </>
  );
};
