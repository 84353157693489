import * as React from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  OrganizerNotificationSnackBar,
  RedirectSnackBar,
  EndSnackBar,
  ProgressSnackBar,
} from 'components/socialSpace/snackbar';

import { useOrganizerSocialEventsSubscription } from './organizerSocialEvents.gql';
import { OrganizerSocialEventType } from 'gql/types/globals';

interface Props {
  spaceId: string;
  spaceSlug: string;
  isOwner: boolean;
}
export function useOrganizerSocialEvents({ spaceId, spaceSlug, isOwner }: Props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const { t } = useTranslation();

  useOrganizerSocialEventsSubscription({ spaceId }, (message) => {
    const data = message.data?.organizerSocialEvents;

    switch (data?.type) {
      case OrganizerSocialEventType.NOTIFY: {
        const key = data.id;

        enqueueSnackbar(<OrganizerNotificationSnackBar snackKey={key} message={data.message || ''} />, {
          key,
          persist: true,
        });
        break;
      }
      case OrganizerSocialEventType.REDIRECT: {
        const key = data.id;

        if (!isOwner) {
          enqueueSnackbar(
            <RedirectSnackBar
              actionDelay={30}
              action={() => {
                if (data.url) {
                  const url = data.url.startsWith('http') ? data.url : 'https://' + data.url;
                  window.location.href = url;
                }
              }}
            />,
            {
              key,
              persist: true,
              preventDuplicate: true,
            }
          );
        } else {
          enqueueSnackbar(
            <ProgressSnackBar
              snackKey={key}
              actionDelay={30}
              message={t('organizerNotifications.redirect')}
              completedMessage={t('organizerNotifications.redirectComplete')}
              noAction
            />,
            {
              key,
              persist: true,
              preventDuplicate: true,
            }
          );
        }
        break;
      }
      case OrganizerSocialEventType.END_SESSION: {
        const key = data.id;

        if (!isOwner) {
          enqueueSnackbar(
            <EndSnackBar
              actionDelay={30}
              action={() => {
                history.push(`/in/${spaceSlug}/session-closed`);
                closeSnackbar(key);
              }}
            />,
            {
              key,
              persist: true,
              preventDuplicate: true,
            }
          );
        } else {
          enqueueSnackbar(
            <ProgressSnackBar
              snackKey={key}
              actionDelay={30}
              message={t('organizerNotifications.endSession')}
              completedMessage={t('organizerNotifications.endSessionComplete')}
              noAction
            />,
            {
              key,
              persist: true,
              preventDuplicate: true,
            }
          );
        }
        break;
      }
    }
  });
}
