import React from 'react';

import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { style, FBox } from '@badger/design-system';

import { useHistory } from 'react-router-dom';
import { ReactComponent as GroupsIcon } from 'assets/icons/groups.svg';
import analytics from 'analytics';
import { isIframe } from 'util/isIframe';
import { useTags } from './useTags';

const useStyles = makeStyles({
  button: {
    flexGrow: 1,
    backgroundColor: '#ffffffbf',
    '&:hover': {
      backgroundColor: '#ffffffe5',
    },
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      height: '100%',
      paddingLeft: 4,
      paddingRight: 4,
    },
  },
  name: {
    paddingLeft: 4,
    paddingTop: 4,
  },
  nameLine: {},
  tagButton: {
    padding: 0,
    minWidth: 0,
  },
  statusLine: {
    color: style.colors.primary,
    width: '100%',
  },
  usersBox: {
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  userCount: {
    marginLeft: 4,
    marginRight: 4,
  },
});

export interface PublicSpaceCardProps {
  slug: string;
  name: string;
  tags: string[];
  numActiveUsers: number;
}

export const PublicSpaceCard = (props: PublicSpaceCardProps) => {
  const classes = useStyles();
  const history = useHistory();
  const tags = useTags();

  const onAddFilter = (withTag: string) => {
    analytics.trackPublicSpacesAddFilter(withTag);
    const newTags = tags?.slice() ?? [];
    if (!newTags.includes(withTag)) {
      newTags.push(withTag);
    }
    history.push(`/?tag=${newTags.join(',')}`);
  };

  const buttonProps = isIframe
    ? {
        href: `/in/${props.slug}`,
        target: '_top',
      }
    : {
        onClick: () => {
          analytics.trackLandingJoinPublicSpace();
          history.push(`/in/${props.slug}`);
        },
      };

  return (
    <Button className={classes.button} {...buttonProps}>
      <Typography variant="h6" align="left" className={classes.name}>
        {props.name}
      </Typography>
      <FBox className={classes.statusLine}>
        {props.tags.map((tag) => (
          <Button
            key={tag}
            className={classes.tagButton}
            onClick={(event) => {
              event.stopPropagation();
              onAddFilter(tag);
            }}
          >
            #{tag}
          </Button>
        ))}
        {props.numActiveUsers > 0 && (
          <>
            <FBox className={classes.usersBox}>
              <GroupsIcon />
              <Typography variant="h5" className={classes.userCount}>
                {props.numActiveUsers}
              </Typography>
            </FBox>
          </>
        )}
      </FBox>
    </Button>
  );
};
