import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';

import { SocialSpacePassword, SocialSpacePasswordVariables } from 'gql/types/SocialSpacePassword';
import { SetSocialSpacePassword, SetSocialSpacePasswordVariables } from 'gql/types/SetSocialSpacePassword';

const GET_SOCIAL_SPACE_PASSWORD = gql`
  query SocialSpacePassword($input: QuerySocialSpacePasswordInput!) {
    socialSpacePassword(input: $input) {
      spacePassword {
        id
        password
      }
      error {
        type
      }
    }
  }
`;

export function useSocialSpacePassword(
  variables: SocialSpacePasswordVariables,
  onCompleted?: (data: SocialSpacePassword) => void
) {
  return useQuery<SocialSpacePassword, SocialSpacePasswordVariables>(GET_SOCIAL_SPACE_PASSWORD, {
    onCompleted,
    variables,
  });
}

const SET_SOCIAL_SPACE_PASSWORD = gql`
  mutation SetSocialSpacePassword($input: SetSocialSpacePasswordInput!) {
    setSocialSpacePassword(input: $input) {
      spacePassword {
        id
        password
      }
      error {
        type
      }
    }
  }
`;

export function useSetSocialSpacePassword(onCompleted?: (data: SetSocialSpacePassword) => void) {
  return useMutation<SetSocialSpacePassword, SetSocialSpacePasswordVariables>(SET_SOCIAL_SPACE_PASSWORD, {
    onCompleted,
  });
}
