import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

import { JoinSocialSpace, JoinSocialSpaceVariables } from 'gql/types/JoinSocialSpace';

const JOIN_SOCIAL_SPACE = gql`
  mutation JoinSocialSpace($input: JoinSocialSpaceInput!) {
    joinSocialSpace(input: $input) {
      space {
        id
        slug
      }
      error {
        type
      }
    }
  }
`;

export function useJoinSocialSpace(onCompleted?: (data: JoinSocialSpace) => void) {
  return useMutation<JoinSocialSpace, JoinSocialSpaceVariables>(JOIN_SOCIAL_SPACE, {
    onCompleted,
    awaitRefetchQueries: true,
    refetchQueries: ['JoinedSocialSpaces'],
  });
}
