import { useQuery, useSubscription, QueryHookOptions, OperationVariables, QueryResult } from '@apollo/client';
import { DocumentNode } from 'graphql';

export function useLiveQuery<TData, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const definition = query.definitions[0];
  if (definition.kind !== 'OperationDefinition') {
    throw new Error('Query must have exactly one operation definition');
  }
  useSubscription<TData, TVariables>(
    {
      kind: 'Document',
      definitions: [
        {
          ...definition,
          kind: 'OperationDefinition',
          operation: 'subscription',
        },
      ],
    },
    {
      variables: options?.variables,
      shouldResubscribe: false,
    }
  );
  return useQuery<TData, TVariables>(query, options);
}
