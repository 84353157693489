import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip, IconButton, Paper, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import { FBox, style } from '@badger/design-system';

import { SocialProfileDialog } from 'dialogs';

import { useUserProfile } from './VideoProfileOverlay.gql';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: style.colors.backgroundDark,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    height: 32,
    maxHeight: 32,
    opacity: 0.8,
    paddingLeft: 8,
    paddingRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .MuiTypography-root': {
      color: '#ffffff',
    },
    '& .MuiSvgIcon-root': {
      color: '#ffffff',
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 4,
  },
  iconButton: {
    padding: 4,
  },
  icon: {
    width: 20,
    height: 20,
  },
});

interface Props {
  userId: string;
  localUser?: boolean;
}

export function VideoProfileOverlay(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data } = useUserProfile(props.userId);

  const [profileDialogOpen, setProfileDialogOpen] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const onTooltipOpen = () => {
    setShowTooltip(true);
  };

  const onTooltipClosed = () => {
    setShowTooltip(false);
  };

  const openProfileDialog = () => {
    setProfileDialogOpen(true);
  };

  const closeProfileDialog = () => {
    setProfileDialogOpen(false);
  };

  const profile = data?.userProfile?.profile;

  let tooltipTopline = '';

  if (profile?.jobTitle && profile?.company) {
    tooltipTopline = t('socialProfile.jobTitleAtCompany', { jobTitle: profile.jobTitle, company: profile.company });
  } else if (profile?.jobTitle) {
    tooltipTopline = profile.jobTitle;
  } else if (profile?.company) {
    tooltipTopline = profile.company;
  }

  const renderEdit = () => {
    return (
      <>
        <IconButton className={classes.iconButton} onClick={openProfileDialog}>
          <EditIcon className={classes.icon} />
        </IconButton>
        <SocialProfileDialog open={profileDialogOpen} onClose={closeProfileDialog} />
      </>
    );
  };

  const renderLeftSide = () => {
    return (
      <FBox alignItems="center">
        <Typography variant="subtitle2">{profile?.name}</Typography>
        {props.localUser && renderEdit()}
      </FBox>
    );
  };

  const renderRightSide = () => {
    if (!profile) {
      return null;
    }
    return (
      <FBox alignItems="center">
        {profile.twitter.length > 1 && (
          <Link
            className={classes.link}
            target="_blank"
            href={`http://www.twitter.com/${profile.twitter.substring(1)}`}
          >
            <TwitterIcon className={classes.icon} />
          </Link>
        )}
        {profile.linkedIn !== '' && (
          <Link className={classes.link} target="_blank" href={profile.linkedIn}>
            <LinkedInIcon className={classes.icon} />
          </Link>
        )}
        {(tooltipTopline || profile.socialBlurb) && (
          <Tooltip
            open={showTooltip}
            onOpen={onTooltipOpen}
            onClose={onTooltipClosed}
            placement="top-end"
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            title={
              <>
                {tooltipTopline && <Typography variant="caption">{tooltipTopline}</Typography>}
                {profile.socialBlurb && <Typography variant="body1">{profile.socialBlurb}</Typography>}
              </>
            }
          >
            <AssignmentIndIcon />
          </Tooltip>
        )}
      </FBox>
    );
  };

  return (
    <Paper className={classes.root}>
      {renderLeftSide()}
      {renderRightSide()}
    </Paper>
  );
}
