import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

import { NotifySocialSpace, NotifySocialSpaceVariables } from 'gql/types/NotifySocialSpace';

export const NOTIFY_SOCIAL_SPACE = gql`
  mutation NotifySocialSpace($input: NotifySocialSpaceInput!) {
    notifySocialSpace(input: $input) {
      error {
        type
      }
    }
  }
`;

export function useNotifySocialSpace() {
  return useMutation<NotifySocialSpace, NotifySocialSpaceVariables>(NOTIFY_SOCIAL_SPACE);
}
