import LogRocket, { captureException } from 'logrocket';

import { Role } from 'gql/types/globals';

import { amplitudeApiKey, baseUrl, googleAnalyticsId, postHogHost, postHogKey } from './config';
import { addCookieFunction, CookieSetting } from 'cookieSettings';
import Plausible from 'plausible-tracker';
import posthog from 'posthog-js';

enum EventId {
  BROADCAST_START = 'broadcast/start',
  BROADCAST_STOP = 'broadcast/stop',
  BROADCAST_JOIN = 'broadcast/join',
  BROADCAST_LEAVE = 'broadcast/leave',
  BROADCAST_LEAVE_VIA_NOTIFICATION = 'broadcast/leave-via-notification',
  BROADCAST_START_SCREENSHARE = 'broadcast/start-screenshare',
  BROADCAST_STOP_SCREENSHARE = 'broadcast/stop-screenshare',
  BROADCAST_KICK = 'broadcast/kick',
  BROADCAST_ASK_TO_LEAVE = 'broadcast/ask-to-leave',

  CHAT_ERROR_RETRY = 'chat-error/retry',

  COOKIE_BANNER_ACCEPT_ALL = 'cookie-banner/accept-all-cookies',
  COOKIE_BANNER_DECLINE_OPTIONAL = 'cookie-banner/decline-optional-cookies',

  LANDING_CREATE_SPACE = 'landing/create-space',
  LANDING_REQUEST_PUBLIC_SPACE = 'landing/request-public-space',
  LANDING_JOIN_PUBLIC_SPACE = 'landing/join-public-space',
  LANDING_CONTACT_US = 'landing/contact-us',
  LANDING_MORE_INFO = 'landing/more-info',

  MANAGE_ENABLE_SPACE_PASSWORD = 'manage/enable-password',
  MANAGE_DISABLE_SPACE_PASSWORD = 'manage/disable-password',
  MANAGE_COPY_URL = 'manage/copy-url',
  MANAGE_SHARE = 'manage/share',
  MANAGE_JOIN = 'manage/join',
  MANAGE_SET_SETTING = 'manage/set-setting',
  MANAGE_SET_TIMER = 'manage/set-timer',
  MANAGE_RESET_TIMER = 'manage/reset-timer',
  MANAGE_SET_INSTRUCTIONS = 'manage/set-instructions',
  MANAGE_CLEAR_INSTRUCTIONS = 'manage/clear-instructions',
  MANAGE_REDIRECT = 'manage/redirect',
  MANAGE_SEND_ANNOUNCEMENT = 'manage/send-announcement',
  MANAGE_START_BROADCAST = 'manage/start-broadcast',
  MANAGE_END_SESSION = 'manage/end-session',
  MANAGE_ENABLE_BREAK_UP_GROUPS = 'manage/enable-break-up-groups',
  MANAGE_DISABLE_BREAK_UP_GROUPS = 'manage/disable-break-up-groups',

  SHARE_OPEN = 'share/open',
  SHARE_CLOSE = 'share/close',
  SHARE_LINKEDIN = 'share/linkedin',
  SHARE_FACEBOOK = 'share/facebook',
  SHARE_FACEBOOK_MESSENGER = 'share/facebook-messenger',
  SHARE_REDDIT = 'share/reddit',
  SHARE_TWITTER = 'share/twitter',
  SHARE_TELEGRAM = 'share/telegram',
  SHARE_WHATSAPP = 'share/whatsapp',
  SHARE_EMAIL = 'share/email',
  SHARE_COPY_LINK = 'share/copy-link',
  SHARE_NATIVE = 'share/native',

  SOCIAL_SPACE_NAV_BAR_FEEDBACK = 'social-space-nav-bar/feedback',
  SOCIAL_SPACE_NAV_BAR_MANAGE = 'social-space-nav-bar/manage',
  SOCIAL_SPACE_NAV_BAR_LOGO = 'social-space-nav-bar/logo',
  SOCIAL_SPACE_NAV_BAR_SHARE = 'social-space-nav-bar/share',

  NAV_BAR_OPEN_LOGIN_REGISTER = 'nav-bar/open-login-register',
  NAV_BAR_CLOSE_LOGIN_REGISTER = 'nav-bar/close-login-register',
  NAV_BAR_OPEN_ACCOUNT_MENU = 'nav-bar/open-account-menu',
  NAV_BAR_CLOSE_ACCOUNT_MENU = 'nav-bar/close-account-menu',

  NAV_BAR_OPEN_MENU = 'nav-bar/open-menu',
  NAV_BAR_CLOSE_MENU = 'nav-bar/close-menu',
  NAV_BAR_OPEN_OVERVIEW_DRAWER = 'nav-bar/open-overview-drawer',
  NAV_BAR_CLOSE_OVERVIEW_DRAWER = 'nav-bar/close-overview-drawer',
  NAV_BAR_OPEN_USERS_DRAWER = 'nav-bar/open-users-drawer',
  NAV_BAR_CLOSE_USERS_DRAWER = 'nav-bar/close-users-drawer',
  NAV_BAR_OPEN_CHAT_DRAWER = 'nav-bar/open-chat-drawer',
  NAV_BAR_CLOSE_CHAT_DRAWER = 'nav-bar/close-chat-drawer',
  NAV_BAR_TITLE_TOOLTIP_MOUSEOVER = 'nav-bar/title-tooltip-mouseover',

  NAV_BAR_MENU_OPEN_LOGIN_REGISTER = 'nav-bar-menu/open-login-register',
  NAV_BAR_MENU_CLOSE_LOGIN_REGISTER = 'nav-bar-menu/close-login-register',
  NAV_BAR_MENU_OPEN_EDIT_PROFILE = 'nav-bar-menu/open-edit-profile',
  NAV_BAR_MENU_CLOSE_EDIT_PROFILE = 'nav-bar-menu/close-edit-profile',
  NAV_BAR_MENU_CREATE_NEW_SPACE = 'nav-bar-menu/create-new-space',
  NAV_BAR_MENU_FEEDBACK = 'nav-bar-menu/feedback',
  NAV_BAR_MENU_HELP = 'nav-bar-menu/help',
  NAV_BAR_MENU_OPEN_INSTRUCTIONS = 'nav-bar-menu/open-instructions',
  NAV_BAR_MENU_CLOSE_INSTRUCTIONS = 'nav-bar-menu/close-instructions',
  NAV_BAR_MENU_LOGOUT = 'nav-bar-menu/logout',

  NAV_BAR_MENU_OPEN_MANAGE_SUBMENU = 'nav-bar-menu/open-manage-submenu',
  NAV_BAR_MENU_CLOSE_MANAGE_SUBMENU = 'nav-bar-menu/close-manage-submenu',
  NAV_BAR_MENU_CLICK_MANAGE = 'nav-bar-menu/click-manage',
  NAV_BAR_MENU_OPEN_JOIN_SUBMENU = 'nav-bar-menu/open-join-submenu',
  NAV_BAR_MENU_CLOSE_JOIN_SUBMENU = 'nav-bar-menu/close-join-submenu',
  NAV_BAR_MENU_CLICK_JOIN = 'nav-bar-menu/click-join',

  ACCOUNT_MENU_LOGOUT = 'account-menu/logout',

  LOGIN_REGISTER_DIALOG_REGISTER = 'login-register-dialog/register',
  LOGIN_REGISTER_DIALOG_LOGIN = 'login-register-dialog/login',

  SOCIAL_SPACE_CHAT_OPEN = 'social-space/chat-open',
  SOCIAL_SPACE_CHAT_CLOSE = 'social-space/chat-close',
  SOCIAL_SPACE_TABLE_OVERVIEW_OPEN = 'social-space/table-overview-open',
  SOCIAL_SPACE_TABLE_OVERVIEW_CLOSE = 'social-space/table-overview-close',
  SOCIAL_SPACE_INSTRUCTIONS_OPEN = 'social-space/instructions-open',
  SOCIAL_SPACE_INSTRUCTIONS_CLOSE = 'social-space/instructions-close',
  SOCIAL_SPACE_USERS_OPEN = 'social-space/users-open',
  SOCIAL_SPACE_USERS_CLOSE = 'social-space/users-close',
  SOCIAL_SPACE_CREATE_YOUR_HUMBY = 'social-space/create-your-humby',
  SOCIAL_SPACE_GIVE_FEEDBACK = 'social-space/give-feedback',

  ENTER_SOCIEL_SPACE_PASSWORD = 'enter-social-space-password/enter-password',

  SOCIAL_SPACE_ONE_ON_ONE_SHOWN = 'social-space/one-on-one-invited/shown',
  SOCIAL_SPACE_ONE_ON_ONE_ACCEPTED = 'social-space/one-on-one-invited/accepted',
  SOCIAL_SPACE_ONE_ON_ONE_DECLINED = 'social-space/one-on-one-invited/declined',

  SOCIAL_SPACE_INVITE_TO_BROADCAST_SHOWN = 'social-space/broadcast-invited/shown',
  SOCIAL_SPACE_INVITE_TO_BROADCAST_ACCEPTED = 'social-space/broadcast-invited/accepted',
  SOCIAL_SPACE_INVITE_TO_BROADCAST_DECLINED = 'social-space/broadcast-invited/declined',

  SOCIAL_PROFILE_LIST_ITEM_EDIT_PROFILE = 'social-profile-list-item/edit-profile',
  SOCIAL_PROFILE_LIST_ITEM_BLURB_MOUSEOVER = 'social-profile-list-item/blurb-mouseover',
  SOCIAL_PROFILE_LIST_ITEM_TWITTER = 'social-profile-list-item/twitter',
  SOCIAL_PROFILE_LIST_ITEM_LINKED_IN = 'social-profile-list-item/linked-in',
  SOCIAL_PROFILE_LIST_ITEM_ONE_ON_ONE = 'social-profile-list-item/one-on-one',
  SOCIAL_PROFILE_LIST_ITEM_INVITE_TO_BROADCAST = 'social-profile-list-item/invite-to-broadcast',

  BROWSE_PANEL_CREATE_TABLE = 'browse-panel/create-table',
  BROWSE_PANEL_JOIN_RANDOM_TABLE = 'browse-panel/join-random-table',
  BROWSE_PANEL_START_BROADCAST = 'browse-panel/start-broadcast',
  BROWSE_PANEL_TO_LOBBY = 'browse-panel/to-lobby',

  TABLE_OVERVIEW_JOIN_TABLE = 'table-overview/join-table',
  TABLE_OVERVIEW_LOBBY = 'table-overview/lobby',
  GROUP_SHARE = 'group/share',

  LOBBY_CREATE_TABLE = 'lobby/create-table',
  LOBBY_JOIN_RANDOM_TABLE = 'lobby/join-random-table',

  SOCIAL_PROFILE_DIALOG_SUBMIT = 'social-profile-dialog/submit',

  TABLE_PROFILE_PROMPT_SHOWN = 'table/profile-prompt/shown',
  TABLE_PROFILE_PROMPT_OPEN = 'table/profile-prompt/open',

  FOOTER_EXIT_GROUP = 'footer/exit-group',
  FOOTER_EXIT_SPACE = 'footer/exit-space',

  AUDIO_MUTED = 'audio-muted',
  VIDEO_MUTED = 'video-muted',
}

const domain = /https?:\/\/([^:/\n]+)/.exec(baseUrl)?.[1];

class Analytics {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  googleAnalytics: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  amplitude: any;

  posthogInitialized = false;

  plausible = Plausible({
    trackLocalhost: true,
    domain,
  });

  async enable() {
    this.googleAnalytics = await import('react-ga');

    this.googleAnalytics.initialize(googleAnalyticsId, {
      debug: false,
      titleCase: false,
    });

    if (amplitudeApiKey) {
      //@ts-ignore
      this.amplitude = await import('amplitude-js');
      this.amplitude.getInstance().init(amplitudeApiKey);
    }
  }

  constructor() {
    try {
      posthog.init(postHogKey, {
        api_host: postHogHost,
        disable_cookie: true,
        persistence: 'memory',
        autocapture: false,
        capture_pageview: false,
        loaded: () => {
          this.posthogInitialized = true;
        },
      });
    } catch (error) {
      // noop
    }

    addCookieFunction(() => {
      this.enable();
    }, CookieSetting.AllCookies);
  }

  setUserProperties(userId: string, role: Role) {
    this.googleAnalytics?.set({
      userId,
    });

    this.amplitude?.getInstance().setUserId(userId);
    this.amplitude?.getInstance().setUserProperties({ role });
  }

  sendNPSScore(score: number | 'close') {
    this.amplitude?.logEvent(`NPS/20minNotification/${score}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendPosthog(eventId: EventId | string, eventProps?: any) {
    try {
      if (this.posthogInitialized) {
        posthog.capture(eventId, eventProps, {
          transport: 'XHR',
        });
      }
    } catch (error) {
      captureException(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendEventAll(eventId: EventId | string, eventProps?: any) {
    this.amplitude?.logEvent(eventId, eventProps);

    this.googleAnalytics?.event({ category: eventId });

    LogRocket.track(eventId);

    this.plausible.trackEvent(eventId, eventProps);

    this.sendPosthog(eventId, eventProps);
  }

  trackPageView(url: string) {
    this.googleAnalytics?.pageview(url);
    this.plausible.trackPageview();
    this.sendPosthog('$pageview');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track(event: string, eventProps?: any) {
    this.sendEventAll(event, eventProps);
  }

  trackBroadcastStart() {
    this.sendEventAll(EventId.BROADCAST_START);
  }

  trackBroadcastStop() {
    this.sendEventAll(EventId.BROADCAST_STOP);
  }

  trackBroadcastJoin() {
    this.sendEventAll(EventId.BROADCAST_JOIN);
  }

  trackBroadcastLeave() {
    this.sendEventAll(EventId.BROADCAST_LEAVE);
  }

  trackBroadcastLeaveViaNotification() {
    this.sendEventAll(EventId.BROADCAST_LEAVE_VIA_NOTIFICATION);
  }

  trackBroadcastStartScreenshare() {
    this.sendEventAll(EventId.BROADCAST_START_SCREENSHARE);
  }

  trackBroadcastStopScreenshare() {
    this.sendEventAll(EventId.BROADCAST_STOP_SCREENSHARE);
  }

  trackBroadcastKick() {
    this.sendEventAll(EventId.BROADCAST_KICK);
  }

  trackBroadcastAskToLeave() {
    this.sendEventAll(EventId.BROADCAST_ASK_TO_LEAVE);
  }

  trackChatErrorRetry() {
    this.sendEventAll(EventId.CHAT_ERROR_RETRY);
  }

  trackCookieBannerAcceptAll() {
    this.sendEventAll(EventId.COOKIE_BANNER_ACCEPT_ALL);
  }

  trackCookieBannerDeclineOptional() {
    this.sendEventAll(EventId.COOKIE_BANNER_DECLINE_OPTIONAL);
  }

  trackDeviceSelectionOpen() {
    this.sendEventAll('device-selection/open');
  }

  trackDeviceSelectionClose() {
    this.sendEventAll('device-selection/close');
  }

  trackDeviceSelectionChangeAudio() {
    this.sendEventAll('device-selection/select-audio');
  }

  trackDeviceSelectionChangeVideo() {
    this.sendEventAll('device-selection/select-video');
  }

  trackCreateSocialSpace() {
    this.sendEventAll(EventId.LANDING_CREATE_SPACE);
  }

  trackLandingRequestPublicSpace() {
    this.sendEventAll(EventId.LANDING_REQUEST_PUBLIC_SPACE);
  }

  trackLandingJoinPublicSpace() {
    this.sendEventAll(EventId.LANDING_JOIN_PUBLIC_SPACE);
  }

  trackContactUs() {
    this.sendEventAll(EventId.LANDING_CONTACT_US);
  }

  trackMoreInfo() {
    this.sendEventAll(EventId.LANDING_MORE_INFO);
  }

  trackEnableSpacePassword() {
    this.sendEventAll(EventId.MANAGE_ENABLE_SPACE_PASSWORD);
  }

  trackDisableSpacePassword() {
    this.sendEventAll(EventId.MANAGE_DISABLE_SPACE_PASSWORD);
  }

  trackCopySocialSpaceUrl() {
    this.sendEventAll(EventId.MANAGE_COPY_URL);
  }

  trackManageShare() {
    this.sendEventAll(EventId.MANAGE_SHARE);
  }

  trackManageSpaceJoin() {
    this.sendEventAll(EventId.MANAGE_JOIN);
  }

  trackManageSetSettingOption(softLimit: number, hardLimit: number, oneOnOneEnabled: boolean) {
    this.sendEventAll(EventId.MANAGE_SET_SETTING, {
      softLimit,
      hardLimit,
      oneOnOneEnabled,
    });
  }

  trackManageSetTimer(seconds: number) {
    this.sendEventAll(EventId.MANAGE_SET_TIMER, {
      seconds,
    });
  }

  trackManageResetTimer() {
    this.sendEventAll(EventId.MANAGE_RESET_TIMER);
  }

  trackManageSetInstructions(textLength: number) {
    this.sendEventAll(EventId.MANAGE_SET_INSTRUCTIONS, {
      textLength,
    });
  }

  trackManageClearInstructions() {
    this.sendEventAll(EventId.MANAGE_CLEAR_INSTRUCTIONS);
  }

  trackManageRedirect(url: string) {
    this.sendEventAll(EventId.MANAGE_REDIRECT, {
      url,
    });
  }

  trackManageSendAnnouncement(textLength: number) {
    this.sendEventAll(EventId.MANAGE_SEND_ANNOUNCEMENT, {
      textLength,
    });
  }

  trackManageStartBroadcast() {
    this.sendEventAll(EventId.MANAGE_START_BROADCAST);
  }

  trackManageEndSession(textLength: number) {
    this.sendEventAll(EventId.MANAGE_END_SESSION, {
      textLength,
    });
  }

  trackManageDisableBreakUpGroups() {
    this.sendEventAll(EventId.MANAGE_DISABLE_BREAK_UP_GROUPS);
  }

  trackManageEnableBreakUpGroups(limit: string) {
    this.sendEventAll(EventId.MANAGE_ENABLE_BREAK_UP_GROUPS, {
      limit,
    });
  }

  trackShareOpen() {
    this.sendEventAll(EventId.SHARE_OPEN);
  }

  trackShareClose() {
    this.sendEventAll(EventId.SHARE_CLOSE);
  }

  trackShareLinkedIn() {
    this.sendEventAll(EventId.SHARE_LINKEDIN);
  }

  trackShareFacebook() {
    this.sendEventAll(EventId.SHARE_FACEBOOK);
  }

  trackShareFacebookMessenger() {
    this.sendEventAll(EventId.SHARE_FACEBOOK_MESSENGER);
  }

  trackShareReddit() {
    this.sendEventAll(EventId.SHARE_REDDIT);
  }

  trackShareTwitter() {
    this.sendEventAll(EventId.SHARE_TWITTER);
  }

  trackShareTelegram() {
    this.sendEventAll(EventId.SHARE_TELEGRAM);
  }

  trackShareWhatsApp() {
    this.sendEventAll(EventId.SHARE_WHATSAPP);
  }

  trackShareEmail() {
    this.sendEventAll(EventId.SHARE_EMAIL);
  }

  trackShareCopyLink() {
    this.sendEventAll(EventId.SHARE_COPY_LINK);
  }

  trackShareNative() {
    this.sendEventAll(EventId.SHARE_NATIVE);
  }

  trackSocialSpaceNavBarFeedback() {
    this.sendEventAll(EventId.SOCIAL_SPACE_NAV_BAR_FEEDBACK);
  }

  trackSocialSpaceNavBarManage() {
    this.sendEventAll(EventId.SOCIAL_SPACE_NAV_BAR_MANAGE);
  }

  trackSocialSpaceNavBarLogo() {
    this.sendEventAll(EventId.SOCIAL_SPACE_NAV_BAR_LOGO);
  }

  trackSocialSpaceNavBarShare() {
    this.sendEventAll(EventId.SOCIAL_SPACE_NAV_BAR_SHARE);
  }

  trackNavBarOpenLoginRegister() {
    this.sendEventAll(EventId.NAV_BAR_OPEN_LOGIN_REGISTER);
  }

  trackNavBarCloseLoginRegister() {
    this.sendEventAll(EventId.NAV_BAR_CLOSE_LOGIN_REGISTER);
  }

  trackNavBarOpenAccountMenu() {
    this.sendEventAll(EventId.NAV_BAR_OPEN_ACCOUNT_MENU);
  }

  trackNavBarCloseAccountMenu() {
    this.sendEventAll(EventId.NAV_BAR_CLOSE_ACCOUNT_MENU);
  }

  trackNavBarOpenOverviewDrawer() {
    this.sendEventAll(EventId.NAV_BAR_OPEN_OVERVIEW_DRAWER);
  }

  trackNavBarCloseOverviewDrawer() {
    this.sendEventAll(EventId.NAV_BAR_CLOSE_OVERVIEW_DRAWER);
  }

  trackNavBarOpenUsersDrawer() {
    this.sendEventAll(EventId.NAV_BAR_OPEN_USERS_DRAWER);
  }

  trackNavBarCloseUsersDrawer() {
    this.sendEventAll(EventId.NAV_BAR_CLOSE_USERS_DRAWER);
  }

  trackNavBarOpenChatDrawer() {
    this.sendEventAll(EventId.NAV_BAR_OPEN_CHAT_DRAWER);
  }

  trackNavBarCloseChatDrawer() {
    this.sendEventAll(EventId.NAV_BAR_CLOSE_CHAT_DRAWER);
  }

  trackNavBarTitleTooltipMouseover() {
    this.sendEventAll(EventId.NAV_BAR_TITLE_TOOLTIP_MOUSEOVER);
  }

  trackNavBarMenuOpenLoginRegister() {
    this.sendEventAll(EventId.NAV_BAR_MENU_OPEN_LOGIN_REGISTER);
  }

  trackNavBarMenuCloseLoginRegister() {
    this.sendEventAll(EventId.NAV_BAR_MENU_CLOSE_LOGIN_REGISTER);
  }

  trackNavBarMenuOpenEditProfile() {
    this.sendEventAll(EventId.NAV_BAR_MENU_OPEN_EDIT_PROFILE);
  }

  trackNavBarMenuCloseEditProfile() {
    this.sendEventAll(EventId.NAV_BAR_MENU_CLOSE_EDIT_PROFILE);
  }

  trackNavBarMenuCreateNewSpace() {
    this.sendEventAll(EventId.NAV_BAR_MENU_CREATE_NEW_SPACE);
  }

  trackNavBarMenuFeedback() {
    this.sendEventAll(EventId.NAV_BAR_MENU_FEEDBACK);
  }

  trackNavBarMenuHelp() {
    this.sendEventAll(EventId.NAV_BAR_MENU_HELP);
  }

  trackNavBarMenuOpenInstructions() {
    this.sendEventAll(EventId.NAV_BAR_MENU_OPEN_INSTRUCTIONS);
  }

  trackNavBarMenuCloseInstructions() {
    this.sendEventAll(EventId.NAV_BAR_MENU_CLOSE_INSTRUCTIONS);
  }

  trackNavBarMenuLogout() {
    this.sendEventAll(EventId.NAV_BAR_MENU_LOGOUT);
  }

  trackNavBarMenuOpenManageSubMenu() {
    this.sendEventAll(EventId.NAV_BAR_MENU_OPEN_MANAGE_SUBMENU);
  }

  trackNavBarMenuCloseManageSubMenu() {
    this.sendEventAll(EventId.NAV_BAR_MENU_CLOSE_MANAGE_SUBMENU);
  }

  trackNavBarMenuClickManage() {
    this.sendEventAll(EventId.NAV_BAR_MENU_CLICK_MANAGE);
  }

  trackNavBarMenuOpenJoinSubMenu() {
    this.sendEventAll(EventId.NAV_BAR_MENU_OPEN_JOIN_SUBMENU);
  }

  trackNavBarMenuCloseJoinSubMenu() {
    this.sendEventAll(EventId.NAV_BAR_MENU_CLOSE_JOIN_SUBMENU);
  }

  trackNavBarMenuClickJoin() {
    this.sendEventAll(EventId.NAV_BAR_MENU_CLICK_JOIN);
  }

  trackAccountMenuLogout() {
    this.sendEventAll(EventId.ACCOUNT_MENU_LOGOUT);
  }

  trackLoginRegisterDialogRegister() {
    this.sendEventAll(EventId.LOGIN_REGISTER_DIALOG_REGISTER);
  }

  trackLoginRegisterDialogLogin() {
    this.sendEventAll(EventId.LOGIN_REGISTER_DIALOG_LOGIN);
  }

  trackSocialSpaceChatOpen() {
    this.sendEventAll(EventId.SOCIAL_SPACE_CHAT_OPEN);
  }

  trackSocialSpaceChatClose() {
    this.sendEventAll(EventId.SOCIAL_SPACE_CHAT_CLOSE);
  }

  trackBrowsePanelCreateTable() {
    this.sendEventAll(EventId.BROWSE_PANEL_CREATE_TABLE);
  }

  trackBrowsePanelJoinRandomTable() {
    this.sendEventAll(EventId.BROWSE_PANEL_JOIN_RANDOM_TABLE);
  }

  trackBrowsePanelStartBroadcast() {
    this.sendEventAll(EventId.BROWSE_PANEL_START_BROADCAST);
  }

  trackBrowsePanelToLobby() {
    this.sendEventAll(EventId.BROWSE_PANEL_TO_LOBBY);
  }

  trackSocialSpaceTableOverviewOpen() {
    this.sendEventAll(EventId.SOCIAL_SPACE_TABLE_OVERVIEW_OPEN);
  }

  trackSocialSpaceTableOverviewClose() {
    this.sendEventAll(EventId.SOCIAL_SPACE_TABLE_OVERVIEW_CLOSE);
  }

  trackSocialSpaceInstructionsOpen() {
    this.sendEventAll(EventId.SOCIAL_SPACE_INSTRUCTIONS_OPEN);
  }

  trackSocialSpaceInstructionsClose() {
    this.sendEventAll(EventId.SOCIAL_SPACE_INSTRUCTIONS_CLOSE);
  }

  trackSocialSpaceUsersOpen() {
    this.sendEventAll(EventId.SOCIAL_SPACE_USERS_OPEN);
  }

  trackSocialSpaceUsersClose() {
    this.sendEventAll(EventId.SOCIAL_SPACE_USERS_CLOSE);
  }

  trackSocialSpaceCreateYourHumby() {
    this.sendEventAll(EventId.SOCIAL_SPACE_CREATE_YOUR_HUMBY);
  }

  trackSocialSpaceGiveFeedback() {
    this.sendEventAll(EventId.SOCIAL_SPACE_GIVE_FEEDBACK);
  }

  trackEnterSocialSpacePassword() {
    this.sendEventAll(EventId.ENTER_SOCIEL_SPACE_PASSWORD);
  }

  trackSocialSpaceOneOnOneShown() {
    this.sendEventAll(EventId.SOCIAL_SPACE_ONE_ON_ONE_SHOWN);
  }

  trackSocialSpaceOneOnOneAccepted() {
    this.sendEventAll(EventId.SOCIAL_SPACE_ONE_ON_ONE_SHOWN);
  }

  trackSocialSpaceOneOnOneDeclined() {
    this.sendEventAll(EventId.SOCIAL_SPACE_ONE_ON_ONE_DECLINED);
  }

  trackSocialSpaceInviteToBroadcastShown() {
    this.sendEventAll(EventId.SOCIAL_SPACE_INVITE_TO_BROADCAST_SHOWN);
  }

  trackSocialSpaceInviteToBroadcastAccepted() {
    this.sendEventAll(EventId.SOCIAL_SPACE_INVITE_TO_BROADCAST_ACCEPTED);
  }

  trackSocialSpaceInviteToBroadcastDeclined() {
    this.sendEventAll(EventId.SOCIAL_SPACE_INVITE_TO_BROADCAST_DECLINED);
  }

  trackSocialProfileListItemEditProfile() {
    this.sendEventAll(EventId.SOCIAL_PROFILE_LIST_ITEM_EDIT_PROFILE);
  }

  trackSocialProfileListItemBlurbMouseover() {
    this.sendEventAll(EventId.SOCIAL_PROFILE_LIST_ITEM_BLURB_MOUSEOVER);
  }

  trackSocialProfileListItemTwitter() {
    this.sendEventAll(EventId.SOCIAL_PROFILE_LIST_ITEM_TWITTER);
  }

  trackSocialProfileListItemLinkedIn() {
    this.sendEventAll(EventId.SOCIAL_PROFILE_LIST_ITEM_LINKED_IN);
  }

  trackSocialProfileListItemOneOnOne() {
    this.sendEventAll(EventId.SOCIAL_PROFILE_LIST_ITEM_ONE_ON_ONE);
  }

  trackSocialProfileListItemInviteToBroadcast() {
    this.sendEventAll(EventId.SOCIAL_PROFILE_LIST_ITEM_INVITE_TO_BROADCAST);
  }

  trackTableOverviewJoinTable() {
    this.sendEventAll(EventId.TABLE_OVERVIEW_JOIN_TABLE);
  }

  trackTableOverviewLobby() {
    this.sendEventAll(EventId.TABLE_OVERVIEW_LOBBY);
  }

  trackGroupShare() {
    this.sendEventAll(EventId.GROUP_SHARE);
  }

  trackLobbyCreateTable() {
    this.sendEventAll(EventId.LOBBY_CREATE_TABLE);
  }

  trackLobbyJoinRandomTable() {
    this.sendEventAll(EventId.LOBBY_JOIN_RANDOM_TABLE);
  }

  trackSocialProfileDialogSubmit() {
    this.sendEventAll(EventId.SOCIAL_PROFILE_DIALOG_SUBMIT);
  }

  trackTableProfilePromptShown() {
    this.sendEventAll(EventId.TABLE_PROFILE_PROMPT_SHOWN);
  }

  trackTableProfilePromptOpen() {
    this.sendEventAll(EventId.TABLE_PROFILE_PROMPT_OPEN);
  }

  trackFooterExitGroup() {
    this.sendEventAll(EventId.FOOTER_EXIT_GROUP);
  }

  trackFooterExitSpace() {
    this.sendEventAll(EventId.FOOTER_EXIT_SPACE);
  }

  trackAudioMuted(muted: boolean) {
    this.sendEventAll(EventId.AUDIO_MUTED, { muted });
  }

  trackVideoMuted(muted: boolean) {
    this.sendEventAll(EventId.VIDEO_MUTED, { muted });
  }

  trackFiltersOpen(open: boolean) {
    this.sendEventAll('filters/open', { open });
  }

  trackFiltersChange(filter: string | null) {
    this.sendEventAll('filters/change', { filter });
  }

  trackPublicSpacesAddFilter(tag: string) {
    this.sendEventAll('publicSpaces/addFilter', { tag });
  }
  trackPublicSpacesClearFilter(tag: string) {
    this.sendEventAll('publicSpaces/clearFilter', { tag });
  }
}

const analytics = new Analytics();

export default analytics;
