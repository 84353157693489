import * as React from 'react';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';

import { TextField, Button, Box, Switch, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';

import { FBox, FBoxV, LoadingComponent } from '@badger/design-system';

import { SocialSpaceContext } from 'components/socialSpace';
import Analytics from 'analytics';

import { SpaceOptionFrame } from './SpaceOptionFrame';

import { useGetSocialSpaceFormat, useSetSocialSpaceFormat } from './format.gql';

const useStyles = makeStyles({
  root: {
    alignItems: 'stretch',
    padding: '8px',
    '& .MuiFormControlLabel-root': {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: 0,
      marginRight: 0,
      '& .MuiTextField-root': {
        width: '100px',
      },
      '& .MuiFormHelperText-root.Mui-focused': {
        display: 'none',
      },
    },
  },
});

export const SettingOption = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { spaceSlug, spaceId } = React.useContext(SocialSpaceContext);

  const [format, setFormat] = React.useState<{
    seatsSoftLimit: string;
    seatsHardLimit: string;
    enableOneOnOne: boolean;
  }>();
  const [seatsSoftLimitError, setSeatsSoftLimitError] = React.useState<string>();
  const [seatsHardLimitError, setSeatsHardLimitError] = React.useState<string>();

  const { data } = useGetSocialSpaceFormat({ slug: spaceSlug });
  const [submitFormat] = useSetSocialSpaceFormat();

  const resetFormat = React.useCallback(() => {
    const format = data?.socialSpace?.format;
    if (format) {
      setFormat({
        seatsSoftLimit: `${format.seatsSoftLimit}`,
        seatsHardLimit: `${format.seatsHardLimit}`,
        enableOneOnOne: format.enableOneOnOne,
      });
    }
  }, [data]);

  React.useEffect(() => {
    if (!format) {
      resetFormat();
    }
  }, [format, resetFormat]);

  if (!format || !data || !data.socialSpace) {
    return <LoadingComponent />;
  }

  const onSeatsSoftLimitChanged = (input: string) => {
    setFormat({
      ...format,
      seatsSoftLimit: input,
    });

    const parsedSeats = parseInt(input);
    if (!isNaN(parsedSeats)) {
      setSeatsSoftLimitError(undefined);
    } else {
      setSeatsSoftLimitError(t('manageSpace.settings.invalidSeatsSoftLimit'));
    }
  };

  const onSeatsHardLimitChanged = (input: string) => {
    setFormat({
      ...format,
      seatsHardLimit: input,
    });

    const parsedSeats = parseInt(input);
    if (!isNaN(parsedSeats)) {
      setSeatsHardLimitError(undefined);
    } else {
      setSeatsHardLimitError(t('manageSpace.settings.invalidSeatsHardLimit'));
    }
  };

  const onEnableOneOnOneChanged = (enabled: boolean) => {
    setFormat({
      ...format,
      enableOneOnOne: enabled,
    });
  };

  const onSubmit = () => {
    const seatsSoftLimit = parseInt(format.seatsSoftLimit);
    const seatsHardLimit = parseInt(format.seatsHardLimit);

    Analytics.trackManageSetSettingOption(seatsSoftLimit, seatsHardLimit, format.enableOneOnOne);

    if (isNaN(seatsSoftLimit) || isNaN(seatsHardLimit)) {
      return;
    }

    submitFormat({
      variables: {
        input: {
          spaceId,
          ..._.omit(data.socialSpace?.format, '__typename', 'id'),
          seatsSoftLimit,
          seatsHardLimit,
          enableOneOnOne: format.enableOneOnOne,
        },
      },
    });
  };

  const onReset = () => {
    resetFormat();
  };

  const controlsDisabled =
    !!seatsSoftLimitError ||
    !!seatsHardLimitError ||
    (`${data.socialSpace.format.seatsSoftLimit}` === format.seatsSoftLimit &&
      `${data.socialSpace.format.seatsHardLimit}` === format.seatsHardLimit &&
      data.socialSpace.format.enableOneOnOne === format.enableOneOnOne);

  return (
    <SpaceOptionFrame
      title={t('manageSpace.settings.title')}
      description={t('manageSpace.settings.description')}
      IconComponent={SettingsIcon}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit();
        }}
      >
        <FBoxV className={classes.root}>
          <FormControlLabel
            control={
              <TextField
                error={!!seatsSoftLimitError}
                helperText={seatsSoftLimitError}
                onChange={(event) => onSeatsSoftLimitChanged(event.target.value)}
                variant="outlined"
                value={format.seatsSoftLimit.toString()}
              />
            }
            label={t('manageSpace.settings.seatsSoftLimit')}
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <TextField
                error={!!seatsHardLimitError}
                helperText={seatsHardLimitError}
                onChange={(event) => onSeatsHardLimitChanged(event.target.value)}
                variant="outlined"
                value={format.seatsHardLimit.toString()}
              />
            }
            label={t('manageSpace.settings.seatsHardLimit')}
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Switch
                checked={format?.enableOneOnOne}
                onChange={(event) => onEnableOneOnOneChanged(event.target.checked)}
                color="primary"
              />
            }
            label={t('manageSpace.settings.enableOneOnOne')}
            labelPlacement="start"
          />
        </FBoxV>
        <FBox justifyContent="flex-end" mt={2}>
          <Button variant="contained" color="primary" type="submit" disabled={controlsDisabled}>
            {t('common.save')}
          </Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" type="reset" disabled={controlsDisabled} onClick={onReset}>
              {t('common.reset')}
            </Button>
          </Box>
        </FBox>
      </form>
    </SpaceOptionFrame>
  );
};
