import _ from 'lodash';
import React from 'react';
import { createMuiTheme, ThemeProvider, makeStyles, responsiveFontSizes } from '@material-ui/core/styles';

import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';

import * as Sentry from '@sentry/browser';
import { Integrations as ApmIntegrations } from '@sentry/apm';

import { style } from '@badger/design-system';

import { client } from './apolloClient';

import AppRouter from './routes';
import { sentryDsn, Deployment, isProduction, logRocketAppId, datadogClientToken } from './config';
import LogRocket from 'logrocket';
import { datadogLogs } from '@datadog/browser-logs';

Sentry.init({
  dsn: sentryDsn,
  integrations: [new ApmIntegrations.Tracing()],
  tracesSampleRate: isProduction() ? 0.1 : 1.0,
  environment: Deployment.toLowerCase(),
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
});
if (logRocketAppId) {
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

datadogLogs.init({
  clientToken: datadogClientToken,
  site: 'datadoghq.eu',
  forwardErrorsToLogs: true,
  sampleRate: 100,
  env: Deployment.toLowerCase(),
});

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xl: false; // xl breakpoint is unused, remove
  }
}

const theme = responsiveFontSizes(
  createMuiTheme({
    ..._.merge(style.muiTheme, {
      overrides: {
        MuiTypography: {
          root: {
            whiteSpace: 'pre-line' as 'pre-line',
          },
        },
      },
    }),
  })
);

const useStyles = makeStyles({
  snackbarProvider: {
    maxWidth: '95vw',
    '& .MuiSnackbarContent-root': {
      backgroundColor: style.colors.backgroundDark,
    },
  },
});

const App: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ApolloProvider client={client}>
          <SnackbarProvider
            className={classes.snackbarProvider}
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <AppRouter />
          </SnackbarProvider>
        </ApolloProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
