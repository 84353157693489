import _ from 'lodash';
import { duration } from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FBoxV } from '@badger/design-system';

import Analytics from 'analytics';
import { SocialSpaceContext } from 'components/socialSpace';
import { ProfilePromptSnackBar } from 'components/socialSpace/snackbar';
import { TwilioVideoFeed, VideoFeedError } from 'components/socialSpace/table/TwilioVideoFeed';

import { SocialProfileDialog } from 'dialogs';

import { useTableSubscriptions } from 'hooks/tableSubscriptions';

import { JoinSocialTableErrorType } from 'gql/types/globals';
import { useGetUserWithProfile, useJoinSocialTable } from 'social.gql';
import { EnterSpace } from './EnterSpace';
import { BroadcastFeed } from 'components/socialSpace/BroadcastFeed';
import { TableFooter } from 'components/socialSpace/table/TableFooter';

const PROFILE_PROMPT_TIMEOUT = duration(3, 'minutes');

interface RouteMatchProps {
  tableId: string;
}

interface RouteStateProps {
  promptProfileRegister: boolean;
  credentials: {
    roomName: string;
    roomPassword: string;
  };
}

interface FeedCredentials {
  roomName: string;
  roomPassword: string;
  subject?: string;
}

interface Props {
  tableId: string;
  feedCredentials: FeedCredentials;
}

const useStyles = makeStyles({
  table: {
    alignItems: 'stretch',
    alignContent: 'stretch',
    flexGrow: 1,
    height: '100%',
  },
  broadcastArea: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    minHeight: '50vh',
    maxHeight: '65vh',
    flexShrink: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '1vw',
    paddingBottom: '0.5vw',
    transition: 'height 0.5s',
  },
  broadcastAreaHidden: {
    height: 0,
    minHeight: 0,
  },
  videoArea: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    height: '100%',
    minHeight: '20vh',
    padding: '1vw',
    paddingBottom: '0.5vw',
    '& video': {
      filter: 'brightness(1)',
      transition: 'filter 2s',
    },
  },
  videoAreaDarker: {
    '& video': {
      filter: 'brightness(0.5)',
    },
  },
  getYourHumby: {
    color: 'white',
    marginBottom: '0.5vw',
    marginLeft: '20px',
    marginRight: '20px',
    textDecoration: 'underline',
  },
});

export function TableInner({ tableId, feedCredentials }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation<RouteStateProps>();
  const { spaceId, spaceSlug, spaceTablesUsers } = React.useContext(SocialSpaceContext);
  const table = React.useMemo(() => spaceTablesUsers?.tables.find((table) => table.id === tableId), [
    spaceTablesUsers,
    tableId,
  ]);

  const promptProfileRegister = location.state?.promptProfileRegister;

  const [socialProfilePromptOpen, setSocialInfoPromptOpen] = React.useState(false);

  const { data: userProfileData } = useGetUserWithProfile();

  useTableSubscriptions({
    spaceId,
    tableId,
    spaceSlug,
  });

  const closeSocialInfoPrompt = () => {
    setSocialInfoPromptOpen(false);
  };

  const openSocialInfoPrompt = React.useCallback(() => {
    Analytics.trackTableProfilePromptOpen();
    setSocialInfoPromptOpen(true);
  }, [setSocialInfoPromptOpen]);

  const showProfilePrompt = React.useCallback(() => {
    setTimeout(() => {
      Analytics.trackTableProfilePromptShown();
      enqueueSnackbar(<ProfilePromptSnackBar snackKey="PROFILE_PROMPT" action={openSocialInfoPrompt} />, {
        key: 'PROFILE_PROMPT',
        autoHideDuration: 15000,
        preventDuplicate: true,
      });
    }, PROFILE_PROMPT_TIMEOUT.asMilliseconds());
  }, [openSocialInfoPrompt, enqueueSnackbar]);

  React.useEffect(() => {
    if (userProfileData?.user?.profile.socialBlurb === '' && promptProfileRegister) {
      location.state = {
        ...location.state,
        promptProfileRegister: false,
      };

      showProfilePrompt();
    }
  }, [userProfileData, location.state, promptProfileRegister, showProfilePrompt]);

  const onVideoFeedError = (error: VideoFeedError) => {
    let message = '';
    switch (error) {
      case 'connecting-failed':
        message = t('table.connectingFailed');
        break;
      case 'disconnected':
        message = t('table.disconnected');
        break;
    }
    history.push(`/in/${spaceSlug}/lobby`, { message });
  };

  return (
    <FBoxV className={classes.table}>
      <Box className={`${classes.broadcastArea} ${spaceTablesUsers?.broadcasting ? '' : classes.broadcastAreaHidden}`}>
        {spaceTablesUsers?.broadcasting && <BroadcastFeed />}
      </Box>
      <Box className={`${classes.videoArea} ${spaceTablesUsers?.broadcasting ? classes.videoAreaDarker : ''}`}>
        {feedCredentials && (
          <TwilioVideoFeed
            key={tableId}
            tableId={tableId}
            credentials={feedCredentials}
            onError={onVideoFeedError}
            showInvite={table?.joinable ?? false}
          />
        )}
        {userProfileData?.user?.profile && (
          <SocialProfileDialog open={socialProfilePromptOpen} onClose={closeSocialInfoPrompt} />
        )}
      </Box>
      <TableFooter />
    </FBoxV>
  );
}

function TableMiddle({ tableId }: { tableId: string }) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<RouteStateProps>();
  const { spaceId, spaceSlug } = React.useContext(SocialSpaceContext);

  const [feedCredentials, setFeedCredentials] = React.useState<FeedCredentials | undefined>(
    location.state?.credentials
  );

  const [joinSocialTable, { loading: joinSocialTableLoading }] = useJoinSocialTable((data) => {
    if (data?.joinSocialTable?.table?.id) {
      const credentials = data?.joinSocialTable?.videoChatCredentials ?? undefined;

      if (!_.isEqual(credentials, feedCredentials)) {
        setFeedCredentials(credentials);
      }
    }

    if (data?.joinSocialTable?.error?.type === JoinSocialTableErrorType.TABLE_DOES_NOT_EXIST) {
      history.push(`/in/${spaceSlug}/lobby`, { message: t('table.tableEmpty') });
    }

    if (data?.joinSocialTable?.error?.type === JoinSocialTableErrorType.TABLE_BLOWING_UP) {
      // TODO joinNextSocialTable directly
      history.push(`/in/${spaceSlug}`);
    }
  });

  const joinTable = React.useCallback(
    _.throttle(
      () => {
        joinSocialTable({
          variables: {
            input: {
              spaceId,
              tableId,
            },
          },
        });
      },
      1000,
      {
        leading: true,
        trailing: true,
      }
    ),
    [joinSocialTable, spaceId, tableId]
  );

  React.useEffect(() => {
    if (!feedCredentials && !joinSocialTableLoading) {
      joinTable();
    }
  }, [feedCredentials, joinSocialTableLoading, joinTable]);

  return feedCredentials ? (
    // Changing the key to force a complete remount.
    <TableInner key={`${tableId}`} tableId={tableId} feedCredentials={feedCredentials} />
  ) : (
    <CircularProgress />
  );
}
export function Table() {
  const { data: userProfileData, refetch } = useGetUserWithProfile();
  const { tableId } = useParams<RouteMatchProps>();

  const onJoin = () => {
    refetch();
  };

  return userProfileData?.user?.profile.name ? (
    <TableMiddle key={`${tableId}`} tableId={tableId} />
  ) : (
    <EnterSpace onJoin={onJoin} />
  );
}
