import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { Typography, Button } from '@material-ui/core';
import { FBox, FBoxV } from '@badger/design-system';
import { detectOS } from '../util';
import { isWebRTCSupported, ignoreNotSupported } from 'util/isSupported';
import { useHistory } from 'react-router-dom';

export const UnsupportedWarning = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const message = () => {
    switch (detectOS()) {
      case 'Linux':
        return t('unsupportedWarning.messageLinux');
      case 'Windows':
        return t('unsupportedWarning.messageWindows');
      case 'Mac':
        return t('unsupportedWarning.messageMac');
      case 'iOS':
        return t('unsupportedWarning.messageIOs');
      case 'Android':
        return t('unsupportedWarning.messageAndroid');
      default:
        return t('unsupportedWarning.messageUnknown');
    }
  };

  const onIgnoreWarning = () => {
    ignoreNotSupported();
    history.goBack();
  };

  return (
    <FBoxV p={8} width="100%" justifyContent="center" alignItems="center" height="60vh">
      <Typography align="center" variant="h4">
        {isWebRTCSupported() ? (
          <FBoxV>
            {t('unsupportedWarning.messagePotentiallyUnsupported')}
            <Button onClick={onIgnoreWarning} color="primary">
              {t('unsupportedWarning.ignore')}
            </Button>
          </FBoxV>
        ) : (
          t('unsupportedWarning.messageUnsupported')
        )}
      </Typography>
      <FBox mt={2}>
        <Typography align="center" variant="h4">
          {message()}
        </Typography>
      </FBox>
    </FBoxV>
  );
};
