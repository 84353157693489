/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AcceptInviteToBroadcastErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  INVALID_INVITE = "INVALID_INVITE",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum AcceptInviteToOneOnOneErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  INVALID_INVITE = "INVALID_INVITE",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum BroadcasterType {
  AUDIENCE = "AUDIENCE",
  SCREENSHARE = "SCREENSHARE",
  VIDEO = "VIDEO",
}

export enum CreateSocialSpaceErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  SPACE_ALREADY_EXISTS = "SPACE_ALREADY_EXISTS",
}

export enum CreateSocialTableErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum DeclineInviteToBroadcastErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  INVALID_INVITE = "INVALID_INVITE",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum DeclineInviteToBroadcastReason {
  DISMISSED = "DISMISSED",
  TIMEOUT = "TIMEOUT",
}

export enum DeclineInviteToOneOnOneErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  INVALID_INVITE = "INVALID_INVITE",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum DeclineInviteToOneOnOneReason {
  DISMISSED = "DISMISSED",
  TIMEOUT = "TIMEOUT",
}

export enum InviteToBroadcastErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  NO_BROADCAST_RUNNING = "NO_BROADCAST_RUNNING",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
  USER_DOES_NOT_EXIST = "USER_DOES_NOT_EXIST",
}

export enum InviteToOneOnOneErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
  USER_DOES_NOT_EXIST = "USER_DOES_NOT_EXIST",
}

export enum JoinBroadcastErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  BROADCAST_FULL = "BROADCAST_FULL",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  NO_BROADCAST_RUNNING = "NO_BROADCAST_RUNNING",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum JoinNextSocialTableErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum JoinSocialSpaceErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  PARAMETER_MISSING = "PARAMETER_MISSING",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum JoinSocialTableErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
  TABLE_BLOWING_UP = "TABLE_BLOWING_UP",
  TABLE_DOES_NOT_EXIST = "TABLE_DOES_NOT_EXIST",
  TABLE_FULL = "TABLE_FULL",
}

export enum KickFromBroadcastAction {
  ASK_TO_LEAVE = "ASK_TO_LEAVE",
  KICK_NOW = "KICK_NOW",
}

export enum KickFromBroadcastErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
  USER_NOT_BROADCASTING = "USER_NOT_BROADCASTING",
}

export enum KickUserErrorType {
  ACCOUNT_NOT_FOUND = "ACCOUNT_NOT_FOUND",
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
}

export enum LeaveBroadcastErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum LeaveScreenshareBroadcastErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum LeaveSocialTableErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
  TABLE_DOES_NOT_EXIST = "TABLE_DOES_NOT_EXIST",
}

export enum LoginErrorType {
  ACCOUNT_NOT_FOUND = "ACCOUNT_NOT_FOUND",
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  UNKNOWN = "UNKNOWN",
}

export enum LogoutErrorType {
  ACCOUNT_NOT_FOUND = "ACCOUNT_NOT_FOUND",
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  UNKNOWN = "UNKNOWN",
}

export enum MutationErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  EXPIRED = "EXPIRED",
  OBJECT_ALREADY_EXISTS = "OBJECT_ALREADY_EXISTS",
  OBJECT_MISSING = "OBJECT_MISSING",
  PARAMETER_MISSING = "PARAMETER_MISSING",
  UNKNOWN = "UNKNOWN",
}

export enum NotifySocialSpaceErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum OrganizerSocialEventType {
  END_SESSION = "END_SESSION",
  NOTIFY = "NOTIFY",
  REDIRECT = "REDIRECT",
}

export enum RedirectSocialSpaceErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum RegisterPushSubscriptionErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  FAILED = "FAILED",
}

export enum Role {
  ADMIN = "ADMIN",
  BANNED = "BANNED",
  GUEST = "GUEST",
  NONE = "NONE",
  ORGANIZER = "ORGANIZER",
  VISITOR = "VISITOR",
}

export enum SetSocialSpaceFormatErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  PAYMENT_REQUIRED = "PAYMENT_REQUIRED",
  SIGNUP_REQUIRED = "SIGNUP_REQUIRED",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum SetSocialSpacePasswordErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  PAYMENT_REQUIRED = "PAYMENT_REQUIRED",
  SIGNUP_REQUIRED = "SIGNUP_REQUIRED",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum SetSocialSpacePublicErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum SignUpErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  UNKNOWN = "UNKNOWN",
  USER_EXISTS = "USER_EXISTS",
}

export enum SocialSpaceFormatGroupShuffling {
  ALWAYS_SAME_GROUP = "ALWAYS_SAME_GROUP",
  RANDOM = "RANDOM",
}

export enum SocialSpaceFormatRoaming {
  FREE_ROAMING = "FREE_ROAMING",
  NO_ROAMING = "NO_ROAMING",
}

export enum SocialSpacePasswordErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum SocialTableActionTag {
  BLOWING_UP = "BLOWING_UP",
  ONE_ON_ONE = "ONE_ON_ONE",
}

export enum SocialTableActionsEventType {
  BLOW_UP_TABLE = "BLOW_UP_TABLE",
  JOIN_SINGLE_USER_TABLE = "JOIN_SINGLE_USER_TABLE",
}

export enum StartBroadcastErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum StopBroadcastErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export enum StoreImageErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
}

export enum UserSocialEventType {
  INVITE_TO_BROADCAST = "INVITE_TO_BROADCAST",
  INVITE_TO_BROADCAST_ACCEPTED = "INVITE_TO_BROADCAST_ACCEPTED",
  INVITE_TO_BROADCAST_DECLINED = "INVITE_TO_BROADCAST_DECLINED",
  INVITE_TO_ONE_ON_ONE = "INVITE_TO_ONE_ON_ONE",
  INVITE_TO_ONE_ON_ONE_ACCEPTED = "INVITE_TO_ONE_ON_ONE_ACCEPTED",
  INVITE_TO_ONE_ON_ONE_DECLINED = "INVITE_TO_ONE_ON_ONE_DECLINED",
  LEAVE_BROADCAST = "LEAVE_BROADCAST",
}

export enum WatchBroadcastErrorType {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  JOIN_SPACE_FIRST = "JOIN_SPACE_FIRST",
  NO_BROADCAST_RUNNING = "NO_BROADCAST_RUNNING",
  SPACE_DOES_NOT_EXIST = "SPACE_DOES_NOT_EXIST",
}

export interface AcceptInviteToBroadcastInput {
  spaceId: string;
  inviteId: string;
}

export interface AcceptInviteToOneOnOneInput {
  spaceId: string;
  inviteId: string;
}

export interface CreateSocialSpaceInput {
  name: string;
  slug: string;
}

export interface CreateSocialTableInput {
  spaceId: string;
}

export interface DeclineInviteToBroadcastInput {
  spaceId: string;
  inviteId: string;
  reason: DeclineInviteToBroadcastReason;
}

export interface DeclineInviteToOneOnOneInput {
  spaceId: string;
  inviteId: string;
  reason: DeclineInviteToOneOnOneReason;
}

export interface EndSpaceSessionInput {
  spaceId: string;
  time: GQLScalarDateTime;
  message: string;
}

export interface InviteToBroadcastInput {
  spaceId: string;
  userId: string;
}

export interface InviteToOneOnOneInput {
  spaceId: string;
  userId: string;
}

export interface JoinBroadcastInput {
  spaceId: string;
}

export interface JoinNextSocialTableInput {
  spaceId: string;
}

export interface JoinScreenshareBroadcastInput {
  spaceId: string;
}

export interface JoinSocialSpaceInput {
  spaceId?: string | null;
  spaceSlug?: string | null;
  password?: string | null;
}

export interface JoinSocialTableInput {
  spaceId: string;
  tableId: string;
}

export interface KickFromBroadcastInput {
  spaceId: string;
  uid: number;
  action: KickFromBroadcastAction;
}

export interface KickUserInput {
  userId: string;
}

export interface LeaveBroadcastInput {
  spaceId: string;
}

export interface LeaveScreenshareBroadcastInput {
  spaceId: string;
}

export interface LeaveSocialTableInput {
  spaceId: string;
  tableId: string;
}

export interface LoginInput {
  email: string;
  password: string;
}

export interface NotifySocialSpaceInput {
  spaceId: string;
  message: string;
}

export interface PublicSpacesInput {
  tags?: string[] | null;
}

export interface QuerySocialSpaceInput {
  id?: string | null;
  slug?: string | null;
}

export interface QuerySocialSpacePasswordInput {
  id?: string | null;
  slug?: string | null;
}

export interface QuerySocialSpaceUsersInput {
  spaceId: string;
  activeLastMinutes: number;
}

export interface QueryUserProfileInput {
  userId: string;
}

export interface RedirectSocialSpaceInput {
  spaceId: string;
  redirectUrl: string;
}

export interface RegisterPushSubscriptionInput {
  subscription: GQLScalarJSONObject;
  endpoint: string;
  userAgent: string;
  spaceIds?: string[] | null;
  tags?: string[] | null;
}

export interface SetSocialSpaceFormatInput {
  spaceId: string;
  seatsSoftLimit: number;
  seatsHardLimit: number;
  seatsBlowUpLimit?: number | null;
  roaming: SocialSpaceFormatRoaming;
  shuffling: SocialSpaceFormatGroupShuffling;
  peerToPeer: boolean;
  enableOneOnOne: boolean;
}

export interface SetSocialSpacePasswordInput {
  spaceId: string;
  password?: string | null;
}

export interface SetSocialSpacePublicInput {
  spaceId: string;
  isPublic: boolean;
}

export interface SetSpaceInstructionsInput {
  spaceId: string;
  instructions: string;
}

export interface SetSpaceTimerInput {
  spaceId: string;
  startTime?: GQLScalarDateTime | null;
  durationSeconds?: number | null;
}

export interface SignUpInput {
  email: string;
  password: string;
  role: Role;
}

export interface StartBroadcastInput {
  spaceId: string;
}

export interface StopBroadcastInput {
  spaceId: string;
}

export interface SubscribeToOrganizerSocialEventsInput {
  spaceId: string;
}

export interface SubscribeToSocialSpaceStatsInput {
  spaceId: string;
}

export interface SubscribeToSocialTableActionsInput {
  spaceId: string;
  tableId: string;
}

export interface UpdateProfileInput {
  name?: string | null;
  company?: string | null;
  jobTitle?: string | null;
  socialBlurb?: string | null;
  twitter?: string | null;
  linkedIn?: string | null;
  pictureUrl?: string | null;
}

export interface WatchBroadcastInput {
  spaceId: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
