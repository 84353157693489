import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { linkAuthContext, token } from 'auth';
import { graphqlUrl } from './config';
import { logger } from 'logging';
import { getMainDefinition } from '@apollo/client/utilities';
//import { createUploadLink } from 'apollo-upload-client';

const cache = new InMemoryCache();

// needed for logrocket
const fetcher = (input: RequestInfo, init?: RequestInit) => {
  return window.fetch(input, init);
};

const requestLink = setContext(linkAuthContext);
const retryLink = new RetryLink();

function createWebSocketLink() {
  const webSocketUrl = graphqlUrl.replace(/^http/, 'ws');
  const subscriptionClient = new SubscriptionClient(webSocketUrl, {
    lazy: true,
    reconnect: true,
    timeout: 30000,
    connectionParams: () => ({
      authToken: token(),
    }),
  });
  //SubscriptionClient uses backoff strategy for connecting with initial timeout of 1s regardless of what the total timeout is
  //@ts-ignore
  subscriptionClient.maxConnectTimeGenerator.duration = () => subscriptionClient.maxConnectTimeGenerator.max;
  const link = new WebSocketLink(subscriptionClient);
  return link;
}
export const client = new ApolloClient({
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
  },
  link: ApolloLink.split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    createWebSocketLink(),
    ApolloLink.from([
      onError(({ operation, graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          logger.error(
            `[GraphQL error]: Operation name: ${operation.operationName}, Variables: ${JSON.stringify(
              operation.variables
            )}`
          );
          graphQLErrors.forEach(({ message, locations, path }) =>
            logger.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
          );
        }
        if (networkError) {
          logger.error(`[Network error]: ${networkError}`);
        }
      }),
      retryLink,
      requestLink,
      createHttpLink({
        uri: graphqlUrl,
        fetch: fetcher,
        credentials: 'include',
      }),
      /*ApolloLink.split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return definition.kind === 'OperationDefinition' && definition.operation === 'query';
        },
        createPersistedQueryLink({ useGETForHashedQueries: true }).concat(
          createHttpLink({
            uri: graphqlUrl,
            fetch: fetcher,
            credentials: 'include',
          })
        ),
        /*createUploadLink({
          uri: graphqlUrl,
          credentials: 'include',
          fetch: fetcher,
        })
      ),*/
    ])
  ),
  cache,
});
