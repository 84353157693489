import React from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Typography, TextField, Button, Dialog, DialogActions, DialogContent, Link } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { makeStyles } from '@material-ui/core/styles';

import { FBox, FBoxV } from '@badger/design-system';

import Analytics from 'analytics';
import { useUpdateProfile, useGetUserWithProfile } from 'social.gql';
import { OwnProfilePicture } from 'components/socialSpace/OwnProfilePicture';

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: 'flex-end',
  },
  privacyLink: {
    flexGrow: 1,
    paddingLeft: 16,
    textDecoration: 'underline',
    color: 'black',
  },
});

interface Props {
  open: boolean;
  onClose: () => void;
}

export function SocialProfileDialog(props: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [name, setName] = React.useState('');
  const [socialBlurb, setSocialBlurb] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [jobTitle, setJobTitle] = React.useState('');
  const [twitter, setTwitter] = React.useState('');
  const [linkedIn, setLinkedIn] = React.useState('');

  const [nameMissingError, setNameMissingError] = React.useState(false);

  const { data } = useGetUserWithProfile();
  const [updateProfile] = useUpdateProfile();

  React.useEffect(() => {
    if (data && data.user) {
      const profile = data.user.profile;
      setName(profile.name);
      setSocialBlurb(profile.socialBlurb);
      setCompany(profile.company);
      setJobTitle(profile.jobTitle);
      setTwitter(profile.twitter);
      setLinkedIn(profile.linkedIn);
    }
  }, [data]);

  const onSubmit = async () => {
    Analytics.trackSocialProfileDialogSubmit();

    if (name === '') {
      setNameMissingError(true);
    } else {
      await updateProfile({
        variables: {
          input: {
            name,
            socialBlurb,
            company,
            jobTitle,
            twitter,
            linkedIn,
          },
        },
      });

      props.onClose();
    }
  };

  const changeName = (newName: string) => {
    setName(newName);

    if (nameMissingError) {
      setNameMissingError(false);
    }
  };

  return (
    <Dialog
      classes={{ scrollPaper: classes.scrollPaper }}
      fullWidth={true}
      maxWidth="md"
      open={props.open}
      onClose={props.onClose}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit();
        }}
        onReset={(event) => {
          event.preventDefault();
          props.onClose();
        }}
      >
        <DialogContent>
          <FBox>
            <Box width="300px">
              <OwnProfilePicture />
            </Box>
            <FBoxV pl={2}>
              <FBox>
                <TextField
                  value={name}
                  onChange={(event) => changeName(event.target.value)}
                  error={nameMissingError}
                  type="text"
                  fullWidth
                  variant="outlined"
                  helperText={nameMissingError ? t('socialProfile.noNameError') : ' '}
                  label={t('socialProfile.name')}
                />
              </FBox>
              <FBox mt={2} alignItems="center">
                <FBox flex={1}>
                  <TextField
                    value={jobTitle}
                    onChange={(event) => setJobTitle(event.target.value)}
                    type="text"
                    fullWidth
                    variant="outlined"
                    label={t('socialProfile.jobTitle')}
                    size="small"
                  />
                </FBox>
                <FBox mx={1}>
                  <Typography variant="h6">{t('socialProfile.at')}</Typography>
                </FBox>
                <FBox flex={1}>
                  <TextField
                    value={company}
                    onChange={(event) => setCompany(event.target.value)}
                    type="text"
                    fullWidth
                    variant="outlined"
                    label={t('socialProfile.company')}
                    size="small"
                  />
                </FBox>
              </FBox>
              <FBox mt={2}>
                <TextField
                  value={socialBlurb}
                  onChange={(event) => setSocialBlurb(event.target.value)}
                  type="text"
                  fullWidth
                  variant="outlined"
                  label={t('socialProfile.socialBlurb')}
                  size="small"
                />
              </FBox>
              <FBox mt={2} alignItems="center">
                <LinkedInIcon />
                <FBox mx={1} flex={1}>
                  <TextField
                    value={linkedIn}
                    onChange={(event) => setLinkedIn(event.target.value)}
                    type="text"
                    fullWidth
                    variant="outlined"
                    label={t('socialProfile.linkedIn')}
                    size="small"
                  />
                </FBox>
                <TwitterIcon />
                <FBox ml={1} flex={1}>
                  <TextField
                    value={twitter}
                    onChange={(event) => setTwitter(event.target.value)}
                    type="text"
                    fullWidth
                    variant="outlined"
                    label={t('socialProfile.twitter')}
                    size="small"
                  />
                </FBox>
              </FBox>
            </FBoxV>
          </FBox>
        </DialogContent>

        <DialogActions>
          <Link className={classes.privacyLink} target="_blank" href="https://www.humby.io/legal/privacy-policy">
            {t('common.privacy')}
          </Link>
          <Box mr={2} mb={1}>
            <Button type="reset" style={{ marginRight: 8 }} variant="contained">
              {t('common.cancel')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('common.submit')}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
}
