import React from 'react';

import { useMediaQuery, Box, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import Analytics from 'analytics';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as LogoNoText } from 'assets/icons/bird.svg';
import { FBox } from '@badger/design-system';
import { Bar } from 'components/Bar';
import { ReactComponent as ExitSpaceIcon } from 'assets/icons/exitSpace.svg';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  logoContainer: {
    '& >svg': {
      height: 42,
    },
  },
});

interface Props {
  target?: string;
}

export function LobbyFooter(props: Props) {
  const history = useHistory();
  const theme = useTheme();
  const showFull = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();

  const onExitSpace = () => {
    Analytics.trackFooterExitSpace();
    history.push(props.target ?? '/');
  };

  return (
    <Bar bottom>
      <Box className={classes.logoContainer} ml={2} flex={1}>
        {showFull ? <Logo /> : <LogoNoText />}
      </Box>
      <FBox ml="auto" flex={1} justifyContent="flex-end">
        <IconButton onClick={onExitSpace}>
          <ExitSpaceIcon />
        </IconButton>
      </FBox>
    </Bar>
  );
}
