import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';

import { SocialSpacePublic, SocialSpacePublicVariables } from 'gql/types/SocialSpacePublic';
import { SetSocialSpacePublic, SetSocialSpacePublicVariables } from 'gql/types/SetSocialSpacePublic';

const GET_SOCIAL_SPACE_PUBLIC = gql`
  query SocialSpacePublic($input: QuerySocialSpaceInput!) {
    socialSpace(input: $input) {
      id
      isPublic
    }
  }
`;

export function useGetSocialSpacePublic(variables: SocialSpacePublicVariables) {
  return useQuery<SocialSpacePublic, SocialSpacePublicVariables>(GET_SOCIAL_SPACE_PUBLIC, { variables });
}

const SET_SOCIAL_SPACE_PUBLIC = gql`
  mutation SetSocialSpacePublic($input: SetSocialSpacePublicInput!) {
    setSocialSpacePublic(input: $input) {
      socialSpace {
        id
        isPublic
      }
      error {
        type
      }
    }
  }
`;

export function useSetSocialSpacePublic() {
  return useMutation<SetSocialSpacePublic, SetSocialSpacePublicVariables>(SET_SOCIAL_SPACE_PUBLIC);
}
