import _ from 'lodash';
import React from 'react';

import { Drawer, DrawerProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { BrowsePanel } from './BrowsePanel';
import { SpaceUsersPanel } from './SpaceUsersPanel';
import { ChatPanel } from './ChatPanel';
import { PANEL_WIDTH } from './constants';

interface StyleProps {
  open: boolean;
  currentMenu: MenuItems | undefined;
}

const useStyles = makeStyles((theme) => ({
  containerSize: {
    width: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  containerSizeOpen: {
    width: `min(${PANEL_WIDTH}px, 50vw)`,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  drawer: {
    overflow: 'hidden',
    flexShrink: 1,
  },
  drawerPaper: {
    top: 'var(--navBarHeight, 0)',
    bottom: 'var(--navBarHeight, 0)',
    overflow: 'auto',
    height: 'auto',
  },
}));

type MenuItems = 'overview' | 'users' | 'chat';

interface Props {
  currentMenu: MenuItems | undefined;
  entries: MenuItems[];
}

export const SpaceDrawer = (props: Props & DrawerProps) => {
  const classes = useStyles();
  const open = props.currentMenu !== undefined && props.entries.includes(props.currentMenu);

  return (
    <>
      <Drawer
        open={open}
        variant="persistent"
        className={`${classes.drawer} ${classes.containerSize} ${open ? classes.containerSizeOpen : ''}`}
        classes={{
          paper: `${classes.drawerPaper} ${classes.containerSize} ${open ? classes.containerSizeOpen : ''}`,
        }}
        {..._.omit(props, 'currentMenu', 'entries')}
      >
        {props.entries.includes('overview') && <BrowsePanel open={props.currentMenu === 'overview'} />}
        {props.entries.includes('users') && <SpaceUsersPanel open={props.currentMenu === 'users'} />}
        {props.entries.includes('chat') && <ChatPanel open={props.currentMenu === 'chat'} />}
      </Drawer>
    </>
  );
};
