import { useHistory } from 'react-router-dom';

import { JoinSocialTable_joinSocialTable_videoChatCredentials } from 'gql/types/JoinSocialTable';

export type FeedCredentials = Omit<JoinSocialTable_joinSocialTable_videoChatCredentials, '__typename'>;

function gotoTable(
  spaceSlug: string,
  tableId: string,
  credentials?: FeedCredentials,
  promptProfileRegister?: boolean,
  openTableOverview?: boolean
) {
  return {
    url: `/in/${spaceSlug}/group/${tableId}`,
    params: {
      credentials,
      promptProfileRegister: !!promptProfileRegister,
      openTableOverview: !!openTableOverview,
    },
  };
}

export function useGotoTable() {
  const history = useHistory();

  return (
    spaceSlug: string,
    tableId: string,
    credentials?: FeedCredentials,
    promptProfileRegister?: boolean,
    openTableOverview?: boolean
  ) => {
    const { url, params } = gotoTable(spaceSlug, tableId, credentials, promptProfileRegister, openTableOverview);

    history.push(url, params);
  };
}
