import * as React from 'react';
import LogRocket from 'logrocket';

import { useTranslation } from 'react-i18next';

import { Paper, TextField, Typography, Box, CircularProgress, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FBox, FBoxV, ProminentButton, FBoxVCenter, PageContainer } from '@badger/design-system';

import { useUpdateProfile, useGetUserWithProfile } from 'social.gql';

import { SocialSpaceContext } from 'components/socialSpace';
import { SocialProfileListItem } from 'components/socialSpace/sidePanel/SocialProfileListItem';
import { VideoPreview } from 'components/socialSpace/VideoPreview';
import { FormattedMessage } from 'components/FormattedMessage';
import { LobbyFooter } from 'components/socialSpace/LobbyFooter';
import { OwnProfilePicture } from 'components/socialSpace/OwnProfilePicture';

const useStyles = makeStyles({
  spaceTitle: {
    marginTop: '0.5vh',
    justifyContent: 'flex-end',
    flex: 1,
    color: 'white',
  },
  instructions: {
    marginTop: '1vh',
    maxWidth: '80vw',
  },
  privacyLink: {
    color: 'black',
    textDecoration: 'underline',
    maxWidth: '256px',
    textAlign: 'center',
  },
});

interface Props {
  onJoin: () => void | Promise<void>;
}

export const EnterSpace = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const { spaceTablesUsers: spaceData } = React.useContext(SocialSpaceContext);

  const [nameMissingError, setNameMissingError] = React.useState<string>();
  const [name, setName] = React.useState('');

  const { data: userProfileData } = useGetUserWithProfile();
  const [setProfileName] = useUpdateProfile();

  const onJoin = async () => {
    setLoading(true);
    props.onJoin();
  };

  const onSubmitName = () => {
    if (name.trim() === '') {
      setNameMissingError(t('spaceLanding.noNameError'));
    } else {
      setProfileName({
        variables: {
          input: {
            name,
          },
        },
      }).then(onJoin);
      if (userProfileData?.user) {
        LogRocket.identify(userProfileData.user.id, {
          name: name,
          email: userProfileData.user.email,
        });
      }
    }
  };

  const onChangeName = (newName: string) => {
    setName(newName);

    if (nameMissingError) {
      setNameMissingError(undefined);
    }
  };

  const renderNameEntry = () => {
    return (
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onSubmitName();
        }}
        onReset={(event) => {
          event.preventDefault();
          setName('');
        }}
      >
        <FBoxVCenter>
          <Typography align="center" variant="h5">
            {t('spaceLanding.enterNameMessage')}
          </Typography>
          <OwnProfilePicture />
          <Box mt={3} mb={3}>
            <TextField
              value={name}
              onChange={(event) => onChangeName(event.target.value)}
              error={!!nameMissingError}
              style={{ minWidth: 256 }}
              type="text"
              fullWidth
              variant="outlined"
              helperText={nameMissingError}
              label={t('spaceLanding.namePlaceholder')}
            />
          </Box>
          <ProminentButton disabled={loading || !!nameMissingError} type="submit" variant="contained" color="primary">
            {loading ? <CircularProgress /> : t('spaceLanding.join')}
          </ProminentButton>
          <Link className={classes.privacyLink} target="_blank" href="https://www.humby.io/legal/privacy-policy">
            {t('common.privacy')}
          </Link>
        </FBoxVCenter>
      </form>
    );
  };

  const renderProfileCard = () => {
    if (!userProfileData?.user?.profile?.name) {
      return null;
    }
    return (
      <>
        <Typography align="center" variant="h4">
          {t('spaceLanding.welcomeBack')}
        </Typography>
        <OwnProfilePicture />
        <Box mt="1vh" mb="1vh">
          <SocialProfileListItem userId={userProfileData.user.id} {...userProfileData.user.profile} localUser />
        </Box>
        <ProminentButton disabled={loading} onClick={onJoin} type="submit" variant="contained" color="primary">
          {loading ? <CircularProgress /> : t('spaceLanding.join')}
        </ProminentButton>
      </>
    );
  };

  const renderInstructions = () => {
    return (
      <Paper className={classes.instructions}>
        <FBoxV p="min(1vw, 16px)">
          <Typography variant="h5">{t('spaceLanding.instructionsTitle')}</Typography>
          <FormattedMessage>{spaceData?.instructions ?? ''}</FormattedMessage>
        </FBoxV>
      </Paper>
    );
  };

  return (
    <PageContainer>
      <FBoxVCenter overflow="hidden" height="100%" width="100%">
        <FBoxVCenter className={classes.spaceTitle}>
          <Typography align="center" variant="h4">
            {t('spaceLanding.title')}
          </Typography>
          {spaceData?.instructions && renderInstructions()}
        </FBoxVCenter>
        <FBox p="1vh" width="100%" justifyContent="center" alignItems="center" flexWrap="wrap">
          <Box m="2vw" flexGrow={1} minWidth="250px" maxWidth="min(484px, 80vw)">
            <VideoPreview />
          </Box>
          <Paper>
            <FBoxV p="min(3vw, 24px)">
              {userProfileData?.user?.profile?.name ? renderProfileCard() : renderNameEntry()}
            </FBoxV>
          </Paper>
        </FBox>
      </FBoxVCenter>
      <LobbyFooter />
    </PageContainer>
  );
};
