import * as React from 'react';

export const useScreenShare = () => {
  const [screenTrack, setScreenTrackState] = React.useState<MediaStreamTrack>();
  const screenTrackRef = React.useRef<MediaStreamTrack>();
  const setScreenTrack = React.useCallback((track?: MediaStreamTrack) => {
    screenTrackRef.current?.stop();
    screenTrackRef.current = track;
    setScreenTrackState(track);
  }, []);

  const startScreenShare = React.useCallback(() => {
    // @ts-ignore
    if (navigator.mediaDevices.getDisplayMedia) {
      // @ts-ignore
      navigator.mediaDevices.getDisplayMedia().then((stream) => {
        setScreenTrack(stream.getVideoTracks()[0]);
      });
    }
  }, [setScreenTrack]);

  const stopScreenShare = React.useCallback(() => {
    setScreenTrack(undefined);
  }, [setScreenTrack]);

  React.useEffect(() => {
    if (screenTrack) {
      screenTrack.onended = () => {
        setScreenTrack(undefined);
      };
      return () => {
        screenTrack.onended = null;
      };
    }
  }, [screenTrack, setScreenTrack]);

  return {
    screenTrack,
    startScreenShare,
    stopScreenShare,
  };
};
