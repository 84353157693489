import _ from 'lodash';
import React from 'react';

import { captureException } from '@sentry/browser';

import { LoadingComponent } from '@badger/design-system';

import { VideoLayout } from './VideoLayout';

import Video, { Room, Participant } from 'twilio-video';
import { VideoStreamContext } from '../VideoStreamContext';
import { TwilioVideoContainer } from './TwilioVideoContainer';
import { GroupInviteButton } from '../GroupInviteButton';

interface FeedCredentials {
  roomName: string;
  roomPassword: string;
  subject?: string;
}

export type VideoFeedError = 'disconnected' | 'connecting-failed';

interface Props {
  credentials?: FeedCredentials;
  tableId: string;
  onError: (error: VideoFeedError) => void;
  showInvite: boolean;
}

function connectVideo(roomName: string, token: string) {
  let cancelled = false;

  const promise = async () => {
    try {
      const room = await Video.connect(token, {
        name: roomName,
        audio: false,
        video: false,
      });
      if (cancelled) {
        room.disconnect();
        return null;
      }
      return room;
    } catch (error) {
      captureException(error);
      return null;
    }
  };
  promise.cancel = () => {
    cancelled = true;
  };
  return promise;
}

export function TwilioVideoFeed(props: Props) {
  const [feedLoading, setFeedLoading] = React.useState(false);

  const { audioTrack, videoTrack } = React.useContext(VideoStreamContext);

  const [room, setRoom] = React.useState<Room | null>(null);

  const [participants, setParticipants] = React.useState<Participant[]>([]);

  const loadVideoPromise = React.useRef<ReturnType<typeof connectVideo>>();

  React.useEffect(() => {
    if (!props.credentials || room || feedLoading) {
      return;
    }

    const roomPromise = connectVideo(props.credentials.roomName, props.credentials.roomPassword);
    loadVideoPromise.current = roomPromise;
    setFeedLoading(true);

    roomPromise().then((room) => {
      setRoom(room);
      if (room) {
        setParticipants(Array.from(room.participants.values()));
      } else {
        props.onError('connecting-failed');
      }
      setFeedLoading(false);
    });
  }, [room, feedLoading, props]);

  React.useEffect(() => {
    if (room) {
      const onParticipantConnected = (participant: Participant) => setParticipants(participants.concat([participant]));
      const onParticipantDisconnected = (participant: Participant) => {
        const without = _.without(participants, participant);
        setParticipants(without);
      };

      room.on('participantConnected', onParticipantConnected);
      room.on('participantDisconnected', onParticipantDisconnected);
      return () => {
        room.off('participantConnected', onParticipantConnected);
        room.off('participantDisconnected', onParticipantDisconnected);
      };
    }
  }, [room, participants]);

  React.useEffect(() => {
    if (room) {
      const onDisconnected = () => {
        props.onError('disconnected');
      };

      room.on('disconnected', onDisconnected);
      return () => {
        room.off('disconnected', onDisconnected);
      };
    }
  }, [props, room]);

  React.useEffect(() => {
    if (room) {
      const disconnect = () => {
        try {
          room.disconnect();
        } catch (error) {
          // We might not have connected successfully, then this would throw
        }
      };
      window.addEventListener('beforeunload', disconnect);
      window.addEventListener('pagehide', disconnect);
      return () => {
        disconnect();
        window.removeEventListener('beforeunload', disconnect);
        window.removeEventListener('pagehide', disconnect);
      };
    }
  }, [room]);

  React.useEffect(() => {
    if (room) {
      room.localParticipant.audioTracks.forEach(({ track }) => {
        room.localParticipant.unpublishTrack(track);
        // Twilio does not emit this on its own
        room.localParticipant.emit('trackUnpublished', track);
      });
      if (audioTrack) {
        room.localParticipant.publishTrack(audioTrack);
      }
    }
  }, [audioTrack, room]);

  React.useEffect(() => {
    if (room) {
      room.localParticipant.videoTracks.forEach(({ track }) => {
        room.localParticipant.unpublishTrack(track);
        // Twilio does not emit this on its own
        room.localParticipant.emit('trackUnpublished', track);
      });
      if (videoTrack) {
        room.localParticipant.publishTrack(videoTrack);
      }
    }
  }, [room, videoTrack]);

  React.useEffect(() => {
    return () => {
      loadVideoPromise.current?.cancel();
    };
  }, []);

  /* tracks are set to undefined now when muted and thus unpublished
  React.useEffect(() => {
    if (room) {
      room.localParticipant.audioTracks.forEach(({ track }) => {
        track.enable(!audioMuted);
      });
    }
  }, [audioMuted, room]);

  React.useEffect(() => {
    if (room) {
      room.localParticipant.videoTracks.forEach(({ track }) => {
        track.enable(!videoMuted);
      });
    }
  }, [room, videoMuted]);
  */

  return (
    <>
      {room ? (
        <VideoLayout>
          {props.showInvite && <GroupInviteButton />}
          {participants.map((participant) => (
            <TwilioVideoContainer key={participant.sid} participant={participant} />
          ))}
          {room?.localParticipant && <TwilioVideoContainer key="local" participant={room.localParticipant} />}
        </VideoLayout>
      ) : (
        <LoadingComponent />
      )}
    </>
  );
}
