import * as React from 'react';
import moment, { duration } from 'moment';
import { useSnackbar } from 'notistack';

import { NPSSnackBar } from 'components/socialSpace/snackbar/NPSSnackBar';
import analytics from 'analytics';
import { getLocal, setLocal } from 'local';

const OLD_KEY = 'NPS_10MIN';
const KEY = 'NPS_20MIN';
const TIMEOUT = duration(20, 'minutes');
const NON_VOTER_EXPIRATION = duration(7, 'days');
const VOTER_EXPIRATION = duration(3, 'months');
const NON_VOTE = 'close';

export function useNPS() {
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    const old_vote = getLocal(OLD_KEY);
    if (old_vote !== null) {
      localStorage.removeItem(OLD_KEY);
      if (old_vote !== NON_VOTE) {
        setLocal(
          KEY,
          JSON.stringify({
            vote: old_vote,
            date: moment().toISOString(),
          })
        );
      }
    }
  }, []);

  React.useEffect(() => {
    const previous_vote = getLocal(KEY);
    if (previous_vote) {
      const vote = JSON.parse(previous_vote);
      const expirationDuration = vote.vote === NON_VOTE ? NON_VOTER_EXPIRATION : VOTER_EXPIRATION;
      const expirationDate = moment(vote.date).add(expirationDuration);
      if (moment().isAfter(expirationDate)) {
        localStorage.removeItem(KEY);
      }
    }
  }, []);

  const onVote = (vote: number) => {
    analytics.sendNPSScore(vote);
    setLocal(
      KEY,
      JSON.stringify({
        vote: vote,
        date: moment().toISOString(),
      })
    );
  };

  const onDismiss = () => {
    analytics.sendNPSScore(NON_VOTE);
    setLocal(
      KEY,
      JSON.stringify({
        vote: NON_VOTE,
        date: moment().toISOString(),
      })
    );
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (!getLocal(KEY)) {
        enqueueSnackbar(<NPSSnackBar snackKey={KEY} onVote={onVote} onDismiss={onDismiss} />, {
          key: KEY,
          persist: true,
          preventDuplicate: true,
        });
      }
    }, TIMEOUT.asMilliseconds());
  }, [enqueueSnackbar]);
}
