import React from 'react';

import { Box, CircularProgress, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { style, FBoxVCenter, FBox } from '@badger/design-system';
import { VideoControls } from './table/VideoControls';
import { VideoProfileOverlay } from './table/VideoProfileOverlay';
import { useUserId } from 'auth';
import { VideoConstraints } from './table/VideoConstraints';
import { MicrophoneMeter } from './MicrophoneMeter';
import { VideoStreamContext, playTrack } from './VideoStreamContext';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    // FIXME hack until design system has all correct transparent colors
    backgroundColor: `${style.colors.backgroundDark}b2`,
    width: '100%',
    paddingTop: `calc(100% / ${VideoConstraints.aspectRatio})`,
    '& > video': {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '8px',
    },
  },
  topOverlay: {
    position: 'absolute',
    top: 8,
    left: 0,
    right: 0,
  },
  bottomOverlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export function VideoPreview() {
  const classes = useStyles();
  const videoRef = React.useRef<HTMLVideoElement | null>();

  const { audioTrack, videoTrack, videoMuted, initialized } = React.useContext(VideoStreamContext);
  const userId = useUserId();

  React.useEffect(() => {
    if (videoTrack && videoRef.current) {
      playTrack(videoRef.current, videoTrack);
    }
  }, [videoTrack]);

  return (
    <Paper className={classes.root}>
      <video hidden={videoMuted} ref={(e) => (videoRef.current = e)} autoPlay muted controls={false} />
      <FBoxVCenter className={classes.topOverlay}>
        {initialized ? <MicrophoneMeter audioTrack={audioTrack} /> : <CircularProgress />}
      </FBoxVCenter>
      <Box className={classes.bottomOverlay}>
        <FBox justifyContent="center">
          <VideoControls />
        </FBox>
        {<VideoProfileOverlay userId={userId ?? ''} localUser={true} />}
      </Box>
    </Paper>
  );
}
