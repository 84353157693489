import * as React from 'react';

import { IconButton } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';

import Logo from 'assets/icons/logo.svg';

const useStyles = makeStyles({
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const LogoImage = styled('img')({
  height: 32,
});

interface Props {
  onPress: () => void;
}

export function HomeButton(props: Props) {
  const classes = useStyles();

  return (
    <IconButton className={classes.button} onClick={props.onPress} aria-label="home" component="span">
      <LogoImage alt="" src={Logo} />
    </IconButton>
  );
}
