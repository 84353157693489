import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

import { RegisterGuest } from 'gql/types/RegisterGuest';
import { RefreshToken } from 'gql/types/RefreshToken';
import { Register, RegisterVariables } from 'gql/types/Register';
import { Login, LoginVariables } from 'gql/types/Login';
import { Logout } from 'gql/types/Logout';

import { onTokenReceived } from './auth';

const REGISTER_GUEST = gql`
  mutation RegisterGuest {
    signUpGuest {
      userId
      token
      tokenExpiry
      role
      error {
        type
      }
    }
  }
`;

export function useRegisterGuest() {
  return useMutation<RegisterGuest, {}>(REGISTER_GUEST, {
    onCompleted: (data: RegisterGuest) => {
      if (!data.signUpGuest.error && data.signUpGuest.token) {
        onTokenReceived(data);
      }
    },
  });
}

export const REFRESH_TOKEN = gql`
  mutation RefreshToken {
    refreshToken {
      userId
      role
      token
      tokenExpiry
      error {
        type
      }
    }
  }
`;

export function useRefreshToken() {
  return useMutation<RefreshToken, {}>(REFRESH_TOKEN, {
    onCompleted: (data: RefreshToken) => {
      if (!data?.refreshToken?.error && data?.refreshToken?.token) {
        onTokenReceived(data);
      }
    },
  });
}

const REGISTER = gql`
  mutation Register($input: SignUpInput!) {
    signUp(input: $input) {
      userId
      token
      tokenExpiry
      role
      error {
        type
      }
    }
  }
`;

export function useRegister(onCompleted: (data: Register) => void) {
  return useMutation<Register, RegisterVariables>(REGISTER, {
    onCompleted: (data: Register) => {
      if (!data.signUp.error && data.signUp.token) {
        onTokenReceived(data);
      }

      onCompleted(data);
    },
  });
}

const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      userId
      token
      tokenExpiry
      role
      error {
        type
      }
    }
  }
`;

export function useLogin(onCompleted: (data: Login) => void) {
  return useMutation<Login, LoginVariables>(LOGIN, {
    onCompleted: (data: Login) => {
      if (!data.login.error && data.login.token) {
        onTokenReceived(data);
      }

      onCompleted(data);
    },
  });
}

const LOGOUT = gql`
  mutation Logout {
    logout {
      error {
        type
      }
    }
  }
`;

export function useLogoutMutation(onCompleted?: (data: Logout) => void) {
  return useMutation<Logout, {}>(LOGOUT, {
    onCompleted: (data) => {
      onCompleted?.(data);
    },
  });
}
