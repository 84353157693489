import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Anchorme } from 'react-anchorme';
import { style } from '@badger/design-system';

const useStyles = makeStyles({
  message: {
    '& > a': {
      color: style.colors.primary,
    },
  },
});

interface Props {
  children: string;
}

export function FormattedMessage(props: Props) {
  const classes = useStyles();

  const message = props.children.split('\n').map((line) => (
    <React.Fragment key={line}>
      <Anchorme key={line} target="_blank">
        {line}
      </Anchorme>
      <br />
    </React.Fragment>
  ));

  return (
    <Typography variant="body1" className={classes.message}>
      {message}
    </Typography>
  );
}
