import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { FBox, style } from '@badger/design-system';
import { useSnackbar } from 'notistack';
import { Duration } from 'moment';
import { useTimeout } from 'hooks/timeout';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    backgroundColor: style.colors.backgroundDark,
    zIndex: 0,
    '& .MuiTypography-root': {
      color: '#ffffff',
    },
    '& .MuiSvgIcon-root': {
      color: '#ffffff',
    },
  },
});

interface CloseProps {
  noClose?: false;
  snackKey: string;
  dismiss?: () => void;
  dismissText?: string;
}

interface NoCloseProps {
  noClose: true;
}

interface ActionProps {
  noAction?: false;
  action: () => void;
  actionText: string;
}

interface NoActionProps {
  noAction: true;
}

interface TimeoutProps {
  duration: Duration;
  timeout?: () => void;
  snackKey: string;
}

interface NoTimeoutProps {
  duration?: undefined;
  timeout?: undefined;
}

interface Props {
  message?: string;
  children?: React.ReactNode;
  icon?: React.ReactElement;
  urgent?: boolean;
}

export type ActionSnackBarProps = Props &
  (NoActionProps | ActionProps) &
  (NoCloseProps | CloseProps) &
  (NoTimeoutProps | TimeoutProps);

export function ActionSnackBar(props: ActionSnackBarProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { closeSnackbar } = useSnackbar();
  const { startTimer, cancelTimer } = useTimeout();

  const onAction = () => {
    cancelTimer();
    if (!props.noAction) {
      props.action();
    }
    if (!props.noClose) {
      closeSnackbar(props.snackKey);
    }
  };

  const onDismiss = () => {
    cancelTimer();
    if (!props.noClose) {
      closeSnackbar(props.snackKey);
      props.dismiss?.();
    }
  };

  React.useEffect(() => {
    if (props.duration) {
      startTimer(() => {
        closeSnackbar(props.snackKey);
        props.timeout?.();
      }, props.duration);
    }
  }, [closeSnackbar, props, startTimer]);

  return (
    <FBox className={classes.root} justifyContent="space-between" alignItems="center" flexWrap="wrap">
      <FBox alignItems="center">
        {props.icon && props.icon}
        {props.message && (
          <FBox ml={1}>
            <Typography>{props.message}</Typography>
          </FBox>
        )}
        {props.children && props.children}
      </FBox>
      <FBox ml={1} alignItems="center" justifyContent="flex-end" flexGrow={1}>
        {!props.noAction && (
          <Button onClick={onAction} variant="contained" color={props.urgent ? 'secondary' : 'primary'}>
            {props.actionText}
          </Button>
        )}
        {!props.noClose && props.dismissText ? (
          <Button
            onClick={onDismiss}
            variant="contained"
            color={props.urgent ? 'secondary' : 'primary'}
            style={{ marginLeft: '8px' }}
          >
            {props.dismissText}
          </Button>
        ) : (
          <IconButton size="small" onClick={onDismiss} aria-label={t('actionBar.close')}>
            <CloseIcon />
          </IconButton>
        )}
      </FBox>
    </FBox>
  );
}
