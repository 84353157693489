import React from 'react';
import { useTranslation } from 'react-i18next';

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import { ActionSnackBar } from './ActionSnackBar';

interface Props {
  snackKey: string;
  action: () => void;
}

export function ProfilePromptSnackBar(props: Props) {
  const { t } = useTranslation();

  return (
    <ActionSnackBar
      {...props}
      icon={<AssignmentIndIcon />}
      message={t('profilePromptSnackBar.message')}
      actionText={t('profilePromptSnackBar.action')}
    />
  );
}
