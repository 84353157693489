import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Typography } from '@material-ui/core';

import { FBox, FBoxV } from '@badger/design-system';

import { SocialSpaceContext } from './context';
import { FormattedMessage } from 'components/FormattedMessage';

interface Props {
  open: boolean;
  onClose?: () => void;
}

export const InstructionsDialog = (props: Props) => {
  const { t } = useTranslation();

  const { spaceTablesUsers } = React.useContext(SocialSpaceContext);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <FBoxV p={2} m={1}>
        <FBox mb={2} justifyContent="center">
          <Typography variant="h5">{t('instructionsPanel.instructions')}</Typography>
        </FBox>
        <FormattedMessage>
          {spaceTablesUsers?.instructions ? spaceTablesUsers.instructions : t('instructionsPanel.noInstructionsSet')}
        </FormattedMessage>
      </FBoxV>
    </Dialog>
  );
};
