import { TypedEmitter } from 'tiny-typed-emitter';

interface VideoUserEvents {
  audioTrackChanged: (newTrack: MediaStreamTrack | undefined) => void;
  videoTrackChanged: (newTrack: MediaStreamTrack | undefined) => void;
}

export class VideoUser extends TypedEmitter<VideoUserEvents> {
  readonly userId: string;
  audioTrack: MediaStreamTrack | undefined;
  videoTrack: MediaStreamTrack | undefined;

  constructor(userId: string) {
    super();
    this.userId = userId;
    this.on('audioTrackChanged', (newTrack) => (this.audioTrack = newTrack));
    this.on('videoTrackChanged', (newTrack) => (this.videoTrack = newTrack));
  }
}

export type RemoteVideoUsers = Map<string, VideoUser>;
