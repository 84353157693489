import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { LoadingComponent, FBoxV, style } from '@badger/design-system';

import { useUserId } from 'auth';
import Analytics from 'analytics';
import { useGotoTable } from 'hooks/urls';

import { useJoinNextSocialTable, useCreateSocialTable } from 'social.gql';

import { SocialSpaceContext } from '../context';

import { SidePanelContainer } from './SidePanelContainer';
import { TableOverview } from './TableOverview';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BroadcastIcon } from 'assets/icons/broadcast.svg';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import AddIcon from '@material-ui/icons/Add';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import { TablesWithUsers_socialSpace_tables } from 'gql/types/TablesWithUsers';
import { RecentUsersList } from './RecentUsersList';

const useStyles = makeStyles({
  button: {
    minHeight: 48,
    marginTop: 8,
  },
  highlightedButton: {
    color: style.colors.secondary,
    '& *': {
      color: 'white',
    },
  },
  buttonIcon: {
    width: 20,
    height: 20,
    color: style.colors.primary,
  },
});

interface Props {
  open: boolean;
}

const LIMIT = 20;

export const BrowsePanel = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const { spaceSlug, spaceTablesUsers } = React.useContext(SocialSpaceContext);
  const [tables, setTables] = React.useState<TablesWithUsers_socialSpace_tables[]>();
  const [ownTable, setOwnTable] = React.useState<TablesWithUsers_socialSpace_tables>();
  const [tablesOverflow, setTablesOverflow] = React.useState<boolean>();

  const gotoTable = useGotoTable();
  const userId = useUserId();

  React.useEffect(() => {
    const ownTable = spaceTablesUsers?.tables?.find((table) => table.users.find((user) => user.id === userId));
    setOwnTable(ownTable);

    let otherTables = spaceTablesUsers?.tables?.filter((table) => table.id !== ownTable?.id);

    otherTables?.sort((a, b) => {
      if (a.id === ownTable?.id) {
        return -1;
      }
      if (b.id === ownTable?.id) {
        return 1;
      }
      if (a.actionTags.length > 0 || !a.joinable) {
        if (b.actionTags.length > 0 || b.joinable) {
          return 0;
        }
        return 1;
      }
      if (b.actionTags.length > 0 || b.joinable) {
        return -1;
      }
      return 0;
    });

    if (otherTables && otherTables?.length > LIMIT) {
      setTablesOverflow(true);
      otherTables = otherTables.filter((table) => table.actionTags.length === 0 && table.joinable);

      if (otherTables?.length > LIMIT) {
        otherTables.length = LIMIT;
      }
    } else {
      setTablesOverflow(false);
    }

    setTables(otherTables);
  }, [ownTable, spaceTablesUsers, userId]);

  const [joinNextSocialTable] = useJoinNextSocialTable((data) => {
    if (data?.joinNextSocialTable?.table?.id) {
      const nextTableId = data?.joinNextSocialTable?.table?.id;
      const nextFeedCredentials = data?.joinNextSocialTable?.videoChatCredentials;

      if (nextFeedCredentials) {
        gotoTable(spaceSlug, nextTableId, nextFeedCredentials);
      }
    }
  });

  const [createSocialTable] = useCreateSocialTable((data) => {
    if (data?.createSocialTable?.table?.id) {
      const nextTableId = data?.createSocialTable?.table?.id;
      const nextFeedCredentials = data?.createSocialTable?.videoChatCredentials;

      if (nextFeedCredentials) {
        gotoTable(spaceSlug, nextTableId, nextFeedCredentials);
      }
    }
  });

  const onJoinRandomTable = () => {
    Analytics.trackBrowsePanelJoinRandomTable();

    if (spaceTablesUsers?.id) {
      const spaceId = spaceTablesUsers.id;

      joinNextSocialTable({
        variables: {
          input: {
            spaceId,
          },
        },
      });
    }
  };

  const onCreateTable = () => {
    Analytics.trackBrowsePanelCreateTable();

    if (spaceTablesUsers?.id) {
      const spaceId = spaceTablesUsers.id;

      createSocialTable({
        variables: {
          input: {
            spaceId,
          },
        },
      });
    }
  };

  const onStartBroadcast = () => {
    Analytics.trackBrowsePanelStartBroadcast();
    history.push(`/in/${spaceSlug}/broadcast`);
  };

  if (!tables) {
    return (
      <FBoxV flex={1}>
        <LoadingComponent />
      </FBoxV>
    );
  } else {
    const renderCurrentTable = () => {
      if (ownTable) {
        return <TableOverview table={ownTable} onTable />;
      } else {
        return <div />;
      }
    };

    const renderOtherTables = () => {
      return tables.map((table) => {
        return <TableOverview key={table.id.toString()} table={table} onTable />;
      });
    };

    const toLobby = () => {
      Analytics.trackBrowsePanelToLobby();

      history.push(`/in/${spaceSlug}/lobby`);
    };

    return (
      <SidePanelContainer open={props.open}>
        <FBoxV m={1}>
          {spaceTablesUsers?.owner?.id === userId && (
            <Button
              onClick={onStartBroadcast}
              className={`${classes.button} ${spaceTablesUsers.broadcasting ? classes.highlightedButton : ''}`}
              variant={spaceTablesUsers.broadcasting ? 'contained' : 'outlined'}
              color={spaceTablesUsers.broadcasting ? 'secondary' : 'primary'}
              startIcon={<BroadcastIcon className={classes.buttonIcon} />}
            >
              {spaceTablesUsers.broadcasting ? t('browsePanel.broadcastRunning') : t('browsePanel.startBroadcast')}
            </Button>
          )}
          <Button
            onClick={onJoinRandomTable}
            className={classes.button}
            variant="outlined"
            color="primary"
            startIcon={<ShuffleIcon className={classes.buttonIcon} />}
          >
            {t('lobby.joinRandomTable')}
          </Button>
          <Button
            onClick={onCreateTable}
            className={classes.button}
            variant="outlined"
            color="primary"
            startIcon={<AddIcon className={classes.buttonIcon} />}
          >
            {t('lobby.createTable')}
          </Button>
          {renderCurrentTable()}
          {renderOtherTables()}
          {tablesOverflow && (
            <Button
              onClick={toLobby}
              className={classes.button}
              variant="outlined"
              color="primary"
              startIcon={<FreeBreakfastIcon className={classes.buttonIcon} />}
            >
              {t('browsePanel.toLobby')}
            </Button>
          )}
        </FBoxV>
        <RecentUsersList open={props.open} />
      </SidePanelContainer>
    );
  }
};
