import * as React from 'react';

import { useHistory } from 'react-router-dom';

import { FBox } from '@badger/design-system';

import { HomeButton } from './HomeButton';
import { NavBarMenu } from './NavBarMenu';
import { Bar } from 'components/Bar';

export const NavBar = () => {
  const history = useHistory();

  const toLanding = () => {
    history.push('/');
  };

  return (
    <Bar>
      <FBox alignItems="center">
        <HomeButton onPress={toLanding} />
      </FBox>
      <FBox mr={1}>
        <NavBarMenu />
      </FBox>
    </Bar>
  );
};
