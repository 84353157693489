import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';
import { UserProfile, UserProfileVariables } from 'gql/types/UserProfile';

export const USER_PROFILE = gql`
  query UserProfile($input: QueryUserProfileInput!) {
    userProfile(input: $input) {
      id
      profile {
        id
        name
        company
        socialBlurb
        jobTitle
        twitter
        linkedIn
        pictureUrl
      }
    }
  }
`;

export function useUserProfile(userId: string) {
  return useQuery<UserProfile, UserProfileVariables>(USER_PROFILE, {
    variables: {
      input: {
        userId,
      },
    },
  });
}
