import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

import { SetSpaceInstructions, SetSpaceInstructionsVariables } from 'gql/types/SetSpaceInstructions';

export const SET_SPACE_INSTRUCTIONS = gql`
  mutation SetSpaceInstructions($input: SetSpaceInstructionsInput!) {
    setSpaceInstructions(input: $input) {
      socialSpace {
        id
        instructions
      }
      error {
        type
      }
    }
  }
`;

export function useSetSpaceInstructions() {
  return useMutation<SetSpaceInstructions, SetSpaceInstructionsVariables>(SET_SPACE_INSTRUCTIONS);
}
