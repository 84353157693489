import React from 'react';

import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FBox, FBoxV } from '@badger/design-system';

interface Props {
  children: React.ReactNode;
  title: string;
  description: string;
  icon?: string;
  IconComponent?: React.ReactNode;
}

const useStyles = makeStyles({
  paper: {
    height: '100%',
    display: 'flex',
    padding: 16,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  image: {
    height: 40,
    width: 40,
  },
});

export function SpaceOptionFrame(props: Props) {
  const classes = useStyles();

  const renderIcon = () => {
    if (props.icon) {
      return <img alt="" className={classes.image} src={props.icon} />;
    } else if (props.IconComponent) {
      // @ts-ignore
      return <props.IconComponent className={classes.image} />;
    }
  };

  return (
    <Paper className={classes.paper}>
      <FBoxV>
        <FBox m={1} alignItems="center">
          {renderIcon()}
          <FBox ml={1}>
            <Typography variant="h5">{props.title}</Typography>
          </FBox>
        </FBox>
        <FBoxV mt={1}>
          <Typography>{props.description}</Typography>
        </FBoxV>
      </FBoxV>
      {props.children}
    </Paper>
  );
}
