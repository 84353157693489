import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';
import { FBoxV } from '@badger/design-system';

import { SocialSpaceContext } from 'components/socialSpace';

export const CloseSession = () => {
  const { t } = useTranslation();

  const { spaceTablesUsers } = React.useContext(SocialSpaceContext);

  return (
    <FBoxV p={8} width="100%" justifyContent="center" alignItems="center" height="60vh">
      <Typography align="center" variant="h4">
        {spaceTablesUsers?.endSessionMessage || t('closeSession.placeholder')}
      </Typography>
    </FBoxV>
  );
};
