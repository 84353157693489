import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

import { JoinScreenshareBroadcast, JoinScreenshareBroadcastVariables } from 'gql/types/JoinScreenshareBroadcast';
import { LeaveScreenshareBroadcast, LeaveScreenshareBroadcastVariables } from 'gql/types/LeaveScreenshareBroadcast';

const JOIN_SCREENSHARE_BROADCAST = gql`
  mutation JoinScreenshareBroadcast($input: JoinScreenshareBroadcastInput!) {
    joinScreenshareBroadcast(input: $input) {
      broadcastCredentials {
        uid
        channelName
        token
      }
      error {
        type
      }
    }
  }
`;

export function useJoinScreenshareBroadcast(onCompleted?: (data: JoinScreenshareBroadcast) => void) {
  return useMutation<JoinScreenshareBroadcast, JoinScreenshareBroadcastVariables>(JOIN_SCREENSHARE_BROADCAST, {
    onCompleted,
  });
}

const LEAVE_SCREENSHARE_BROADCAST = gql`
  mutation LeaveScreenshareBroadcast($input: LeaveScreenshareBroadcastInput!) {
    leaveScreenshareBroadcast(input: $input) {
      error {
        type
      }
    }
  }
`;

export function useLeaveScreenshareBroadcast(onCompleted?: (data: LeaveScreenshareBroadcast) => void) {
  return useMutation<LeaveScreenshareBroadcast, LeaveScreenshareBroadcastVariables>(LEAVE_SCREENSHARE_BROADCAST, {
    onCompleted,
  });
}
