import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, Button } from '@material-ui/core';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';

import { FBox, FBoxV } from '@badger/design-system';

import Analytics from 'analytics';
import { SocialSpaceContext } from 'components/socialSpace';

import { SpaceOptionFrame } from './SpaceOptionFrame';

import { useNotifySocialSpace } from './NotifyOption.gql';

export function NotifyOption() {
  const { t } = useTranslation();

  const { spaceId } = React.useContext(SocialSpaceContext);
  const [message, setMessage] = React.useState('');

  const [notifySocialSpace] = useNotifySocialSpace();

  const notify = () => {
    Analytics.trackManageSendAnnouncement(message.length);

    notifySocialSpace({
      variables: {
        input: {
          spaceId,
          message,
        },
      },
    });
  };

  return (
    <SpaceOptionFrame
      title={t('manageSpace.notification.title')}
      description={t('manageSpace.notification.description')}
      IconComponent={AnnouncementOutlinedIcon}
    >
      <>
        <FBoxV mt={2} width="100%">
          <TextField
            helperText={t('manageSpace.notification.helperText')}
            onChange={(event) => setMessage(event.target.value)}
            variant="outlined"
            value={message}
          />
        </FBoxV>
        <FBox justifyContent="flex-end" mt={1}>
          <Button onClick={notify} variant="contained" color="primary">
            {t('common.send')}
          </Button>
        </FBox>
      </>
    </SpaceOptionFrame>
  );
}
