import React from 'react';
import { useTranslation } from 'react-i18next';

import { Fade, Paper, Typography } from '@material-ui/core';
import { BroadcastView } from './BroadcastView';
import { VideoLayout } from './table/VideoLayout';
import { useWatchBroadcast } from 'hooks/watchBroadcast';
import { useCountdown } from 'hooks/countdown';
import { Credentials, useAgoraClient } from 'hooks/agoraClient';
import { useAgoraRemoteUsers } from 'hooks/agoraRemoteUsers';
import { BroadcasterType } from 'gql/types/globals';
import { ScreenshareView } from './ScreenshareView';
import { parseBroadcasterType } from 'util/broadcast';

interface Props {
  credentials: Credentials;
}

export function BroadcastFeed() {
  const { t } = useTranslation();

  const [countdown, startCountdown] = useCountdown();

  const { client, connect } = useAgoraClient();
  const [hosts] = useAgoraRemoteUsers(client);

  const { credentials, startsInSeconds } = useWatchBroadcast();

  React.useEffect(() => {
    if (credentials) {
      connect(credentials);
    }
  }, [connect, credentials]);

  React.useEffect(() => {
    startCountdown(startsInSeconds);
  }, [startCountdown, startsInSeconds]);

  return (
    <>
      <VideoLayout>
        {countdown === undefined &&
          Array.from(hosts.entries()).map(([uid, host]) => {
            return parseBroadcasterType(uid) === BroadcasterType.VIDEO && <BroadcastView key={uid} user={host} />;
          })}
      </VideoLayout>
      {Array.from(hosts.entries()).map(([uid, host]) => {
        return parseBroadcasterType(uid) === BroadcasterType.SCREENSHARE && <ScreenshareView user={host} />;
      })}
      <Fade in={countdown !== undefined || hosts.size === 0}>
        <Paper
          style={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            padding: '2vh',
            top: 0,
            bottom: 0,
            left: '10vw',
            right: '10vw',
          }}
        >
          <Typography align="center" variant="subtitle1">
            {t('broadcastView.starting')}
          </Typography>
          {countdown !== undefined && (
            <>
              <Typography align="center" variant="h2">
                {countdown}
              </Typography>
              <Typography align="center" variant="subtitle1">
                {t('broadcastView.seconds')}
              </Typography>
            </>
          )}
        </Paper>
      </Fade>
    </>
  );
}
