import React from 'react';
import AgoraRTC, { IAgoraRTCClient, ConnectionState } from 'agora-rtc-sdk-ng';
import { VideoStreamContext } from 'components/socialSpace/VideoStreamContext';

interface Credentials {
  channelName: string;
  token: string;
}

export function usePublishBroadcast(client: IAgoraRTCClient) {
  const [connectionState, setConnectionState] = React.useState<ConnectionState>();
  const [published, setPublishStream] = React.useState(false);

  const { audioTrack, audioMuted, videoTrack, videoMuted } = React.useContext(VideoStreamContext);

  React.useEffect(() => {
    client.on('connection-state-change', setConnectionState);
    return () => {
      client.off('connection-state-change', setConnectionState);
    };
  }, [client]);

  // publish/mute/unmute audio
  const publishAudio = React.useCallback(async () => {
    if (audioMuted) {
      client.localTracks.forEach((track) => {
        if (track.trackMediaType === 'audio') {
          track.setEnabled(!audioMuted);
        }
      });
    } else if (audioTrack) {
      await Promise.all(
        client.localTracks.map((track) => {
          if (track.trackMediaType === 'audio' && track.getMediaStreamTrack().id !== audioTrack.id) {
            return client?.unpublish(track);
          }
          return undefined;
        })
      );
      audioTrack.enabled = true;
      const localAudioTrack = AgoraRTC.createCustomAudioTrack({
        mediaStreamTrack: audioTrack,
      });
      client.publish(localAudioTrack);
    }
  }, [audioMuted, audioTrack, client]);

  React.useEffect(() => {
    if (client && connectionState === 'CONNECTED' && published) {
      publishAudio();
    }
  }, [client, connectionState, publishAudio, published]);

  // publish/mute/unmute video
  const publishVideo = React.useCallback(async () => {
    if (videoMuted) {
      client.localTracks.forEach((track) => {
        if (track.trackMediaType === 'video') {
          track.setEnabled(false);
        }
      });
    } else if (videoTrack) {
      await Promise.all(
        client.localTracks.map((track) => {
          if (track.trackMediaType === 'video' && track.getMediaStreamTrack().id !== videoTrack.id) {
            return client?.unpublish(track);
          }
          return undefined;
        })
      );
      videoTrack.enabled = true;
      const localVideoTrack = AgoraRTC.createCustomVideoTrack({
        mediaStreamTrack: videoTrack,
      });
      client.publish(localVideoTrack);
    }
  }, [videoMuted, videoTrack, client]);

  React.useEffect(() => {
    if (client && connectionState === 'CONNECTED' && published) {
      publishVideo();
    }
  }, [client, connectionState, publishVideo, published]);

  React.useEffect(() => {
    if (!published) {
      client?.localTracks.forEach((track) => {
        client?.unpublish(track);
      });
    }
  }, [client, published]);

  const publish = React.useCallback(() => {
    setPublishStream(true);
  }, []);

  const unpublish = React.useCallback(() => {
    setPublishStream(false);
  }, []);

  return {
    publish,
    unpublish,
    published,
  };
}
