import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';

import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { InviteMenu, useInviteMenu } from '../InviteMenu';
import { style } from '@badger/design-system';
import analytics from 'analytics';

const useStyles = makeStyles({
  button: {
    color: 'white',
  },
  buttonOpen: {
    color: style.colors.secondary,
  },
  buttonPanel: {
    alignItems: 'start',
    '& > button': {
      alignItems: 'center',
    },
  },
});

export const NavBarInviteButton = () => {
  const classes = useStyles();
  const { onShare, onClose, anchorEl, share } = useInviteMenu();

  const onOpen = (event: React.MouseEvent) => {
    analytics.trackSocialSpaceNavBarShare();
    onShare(event);
  };

  return (
    <>
      <IconButton className={anchorEl ? classes.buttonOpen : classes.button} onClick={onOpen}>
        <ShareIcon />
      </IconButton>
      <InviteMenu onClose={onClose} anchorEl={anchorEl} share={share} />
    </>
  );
};
