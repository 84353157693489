import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

import { EndSpaceSession, EndSpaceSessionVariables } from 'gql/types/EndSpaceSession';

export const END_SPACE_SESSION = gql`
  mutation EndSpaceSession($input: EndSpaceSessionInput!) {
    endSpaceSession(input: $input) {
      socialSpace {
        id
        endSessionMessage
        endSessionTime
      }
      error {
        type
      }
    }
  }
`;

export function useEndSpaceSession() {
  return useMutation<EndSpaceSession, EndSpaceSessionVariables>(END_SPACE_SESSION);
}
