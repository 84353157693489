import React from 'react';

import { Box, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 5,
    overflow: 'auto',

    backgroundColor: 'transparent',
  },
  containerOpen: {
    height: '100%',
    width: '100%',
  },
  containerClosed: {
    height: 0,
    width: 0,
  },
});

interface Props {
  open: boolean;
  children: React.ReactNode;
}

export const SidePanelContainer = (props: Props) => {
  const classes = useStyles();

  return (
    <Fade in={props.open}>
      <Box className={`${classes.container} ${props.open ? classes.containerOpen : classes.containerClosed}`}>
        {props.children}
      </Box>
    </Fade>
  );
};
