import React from 'react';
import { useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { CircularProgress, Typography, Box, Button, makeStyles } from '@material-ui/core';

import { FBox, FBoxV } from '@badger/design-system';

import Analytics from 'analytics';
import { useGotoTable } from 'hooks/urls';
import { SocialSpaceContext } from 'components/socialSpace';
import { TableOverview } from 'components/socialSpace/sidePanel';

import { useJoinNextSocialTable, useCreateSocialTable } from 'social.gql';
import { LobbyFooter } from 'components/socialSpace/LobbyFooter';

const useStyles = makeStyles({
  whiteText: {
    color: 'white',
  },
  spaceName: {
    margin: '2vh',
    color: 'white',
  },
});

interface RouteStateProps {
  fromLastTableBrowsed: boolean;
  message: string;
}

interface FeedCredentials {
  roomName?: string;
  roomPassword?: string;
  subject?: string;
}

export const Lobby = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation<RouteStateProps>();

  const [message] = React.useState(location?.state?.message);

  const { spaceSlug, spaceTablesUsers, loading } = React.useContext(SocialSpaceContext);

  const gotoTable = useGotoTable();

  const [joinNextSocialTable] = useJoinNextSocialTable((data) => {
    if (data?.joinNextSocialTable?.table?.id) {
      const nextTableId = data?.joinNextSocialTable?.table?.id;
      const nextFeedCredentials = data?.joinNextSocialTable?.videoChatCredentials;

      if (nextFeedCredentials) {
        gotoTable(spaceSlug, nextTableId, nextFeedCredentials);
      }
    }
  });

  const [createSocialTable] = useCreateSocialTable((data) => {
    if (data?.createSocialTable?.table?.id) {
      const nextTableId = data?.createSocialTable?.table?.id;
      const nextFeedCredentials = data?.createSocialTable?.videoChatCredentials;

      if (nextFeedCredentials) {
        gotoTable(spaceSlug, nextTableId, nextFeedCredentials);
      }
    }
  });

  const onJoinRandomTable = () => {
    Analytics.trackLobbyJoinRandomTable();

    if (spaceTablesUsers?.id) {
      const spaceId = spaceTablesUsers.id;

      joinNextSocialTable({
        variables: {
          input: {
            spaceId,
          },
        },
      });
    }
  };

  const onCreateTable = () => {
    Analytics.trackLobbyCreateTable();

    if (spaceTablesUsers?.id) {
      const spaceId = spaceTablesUsers.id;

      createSocialTable({
        variables: {
          input: {
            spaceId,
          },
        },
      });
    }
  };

  const renderJoinRandomCreateTablePrompt = () => {
    return (
      <FBoxV className={classes.whiteText} width="min(100%, 60vw)" alignItems="center">
        {message && (
          <Box pb="1vh">
            <Typography align="center" variant="h5">
              {message}
            </Typography>
          </Box>
        )}
        <Typography align="center" variant="body1">
          {t('lobby.message')}
        </Typography>
        <Box mt={4} display="flex">
          <Box ml={2}>
            <Button onClick={onJoinRandomTable} variant="contained" color="primary">
              {t('lobby.joinRandomTable')}
            </Button>
          </Box>
          <Box ml={2}>
            <Button onClick={onCreateTable} variant="contained" color="primary">
              {t('lobby.createTable')}
            </Button>
          </Box>
        </Box>
      </FBoxV>
    );
  };

  const renderTables = () => {
    if (spaceSlug) {
      return spaceTablesUsers?.tables.map((table) => {
        return (
          <FBoxV key={table.id} m={2}>
            <TableOverview table={table} />
          </FBoxV>
        );
      });
    }
  };

  if (spaceTablesUsers && !loading) {
    return (
      <FBoxV mt={4} alignItems="center" width="100%">
        {renderJoinRandomCreateTablePrompt()}
        <FBox mx={8} mt={4} flexWrap="wrap" justifyContent="center" flexGrow={1}>
          {renderTables()}
        </FBox>
        <LobbyFooter target={`/in/${spaceSlug}/feedback`} />
      </FBoxV>
    );
  } else {
    return (
      <FBoxV justifyContent="center" alignItems="center" height="60vh" width="100%">
        <CircularProgress />
      </FBoxV>
    );
  }
};
