import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Paper, Grid, Button, Typography, Box, FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FBox, FBoxV } from '@badger/design-system';
import Analytics from 'analytics';
import { useSocialSpacePassword, useSetSocialSpacePassword } from './ManageSpace.gql';

//@ts-ignore
import randomWords from 'random-words';

import { SocialSpaceContext } from 'components/socialSpace';
import {
  SettingOption,
  EndSessionOption,
  InstructionsOption,
  SetTimerOption,
  StartBroadcast,
  RedirectOption,
  NotifyOption,
  BreakUpTableOption,
  ShareLinkButton,
} from 'components/socialSpace/manage';

import { baseUrl } from '../config';
import { SetSpacePublicSwitch } from 'components/socialSpace/manage/SetSpacePublicSwitch';

const useStyles = makeStyles({
  manageSpace: {
    alignItems: 'center',
  },
  spaceCreatedContainer: {
    maxWidth: 877,
  },
  prominentButton: {
    marginLeft: 16,
    marginRight: 16,
    height: 48,
    minWidth: 169,
  },
  whiteText: {
    color: 'white',
  },
});

export const ManageSpace = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const { spaceSlug, spaceId, spaceTablesUsers } = React.useContext(SocialSpaceContext);

  const { data: passwordData, loading: passwordLoading } = useSocialSpacePassword({
    input: {
      slug: spaceSlug,
    },
  });
  const [setSocialSpacePassword, { loading: setPasswordLoading }] = useSetSocialSpacePassword();

  const spaceUrl = `${baseUrl}/in/${spaceSlug}`;
  const pushUrl = `/in/${spaceSlug}`;

  const onPasswordChanged = (enabled: boolean) => {
    const password = enabled ? randomWords({ exactly: 3, join: '-' }) : undefined;
    setSocialSpacePassword({
      variables: {
        input: {
          spaceId,
          password,
        },
      },
    });

    if (enabled) {
      Analytics.trackEnableSpacePassword();
    } else {
      Analytics.trackDisableSpacePassword();
    }
  };

  const onJoin = () => {
    Analytics.trackManageSpaceJoin();

    history.push(pushUrl);
  };

  const joinSpaceUrl =
    setPasswordLoading || !passwordData?.socialSpacePassword?.spacePassword?.password
      ? spaceUrl
      : `${spaceUrl}?pw=${passwordData.socialSpacePassword.spacePassword.password}`;

  return (
    <FBoxV className={classes.manageSpace}>
      <FBoxV maxWidth="877px" width="100%" alignItems="center" flexShrink={0}>
        <FBoxV className={classes.whiteText} m={2} mb={3}>
          <Box pt={1}>
            <Typography align="center" variant="h3">
              {t('manageSpace.title')}
            </Typography>
          </Box>
          <Box m={1}>
            <Typography align="center" variant="h3">
              {spaceTablesUsers?.name}
            </Typography>
          </Box>
          <Box p={1}>
            <Typography align="center" variant="subtitle1">
              {t('manageSpace.message')}
            </Typography>
          </Box>
        </FBoxV>
        <Paper>
          <FBoxV p={4} alignItems="center">
            <Typography align="center" variant="h4">
              {t('manageSpace.shareTitle')}
            </Typography>
            <Box p={2}>
              <Typography align="center" variant="h6">
                {t('manageSpace.shareMessage')}
              </Typography>
            </Box>
            <FBox p={2}>
              <Typography align="center" variant="h5">
                <span role="img" aria-label="index finger">
                  👉
                </span>{' '}
                {joinSpaceUrl}
              </Typography>
            </FBox>
            <Box mb={1}>
              <SetSpacePublicSwitch />
              <FormControlLabel
                control={
                  <Switch
                    disabled={passwordLoading}
                    checked={!!passwordData?.socialSpacePassword?.spacePassword?.password}
                    onChange={(event) => onPasswordChanged(event.target.checked)}
                    color="primary"
                  />
                }
                label={t('manageSpace.addPassword')}
                labelPlacement="start"
              />
            </Box>
            <FBox>
              <ShareLinkButton className={classes.prominentButton} variant="contained" color="primary" />
              <Button
                className={classes.prominentButton}
                type="submit"
                variant="contained"
                color="primary"
                onClick={onJoin}
              >
                {t('manageSpace.join')}
              </Button>
            </FBox>
          </FBoxV>
        </Paper>
        <FBoxV className={classes.whiteText} m={2} mt={1} mb={0} alignItems="center">
          <Box mt={4} mb={1}>
            <Typography variant="h4">{t('manageSpace.manageTitle')}</Typography>
          </Box>
          <Typography variant="body1" align="center">
            {t('manageSpace.manageMessage')}
          </Typography>
        </FBoxV>
      </FBoxV>
      <FBoxV p={2} width="100%" alignItems="center">
        <Grid spacing={1} container>
          <Grid item lg={6}>
            <SettingOption />
          </Grid>
          <Grid item lg={6}>
            <StartBroadcast />
          </Grid>
          <Grid item lg={12}>
            <InstructionsOption />
          </Grid>
          <Grid item lg={6}>
            <SetTimerOption />
          </Grid>
          <Grid item lg={6}>
            <RedirectOption />
          </Grid>
          <Grid item lg={6}>
            <NotifyOption />
          </Grid>
          <Grid item lg={6}>
            <EndSessionOption />
          </Grid>
          <Grid item lg={6}>
            <BreakUpTableOption />
          </Grid>
        </Grid>
      </FBoxV>
    </FBoxV>
  );
};
