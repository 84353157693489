export const en = {
  common: {
    ok: 'Ok',
    cancel: 'Cancel',
    submit: 'Submit',
    edit: 'Edit',
    open: 'Open',
    show: 'Show',
    send: 'Send',
    hide: 'Hide',
    set: 'Set',
    clear: 'Clear',
    close: 'Close',
    minutes: 'Minutes',
    seconds: 'Seconds',
    save: 'Save',
    reset: 'Reset',
    privacy: 'See our privacy policy on how we process your data',
  },
  accountMenu: {
    logout: 'Logout',
  },
  browsePanel: {
    toLobby: 'Exit to the lobby to see all groups',
    startBroadcast: 'Start a broadcast',
    broadcastRunning: 'Broadcast running...',
  },
  loginRegisterPopover: {
    button: 'Login / Register',
    registerTab: 'Register',
    loginTab: 'Login',
    emailInput: 'Email',
    passwordInput: 'Password',
    registerButton: 'Register',
    loginButton: 'Login',
    errorAccountExists: 'Account already exists.',
    errorUnknown: 'Unknown error 😓',
    errorAccountNotFound: 'Account does not exist.',
    errorWrongPassword: 'Wrong password.',
  },
  landing: {
    newPublicSpace: {
      title: 'Missing a Humby?',
      message: "Tell us what topic space you'd like to have here...",
      placeholder: 'Humby name',
      submit: 'Request',
    },
    newPrivateSpace: {
      title: 'Need a private Humby?',
      message: `You can create a private space which will not be listed here.
        Only people you invite can join.
        
        How shall your private Humby be named?`,
      placeholder: 'Humby name',
      submit: 'Create',
    },
    copyright: '©2021 Humby GmbH, ',
    imprint: 'Imprint',
  },
  manageSpace: {
    title: 'Congrats 🎉 You have a Humby!',
    message:
      "If you are not logged in with a Humby account, it's best to bookmark this page so that you can find it again later. 😉",
    shareTitle: 'Get this party started',
    shareMessage:
      'Simply share this link with all the people you want in your Humby to start your new social video space experience!',
    shareLink: 'Invite people',
    join: 'Jump in now',
    manageTitle: 'Manage Humby',
    manageMessage:
      "Your Humby is already ready to use and you don't need to configure anything. But we do offer some options to adjust your Humby to make it right for your event. While in the Humby yourself, you can always come back to this page via the top right menu.",
    addPassword: 'Add password',
    setTimerButton: 'Set',
    resetTimerButton: 'Reset',
    settings: {
      title: 'Settings',
      description: 'Here you can configure some basic settings for your Humby.',
      allowTableSwitching: 'Allow users to switch groups',
      seatsSoftLimit: 'Initially fill up groups to this size',
      seatsHardLimit: 'Maximum number of people per group',
      enableTableBlowUp: 'Blow up groups that are too big',
      seatsBlowUpLimit: 'Maximum number of people per group before it blows up after some time',
      enableOneOnOne: 'Enable 1 on 1 invites',
      groupShuffling: 'Keep groups the same when leaving and rejoining the Humby',
      disabled: 'disabled',
      invalidSeatsSoftLimit: 'Enter a number between 2 and 50 (4 recommended)',
      invalidSeatsHardLimit: 'Enter a number between 2 and 50 (6 recommended)',
    },
    setTimer: {
      title: 'Set Timer',
      description:
        'Set a timer. This will be shown to all people in the Humby. You can set and reset it as many times as you want. Nothing happens when the timer runs out. If you want something to happen, please look at the other options.',
    },
    redirect: {
      title: 'Redirect',
      description:
        'Entering a link here and clicking redirect will automatically send all users in this Humby to the link.',
      helperText: 'For example: www.example.com/myevent',
    },
    instructions: {
      title: 'Instructions',
      description:
        'Enter instructions for this Humby here. People can access the instructions at any time from the left tab bar.',
    },
    endSession: {
      title: 'End Session',
      description:
        'Enter text here and click send to end this session and send all people in this Humby to a site showing your message.',
      button: 'End Session Now',
    },
    notification: {
      title: 'Send Text Announcement',
      description: 'Send a notification message to all people in this Humby.',
      helperText: 'For example: The next talk is up now: www.example.com/myevent',
    },
    startBroadcast: {
      title: 'Start Broadcast',
      description:
        'You can talk to everyone in this Humby while they stay in their groups. Before starting you will have a video preview.',
      start: 'Start a broadcast',
    },
    breakUpTable: {
      title: 'Break Up Groups',
      description: 'People have better conversations in small groups. We can break up groups if they get too big.',
      enableBreakUpTables: 'Break up large groups',
      breakUpTableLimit: 'Maximum number of people per group before it gets broken up',
      invalidSeatsBlowUpLimit: 'Number between 3 and 49 (5 recommended)',
    },
  },
  publicSpaces: {
    latest: 'Latest Humbys',
    active: 'Active Humbys',
    empty: 'Empty Humbys',
    filtered: 'Showing Humbys tagged with:',
  },
  enterSpacePassword: {
    message: 'This Humby is password protected 🔒',
    password: 'Password',
    submit: 'Enter Humby!',
  },
  closeSession: {
    placeholder: 'The organizer ended the session',
  },
  spaceLanding: {
    title: 'Hi there! You’re about to join the discussion in a group',
    instructionsTitle: 'Message from this Humby’s organizer:',
    enterNameMessage: 'Just enter your name',
    noNameError: "Don't be a stranger",
    namePlaceholder: 'Your name',
    join: "Let's Humby",
    welcomeBack: 'Welcome back!',
  },
  socialProfile: {
    name: 'Your Name',
    noNamePlaceholder: 'Humbyer',
    socialBlurb: "What's your favorite topic to talk shop about?",
    jobTitle: 'Job Title',
    at: 'at',
    company: 'Company',
    noNameError: "Don't be a stranger",
    noBlurbError: 'Tell us just a tiny bit about yourself',
    jobTitleAtCompany: '{{jobTitle}} at {{company}}',
    twitter: 'Twitter handle',
    linkedIn: 'LinkedIn Profile URL',
    inviteToOneOnOne: 'Invite to 1:1',
    inviteToOneOnOneSent: 'Invite to {{name}} was sent',
    inviteToBroadcast: 'Invite on stage',
    inviteToBroadcastSent: 'Invite on stage was sent',
  },
  socialProfilePrompt: {
    message: "Don't be a stranger, add some profile information",
  },
  table: {
    me: 'me',
    tableEmpty: 'The group you have tried to join does not exist anymore.',
    connectingFailed: 'Failed to connect to the group.',
    disconnected: 'The group connection was closed.',
    getYourHumby: 'Get your own Humby',
    giveFeedback: 'Give your feedback about Humby',
  },
  tableOverview: {
    blowingUp: 'Blowing up',
    private: 'Private',
    full: 'Full',
  },
  broadcast: {
    title: 'You are about to start a space-wide announcement',
    message: `Once you press the start button, your video and sound will be broadcasted to all current groups in the space. We will also reduce their volume so that you can be sure they hear you.

    You can also invite a guest to join your broadcast from the group view and/or participant list.

    When you are finished, don’t forget to press the stop button so that the groups can continue socializing :-)`,
    guestMessage: 'When you are finished, don’t forget to press the Leave button :-)',
    start: 'Start broadcast',
    stop: 'End broadcast',
    starting: 'Starts in {{count}} second (click to cancel)',
    starting_plural: 'Starts in {{count}} seconds (click to cancel)',
    kicked: 'The organizer ended your announcement',
    join: 'Join broadcast',
    leave: 'Leave broadcast',
    inviteNeeded: 'You need to be invited by the organizer to go on stage',
    stopTheirScreenshare: 'Stop their screensharing',
    askToLeave: 'Ask them to leave',
    kick: 'Remove them immediately',
    screenshareInfo: 'You can share your screen, once the organizer leaves the announcement!',
    stopScreenshare: 'Stop screen sharing',
    startScreenshare: 'Share screen',
  },
  broadcastView: {
    starting: `A broadcast from the organizer starts soon...
    The presenter cannot hear you. You can continue talking in your group.`,
    seconds: 'seconds',
  },
  spaceUsers: {
    activeUsers: 'Active',
    activeUsersDescription: 'Currently active participants',
    recentUsers: 'Recent',
    recentUsersDescription: 'Participants who have been active in the last 6h',
  },
  instructionsPanel: {
    instructions: 'Instructions',
    noInstructionsSet: 'No instructions set for social space',
  },
  organizerNotifications: {
    redirect: 'We are redirecting people in this Humby.',
    redirectComplete: 'Participants have been redirected.',
    endSession: 'We are ending the session.',
    endSessionComplete: 'Session has been ended.',
  },
  npsSnackBar: {
    title: 'How likely would you recommend Humby to a friend?',
    footerLeft: 'very unlikely',
    footerRight: 'very likely',
  },
  inviteButton: {
    invite: 'Invite',
  },
  inviteMenu: {
    title: 'Invite someone to join {{spaceName}}',
    messageTitle: 'Join me at {{spaceName}}',
    message: 'Hey there 👋\nRight now I’m on Humby!\nClick this link to join me there',
    copy: 'Copy link',
  },
  thanksForSharing: {
    message: 'Thanks for sharing!',
    closeMessage: 'You can now close this tab.',
    close: 'Close',
  },
  joinTableButton: {
    label: 'Join group',
  },
  lobby: {
    message:
      "You're in the lobby of this Humby. Pick a group to join below, take a break or join a random group if you're feeling lucky.",
    joinRandomTable: 'Join random group',
    createTable: 'Create group',
  },
  cookie: {
    acceptAll: 'Accept all',
    declineOptional: 'Only accept required',
    message:
      ' We use optional third-party analytics cookies to understand how you use Humby so we can offer a better service.',
    policyLink: 'Read more in our privacy policy.',
  },
  spaceStats: {
    stats: "$t(spaceStats.user, {'count': {{users}} }) at $t(spaceStats.table, {'count': {{tables}} })",
    user: 'one user',
    user_plural: '{{count}} users',
    table: 'one group',
    table_plural: '{{count}} groups',
  },
  blowUpTableSnackBar: {
    message: 'This group is overcrowded and going to explode...',
    action: 'Escape',
  },
  profilePromptSnackBar: {
    message: 'Tell us a bit about yourself.',
    action: 'Edit Profile',
  },
  joinSingleUserTableSnackBar: {
    message: 'Someone is alone, find out who!',
    action: 'Join',
  },
  joinOtherTableSnackBar: {
    message: 'Looks like nobody is joining your group, join another one!',
    action: 'Join',
  },
  organizerNotificationSnackBar: {
    message: 'Message from the Organizer',
  },
  redirectSnackBar: {
    message: 'Your organizer is redirecting you.',
    action: 'Leave Now',
  },
  endSnackBar: {
    message: 'Your organizer is ending this session...',
    action: 'Leave Now',
  },
  endSession: {
    placeholder: 'This Session has ended.',
  },
  spaceMissing: {
    message: 'This is Humby does not exist.',
  },
  chatError: {
    message: 'Initializing chat failed. Automatically retrying in {{retryTimeout}}.',
    retry: 'Try again now',
  },
  navBarMenu: {
    loginRegister: 'Login/Register',
    editProfile: 'Edit profile',
    createNewSpace: 'Get your Humby',
    feedback: 'Give feedback',
    instructions: 'Instructions',
    help: 'Help',
    logout: 'Logout',
  },
  navBarSpacesSubMenu: {
    manage: 'Manage Humby',
    join: 'Join Humby',
  },
  invite: {
    inviteToOneOnOne: '{{name}} invited you to a 1:1',
    accept: 'Accept',
    dismiss: 'Dismiss',
    declined: 'You declined the invitation',
    wasAccepted: 'Your invite was accepted',
    wasNotAccepted: 'Your invite was not accepted',
  },
  inviteToBroadcast: {
    invited:
      'The organizer of this Humby is inviting you to join their current live broadcast.\nIf you accept this invite, your current video and audio will be streamed to all groups in this Humby right now',
    accept: 'Accept',
    decline: 'Decline',
    declined: 'You have declined the invite',
    wasAccepted: 'The invite on stage was accepted',
    wasNotAccepted: 'The invite on stage was declined',
    askedToLeave: 'The organizer asked you to leave the stage',
    leave: 'Leave',
  },
  videoPreview: {
    audioDevice: 'Microphone {{number}}',
    videoDevice: 'Camera {{number}}',
  },
  microphoneMeter: {
    ok: 'Your microphone works fine',
    noSignal: 'No signal from your microphone',
    muted: 'Your microphone is muted',
  },
  videoFeed: {
    audioNotAllowedTitle: 'No permission to use your microphone',
    audioNotAllowedMessage:
      'You did not grant permissions to use your microphone, you will not be able to use Humby. To change the permissions look for a microphone icon next to the address bar and retry.',
    audioNotAvailableTitle: 'Microphone not available',
    audioNotAvailableMessage:
      'Your microphone could not be initialized, check if it is used by another programm and retry.',
    videoNotAllowedTitle: 'No permission to use your camera',
    videoNotAllowedMessage:
      'You did not grant permissions to use your camera, you will not be able to enjoy the full Humby experience. To change the permissions look for a camera icon next to the address bar and retry.',
    videoNotAvailableTitle: 'Camera not available',
    videoNotAvailableMessage:
      'Your camera could not be initialized, check if it is used by another programm and retry.',
  },
  retryErrorDialog: {
    ignore: 'Ignore',
    retry: 'Retry',
  },
  unsupportedWarning: {
    messageUnsupported: 'Your browser is not supported',
    messagePotentiallyUnsupported: 'Your browser may not be supported, your experience may vary',
    ignore: 'Try regardless',
    messageLinux: 'Please use Chrome or Firefox',
    messageWindows: 'Please use Edge, Chrome or Firefox',
    messageMac: 'Please use Safari, Chrome or Firefox',
    messageIOs: 'Please use Safari',
    messageAndroid: 'Please use Chrome',
    messageUnknown: 'Please use Chrome, Firefox, Edge or Safari on a supported device',
  },
  videoProfileOverlay: {
    me: '(me)',
  },
  filters: {
    filters: 'Video filters',
    refract: 'Underwater',
    linear: 'Linear',
    discrete: 'Discrete',
    colorOffset: 'Color offset',
    edge: 'Edge detection',
    comic: 'Comic',
    disable: 'Disable',
  },
  pushSubscriptionDialog: {
    title: 'Notify my when someone joins',
    message: 'There is no one here right now, but we can notify you when someone joins',
    ok: 'Yes, notify me',
    no: 'No, thanks',
  },
  push: {
    title: 'Join Humby: {{spaceName}}',
    message: "Someone just joined a Humby you're interested in",
    join: 'Jump right in',
    someoneJoins: 'Someone else is about to join',
  },
};
