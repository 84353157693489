import * as React from 'react';
import { useHistory, useRouteMatch, Switch, useLocation, Route } from 'react-router-dom';
import { Box, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { FBoxV } from '@badger/design-system';

import { Lobby, ManageSpace, CloseSession } from 'pages';
import { SpaceDrawer } from 'components/socialSpace/sidePanel/SpaceDrawer';
import { SocialSpaceNavBar } from 'components/socialSpace/navbar';
import { SocialSpaceContext } from 'components/socialSpace';
import { useUserId } from 'auth';

import { SpaceTimer } from 'components/socialSpace/SpaceTimer';
import { useGetTablesWithUsers } from './SocialSpaceRouter.gql';
import { useUserSocialEvents } from 'hooks/userSocialEvents';
import { useOrganizerSocialEvents } from 'hooks/organizerSocialEvents';
import { useNPS } from 'hooks/npsHook';
import background from 'assets/background.png';

import { SocialSpaceVideoRouter } from './SocialSpaceVideoRouter';
import { Feedback } from 'pages/Feedback';

const useStyles = makeStyles({
  spaceRoot: {
    position: 'fixed',
    overflow: 'auto',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    '--navBarHeight': 'clamp(44px, calc(1.5vw + 35px), 64px)',
  },
  spaceContainer: {
    position: 'absolute',
    top: 'var(--navBarHeight, 0)',
    bottom: 'var(--navBarHeight, 0)',
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  spaceTimer: {
    position: 'fixed',
    zIndex: 10,
    width: '100%',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
});

export const SocialSpaceRouter = () => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const xsDownLayout = useMediaQuery(theme.breakpoints.down('xs'));
  const mdUpLayout = useMediaQuery(theme.breakpoints.up('md'));

  const location = useLocation<{ openTableOverview: boolean }>();
  const [currentLeftMenu, setCurrentLeftMenu] = React.useState<'overview' | 'users' | undefined>();
  const [currentRightMenu, setCurrentRightMenu] = React.useState<'chat' | undefined>();
  const spaceSlugMatch = useRouteMatch<{ spaceSlug: string }>('/in/:spaceSlug');
  const spaceSlug = React.useMemo(() => spaceSlugMatch?.params?.spaceSlug ?? '', [spaceSlugMatch]);
  const userId = useUserId();

  const { data: spaceTablesUsers, loading } = useGetTablesWithUsers({
    slug: spaceSlug,
  });

  const spaceId = React.useMemo(() => spaceTablesUsers?.socialSpace?.id ?? '', [spaceTablesUsers]);

  React.useEffect(() => {
    if (!loading && !spaceTablesUsers?.socialSpace?.id) {
      history.push('/space-missing');
    }
  }, [loading, spaceTablesUsers, history]);

  useUserSocialEvents({ spaceId, spaceSlug });
  useOrganizerSocialEvents({ spaceId, spaceSlug, isOwner: spaceTablesUsers?.socialSpace?.owner?.id === userId });
  useNPS();

  const onSetCurrentLeftMenu = React.useCallback(
    (menu: 'overview' | 'users' | undefined) => {
      if (xsDownLayout) {
        setCurrentRightMenu(undefined);
      }
      setCurrentLeftMenu(menu);
    },
    [xsDownLayout]
  );

  const onSetCurrentRightMenu = React.useCallback(
    (menu: 'chat' | undefined) => {
      if (xsDownLayout) {
        setCurrentLeftMenu(undefined);
      }
      setCurrentRightMenu(menu);
    },
    [xsDownLayout]
  );

  React.useEffect(() => {
    if (mdUpLayout && location.state?.openTableOverview) {
      onSetCurrentLeftMenu('overview');
    }
  }, [location.state, mdUpLayout, onSetCurrentLeftMenu]);

  React.useEffect(() => {
    if (xsDownLayout) {
      setCurrentLeftMenu(undefined);
      setCurrentRightMenu(undefined);
    }
  }, [location, xsDownLayout]);

  return (
    <SocialSpaceContext.Provider
      value={{ spaceId, spaceSlug, spaceTablesUsers: spaceTablesUsers?.socialSpace ?? null, loading }}
    >
      <FBoxV className={classes.spaceRoot}>
        <SocialSpaceNavBar
          currentLeftMenu={currentLeftMenu}
          setCurrentLeftMenu={onSetCurrentLeftMenu}
          currentRightMenu={currentRightMenu}
          setCurrentRightMenu={onSetCurrentRightMenu}
        />
        <Box className={classes.spaceContainer}>
          <SpaceDrawer entries={['overview', 'users']} currentMenu={currentLeftMenu} />
          {spaceTablesUsers?.socialSpace?.timerStartTime && <SpaceTimer className={classes.spaceTimer} />}
          <Switch>
            <Route path="/in/:spaceSlug/manage">
              <ManageSpace />
            </Route>
            <Route path="/in/:spaceSlug/lobby">
              <Lobby />
            </Route>
            <Route path="/in/:spaceSlug/session-closed">
              <CloseSession />
            </Route>
            <Route path="/in/:spaceSlug/feedback">
              <Feedback />
            </Route>
            <Route>
              <SocialSpaceVideoRouter />
            </Route>
          </Switch>
          <SpaceDrawer entries={['chat']} currentMenu={currentRightMenu} anchor="right" />
        </Box>
      </FBoxV>
    </SocialSpaceContext.Provider>
  );
};
