import * as React from 'react';

import { useJoinNextSocialTable } from 'social.gql';

import { useGotoTable } from 'hooks/urls';
import { SocialSpaceContext } from 'components/socialSpace';
import { EnterSpace } from './EnterSpace';

export const SpaceLanding = () => {
  const { spaceSlug, spaceTablesUsers: spaceData } = React.useContext(SocialSpaceContext);

  const gotoTable = useGotoTable();

  const [joinNextSocialTable] = useJoinNextSocialTable((data) => {
    if (data?.joinNextSocialTable?.table?.id) {
      const tableId = data?.joinNextSocialTable?.table?.id;
      const feedCredentials = data?.joinNextSocialTable?.videoChatCredentials;

      if (feedCredentials && spaceSlug) {
        gotoTable(spaceSlug, tableId, feedCredentials, true, true);
      }
    }
  });

  const onJoinTable = async () => {
    if (!spaceData) {
      return;
    }
    await joinNextSocialTable({
      variables: {
        input: {
          spaceId: spaceData.id,
        },
      },
    });
  };

  return <EnterSpace onJoin={onJoinTable} />;
};
