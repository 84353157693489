import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Typography, Link } from '@material-ui/core';
import { ReactComponent as CookieIcon } from 'assets/icons/cookie.svg';
import { style } from '@badger/design-system';

import { FBox } from '@badger/design-system';

import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    backgroundColor: style.colors.backgroundDark,
    zIndex: 0,
    color: 'white',
  },
  icon: {
    paddingRight: 8,
    minWidth: 40,
    minHeight: 40,
  },
});

interface Props {
  snackKey: string;
  onAcceptAll: () => void;
  onDeclineOptional: () => void;
}
export function CookieConsentSnackBar(props: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  const onAcceptAll = () => {
    closeSnackbar(props.snackKey);
    props.onAcceptAll();
  };

  const onDeclineOptional = () => {
    closeSnackbar(props.snackKey);
    props.onDeclineOptional();
  };

  return (
    <FBox className={classes.root} justifyContent="space-between" alignItems="center" flexWrap="wrap">
      <FBox alignItems="center">
        <CookieIcon className={classes.icon} />
        <Typography variant="body2">
          {t('cookie.message')}{' '}
          <Link href="https://www.humby.io/legal/privacy-policy" target="_blank">
            {t('cookie.policyLink')}
          </Link>
        </Typography>
      </FBox>
      <FBox
        ml={1}
        onClick={(event) => {
          event.stopPropagation();
        }}
        alignItems="center"
        justifyContent="flex-end"
        flexGrow={1}
      >
        <Button onClick={onAcceptAll} variant="contained" color="secondary" style={{ marginRight: '8px' }}>
          {t('cookie.acceptAll')}
        </Button>
        <Button onClick={onDeclineOptional} variant="contained" color="secondary">
          {t('cookie.declineOptional')}
        </Button>
      </FBox>
    </FBox>
  );
}
