import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

import { StartBroadcast, StartBroadcastVariables } from 'gql/types/StartBroadcast';
import { StopBroadcast, StopBroadcastVariables } from 'gql/types/StopBroadcast';
import { JoinBroadcast, JoinBroadcastVariables } from 'gql/types/JoinBroadcast';
import { LeaveBroadcast, LeaveBroadcastVariables } from 'gql/types/LeaveBroadcast';
import { KickFromBroadcast, KickFromBroadcastVariables } from 'gql/types/KickFromBroadcast';

const START_BROADCAST = gql`
  mutation StartBroadcast($input: StartBroadcastInput!) {
    startBroadcast(input: $input) {
      broadcastCredentials {
        uid
        channelName
        token
      }
      error {
        type
      }
    }
  }
`;

export function useStartBroadcast(onCompleted?: (data: StartBroadcast) => void) {
  return useMutation<StartBroadcast, StartBroadcastVariables>(START_BROADCAST, {
    onCompleted,
  });
}

const STOP_BROADCAST = gql`
  mutation StopBroadcast($input: StopBroadcastInput!) {
    stopBroadcast(input: $input) {
      error {
        type
      }
    }
  }
`;

export function useStopBroadcast(onCompleted?: (data: StopBroadcast) => void) {
  return useMutation<StopBroadcast, StopBroadcastVariables>(STOP_BROADCAST, {
    onCompleted,
  });
}

const JOIN_BROADCAST = gql`
  mutation JoinBroadcast($input: JoinBroadcastInput!) {
    joinBroadcast(input: $input) {
      broadcastCredentials {
        uid
        channelName
        token
      }
      error {
        type
      }
    }
  }
`;

export function useJoinBroadcast(onCompleted?: (data: JoinBroadcast) => void) {
  return useMutation<JoinBroadcast, JoinBroadcastVariables>(JOIN_BROADCAST, {
    onCompleted,
  });
}

const LEAVE_BROADCAST = gql`
  mutation LeaveBroadcast($input: LeaveBroadcastInput!) {
    leaveBroadcast(input: $input) {
      error {
        type
      }
    }
  }
`;

export function useLeaveBroadcast(onCompleted?: (data: LeaveBroadcast) => void) {
  return useMutation<LeaveBroadcast, LeaveBroadcastVariables>(LEAVE_BROADCAST, {
    onCompleted,
  });
}

const KICK_FROM_BROADCAST = gql`
  mutation KickFromBroadcast($input: KickFromBroadcastInput!) {
    kickFromBroadcast(input: $input) {
      error {
        type
      }
    }
  }
`;

export function useKickFromBroadcast(onCompleted?: (data: KickFromBroadcast) => void) {
  return useMutation<KickFromBroadcast, KickFromBroadcastVariables>(KICK_FROM_BROADCAST, {
    onCompleted,
  });
}
