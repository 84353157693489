import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button, Fade, makeStyles, Menu, MenuProps } from '@material-ui/core';

import { FBox, FBoxV, style } from '@badger/design-system';

import copyToClipboard from 'copy-to-clipboard';
import { baseUrl, facebookAppId } from 'config';
import { useLocation } from 'react-router-dom';
import { ReactComponent as CopyLinkIcon } from 'assets/icons/share/copyLink.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/share/email.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/share/facebook.svg';
import { ReactComponent as FacebookMessengerIcon } from 'assets/icons/share/facebookMessenger.svg';
import { ReactComponent as LinkedInIcon } from 'assets/icons/share/linkedIn.svg';
import { ReactComponent as RedditIcon } from 'assets/icons/share/reddit.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/share/telegram.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/share/twitter.svg';
import { ReactComponent as WhatsAppIcon } from 'assets/icons/share/whatsApp.svg';
import { SocialSpaceContext } from './context';
import { TruncateTypography } from 'components/TruncateTypography';
import analytics from 'analytics';
import { useSocialSpacePassword } from 'pages/ManageSpace.gql';

const useStyles = makeStyles({
  button: {
    color: 'white',
  },
  buttonOpen: {
    color: style.colors.secondary,
  },
  buttonPanel: {
    alignItems: 'stretch',
    '& > button': {
      alignItems: 'center',
    },
    '& .MuiButton-root': {
      justifyContent: 'start',
    },
  },
  inviteDialog: {
    maxWidth: '300px',
  },
});

function encodeParameters(url: string, parameters: { [key: string]: string }) {
  return `${url}?${Object.entries(parameters)
    .map((kv) => kv.map(encodeURIComponent).join('='))
    .join('&')}`;
}

function isMobileOrTablet() {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
}

interface ShareService {
  name: string;
  href: string;
  icon: React.ReactNode;
  track: () => void;
}

function useShare() {
  const { t } = useTranslation();
  const location = useLocation();

  const { spaceSlug, spaceTablesUsers } = React.useContext(SocialSpaceContext);
  const spaceName = React.useMemo(() => spaceTablesUsers?.name, [spaceTablesUsers]);

  const { data: passwordData } = useSocialSpacePassword({
    input: {
      slug: spaceSlug,
    },
  });

  const share = React.useMemo(() => {
    let url = `${baseUrl}/in/${spaceSlug}`;

    if (location.pathname.match(/\/in\/.*\/group\/.*/)) {
      url = `${baseUrl}${location.pathname}`;
    }

    if (passwordData?.socialSpacePassword?.spacePassword?.password) {
      url += `?pw=${passwordData.socialSpacePassword.spacePassword.password}`;
    }

    return {
      url,
      title: t('inviteMenu.messageTitle', { spaceName }),
      text: t('inviteMenu.message', { spaceName }),
      redirectUrl: `${baseUrl}/thanksForSharing`,
    };
  }, [location.pathname, passwordData, spaceName, spaceSlug, t]);

  return share;
}

export function useInviteMenu() {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const share = useShare();

  const onShare = React.useCallback(
    (event: React.MouseEvent) => {
      if (
        isMobileOrTablet() &&
        //@ts-ignore
        navigator.share
      ) {
        analytics.trackShareNative();
        navigator.share(share).then(() => analytics.trackShareNative());
      } else {
        analytics.trackShareOpen();
        setAnchorEl(event.currentTarget);
      }
    },
    [share]
  );

  const onClose = React.useCallback(() => {
    analytics.trackShareClose();
    setAnchorEl(null);
  }, []);

  return {
    onShare,
    onClose,
    anchorEl,
    share,
  };
}

type Props = Partial<MenuProps> & {
  onClose: () => void;
  share: ReturnType<typeof useShare>;
};

export const InviteMenu = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { spaceTablesUsers } = React.useContext(SocialSpaceContext);
  const spaceName = React.useMemo(() => spaceTablesUsers?.name, [spaceTablesUsers]);

  const share = props.share;

  const onCopy = () => {
    analytics.trackShareCopyLink();
    copyToClipboard(share.url, {
      format: 'text/plain',
    });
    props.onClose();
  };

  const linkedIn: ShareService = {
    name: 'LinkedIn',
    href: encodeParameters('https://www.linkedin.com/shareArticle', {
      url: share.url,
      title: share.title,
      summary: share.text,
      source: baseUrl,
    }),
    icon: <LinkedInIcon />,
    track: () => analytics.trackShareLinkedIn(),
  };

  const facebook: ShareService = {
    name: 'Facebook',
    href: encodeParameters('https://www.facebook.com/dialog/share', {
      app_id: facebookAppId,
      display: 'page',
      href: share.url,
      redirect_uri: share.redirectUrl,
    }),
    icon: <FacebookIcon />,
    track: () => analytics.trackShareFacebook(),
  };

  const facebookMessenger: ShareService = {
    name: 'Messenger',
    href: encodeParameters('https://www.facebook.com/dialog/send', {
      link: share.url,
      display: 'page',
      redirect_uri: share.redirectUrl,
      app_id: facebookAppId,
    }),
    icon: <FacebookMessengerIcon />,
    track: () => analytics.trackShareFacebookMessenger(),
  };

  const reddit: ShareService = {
    name: 'Reddit',
    href: encodeParameters('https://www.reddit.com/submit', {
      url: share.url,
      title: share.title,
      text: share.text, //markdown
    }),
    icon: <RedditIcon />,
    track: () => analytics.trackShareReddit(),
  };

  const twitter: ShareService = {
    name: 'Twitter',
    href: encodeParameters('https://twitter.com/share', {
      url: share.url,
      text: share.text,
      //hashtags: hashtags.length > 0 ? hashtags.join(',') : undefined,
      //related: related.length > 0 ? related.join(',') : undefined,
    }),
    icon: <TwitterIcon />,
    track: () => analytics.trackShareTwitter(),
  };

  const telegram: ShareService = {
    name: 'Telegram',
    href: encodeParameters('https://telegram.me/share/', {
      url: share.url,
      text: share.text,
    }),
    icon: <TelegramIcon />,
    track: () => analytics.trackShareTelegram(),
  };

  const whatsApp: ShareService = {
    name: 'WhatsApp',
    href: encodeParameters('https://wa.me/', {
      text: `${share.text}\n\n${share.url}`,
    }),
    icon: <WhatsAppIcon />,
    track: () => analytics.trackShareWhatsApp(),
  };

  const email: ShareService = {
    name: 'Email',
    href: encodeParameters('mailto:', {
      subject: share.title,
      body: `${share.text}\n\n${share.url}`,
    }),
    icon: <EmailIcon />,
    track: () => analytics.trackShareEmail(),
  };

  const services: ShareService[] = [email, facebookMessenger, whatsApp, telegram, facebook, twitter, reddit, linkedIn];

  return (
    <Menu
      className={classes.inviteDialog}
      autoFocus={false}
      anchorEl={props.anchorEl}
      open={Boolean(props.anchorEl)}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      TransitionComponent={Fade}
      variant="menu"
      {..._.omit(props, 'button')}
    >
      <FBoxV p={2} m={1} className={classes.buttonPanel}>
        <FBox mb={2} justifyContent="center">
          <TruncateTypography align="center" variant="h5" lines={2}>
            {t('inviteMenu.title', { spaceName })}
          </TruncateTypography>
        </FBox>
        <Button startIcon={<CopyLinkIcon />} onClick={onCopy}>
          {t('inviteMenu.copy')}
        </Button>
        {services.map((service) => (
          <Button
            key={service.name}
            startIcon={service.icon}
            onClick={() => {
              window.open(service.href, 'Invite to Humby!');
              service.track();
            }}
          >
            {service.name}
          </Button>
        ))}
      </FBoxV>
    </Menu>
  );
};
