import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, Button } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';

import { FBox, FBoxV } from '@badger/design-system';

import Analytics from 'analytics';
import { SocialSpaceContext } from 'components/socialSpace';

import { SpaceOptionFrame } from './SpaceOptionFrame';

import { useRedirectSocialSpace } from './RedirectOption.gql';

export function RedirectOption() {
  const { t } = useTranslation();

  const { spaceId } = React.useContext(SocialSpaceContext);

  const [redirectUrl, setRedirectUrl] = React.useState('');

  const [redirectSocialSpace] = useRedirectSocialSpace();

  const redirect = () => {
    Analytics.trackManageRedirect(redirectUrl);

    redirectSocialSpace({
      variables: {
        input: {
          spaceId,
          redirectUrl,
        },
      },
    });
  };

  return (
    <SpaceOptionFrame
      title={t('manageSpace.redirect.title')}
      description={t('manageSpace.redirect.description')}
      IconComponent={LinkIcon}
    >
      <>
        <FBoxV mt={2} width="100%">
          <TextField
            helperText={t('manageSpace.redirect.helperText')}
            onChange={(event) => setRedirectUrl(event.target.value)}
            variant="outlined"
            value={redirectUrl}
          />
        </FBoxV>
        <FBox justifyContent="flex-end" mt={1}>
          <Button onClick={redirect} variant="contained" color="primary">
            {t('common.send')}
          </Button>
        </FBox>
      </>
    </SpaceOptionFrame>
  );
}
