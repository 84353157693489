import React from 'react';

import { VideoUser } from 'util/VideoUser';
import { ScreenshareContainer } from './table/ScreenshareContainer';

interface Props {
  user: VideoUser;
}

export function ScreenshareView(props: Props) {
  const [videoTrack, setVideoTrack] = React.useState<MediaStreamTrack | undefined>(props.user.videoTrack);

  React.useEffect(() => {
    setVideoTrack(props.user.videoTrack);
    props.user.on('videoTrackChanged', setVideoTrack);
    return () => {
      props.user.off('videoTrackChanged', setVideoTrack);
    };
  }, [props.user]);

  return <ScreenshareContainer screenTrack={videoTrack} />;
}
