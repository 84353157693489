import React from 'react';

import { useMediaQuery, Box, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import Analytics from 'analytics';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as LogoNoText } from 'assets/icons/bird.svg';
import { VideoControls } from 'components/socialSpace/table/VideoControls';
import { FBox } from '@badger/design-system';
import { Bar } from 'components/Bar';
import { ReactComponent as ExitSpaceIcon } from 'assets/icons/exitSpace.svg';
import { useHistory } from 'react-router-dom';
import { SocialSpaceContext } from '../context';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  logoContainer: {
    '& >svg': {
      height: 42,
    },
  },
});

export function TableFooter() {
  const history = useHistory();
  const theme = useTheme();
  const showFull = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();

  const { spaceSlug } = React.useContext(SocialSpaceContext);

  const onExitGroup = () => {
    Analytics.trackFooterExitGroup();
    history.push(`/in/${spaceSlug}/lobby`);
  };

  return (
    <Bar bottom>
      <Box className={classes.logoContainer} ml={2} flex={1}>
        {showFull ? <Logo /> : <LogoNoText />}
      </Box>
      <FBox flex={1} justifyContent="space-around">
        <VideoControls />
      </FBox>
      <FBox ml="auto" flex={1} justifyContent="flex-end">
        <IconButton onClick={onExitGroup}>
          <ExitSpaceIcon />
        </IconButton>
      </FBox>
    </Bar>
  );
}
