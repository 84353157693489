import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Typography, TextField } from '@material-ui/core';

import { FBoxV } from '@badger/design-system';

import Analytics from 'analytics';

interface Props {
  open: boolean;
  onSetPassword: (password: string) => void;
}

export const EnterSpacePasswordDialog = (props: Props) => {
  const { t } = useTranslation();

  const [password, setPassword] = React.useState('');

  const onSubmit = () => {
    Analytics.trackEnterSocialSpacePassword();
    props.onSetPassword(password);
  };

  return (
    <Dialog open={props.open}>
      <form onSubmit={onSubmit}>
        <FBoxV p={2} m={1}>
          <Typography variant="h5">{t('enterSpacePassword.message')}</Typography>
          <TextField
            label={t('enterSpacePassword.password')}
            value={password}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value);
            }}
            variant="outlined"
            size="small"
          />
          <Button type="submit" variant="contained" color="secondary">
            {t('enterSpacePassword.submit')}
          </Button>
        </FBoxV>
      </form>
    </Dialog>
  );
};
