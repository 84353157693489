import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, Button, Box } from '@material-ui/core';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';

import { FBox } from '@badger/design-system';
import { styled } from '@material-ui/core/styles';

import { SocialSpaceContext } from 'components/socialSpace';
import Analytics from 'analytics';

import { SpaceOptionFrame } from './SpaceOptionFrame';

import { useSetSpaceTimer } from './SetTimerOption.gql';

const TimerTextField = styled(TextField)({
  width: 80,
});

export function SetTimerOption() {
  const { t } = useTranslation();

  const { spaceId } = React.useContext(SocialSpaceContext);

  const [setSpaceTimer] = useSetSpaceTimer();

  const [timerMinutes, setTimerMinutes] = React.useState(5);
  const [timerSeconds, setTimerSeconds] = React.useState(0);

  const onTimerSecondsChanged = (seconds: string) => {
    const parsedSeconds = parseInt(seconds);

    if (!isNaN(parsedSeconds)) {
      setTimerSeconds(parsedSeconds);
    } else {
      setTimerSeconds(0);
    }
  };

  const onTimerMinutesChanged = (minutes: string) => {
    const parsedMinutes = parseInt(minutes);

    if (!isNaN(parsedMinutes)) {
      setTimerMinutes(parsedMinutes);
    } else {
      setTimerMinutes(0);
    }
  };

  const setTimer = () => {
    Analytics.trackManageSetTimer(timerMinutes * 60 + timerSeconds);

    setSpaceTimer({
      variables: {
        input: {
          spaceId,
          startTime: moment().toISOString(),
          durationSeconds: timerMinutes * 60 + timerSeconds,
        },
      },
    });
  };

  const resetTimer = () => {
    Analytics.trackManageResetTimer();

    setSpaceTimer({
      variables: {
        input: {
          spaceId,
        },
      },
    });
  };

  return (
    <SpaceOptionFrame
      title={t('manageSpace.setTimer.title')}
      description={t('manageSpace.setTimer.description')}
      IconComponent={AccessAlarmIcon}
    >
      <>
        <FBox alignItems="center" mt={2}>
          <TimerTextField
            onChange={(event) => onTimerMinutesChanged(event.target.value)}
            helperText={t('common.minutes')}
            inputProps={{ style: { textAlign: 'center' } }}
            variant="outlined"
            value={timerMinutes.toString()}
          />
          <Box ml={2}>
            <TimerTextField
              onChange={(event) => onTimerSecondsChanged(event.target.value)}
              helperText={t('common.seconds')}
              inputProps={{ style: { textAlign: 'center' } }}
              variant="outlined"
              value={timerSeconds.toString()}
            />
          </Box>
        </FBox>
        <FBox justifyContent="flex-end" mt={2}>
          <Button onClick={resetTimer} variant="contained" color="primary">
            {t('manageSpace.resetTimerButton')}
          </Button>
          <Box ml={2}>
            <Button onClick={setTimer} variant="contained" color="primary">
              {t('manageSpace.setTimerButton')}
            </Button>
          </Box>
        </FBox>
      </>
    </SpaceOptionFrame>
  );
}
