import gql from 'graphql-tag';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';

import { UpdateProfile, UpdateProfileVariables } from 'gql/types/UpdateProfile';

import { SocialSpace, SocialSpaceVariables } from 'gql/types/SocialSpace';

import { CreateSocialTable, CreateSocialTableVariables } from 'gql/types/CreateSocialTable';

import { JoinNextSocialTable, JoinNextSocialTableVariables } from 'gql/types/JoinNextSocialTable';

import { JoinSocialTable, JoinSocialTableVariables } from 'gql/types/JoinSocialTable';

import { GetUserWithProfile } from 'gql/types/GetUserWithProfile';

import { QuerySocialSpaceInput } from 'gql/types/globals';
import { StoreImage } from 'gql/types/StoreImage';
import { useUserQuery } from 'auth';

export const GET_SOCIAL_SPACE = gql`
  query SocialSpace($input: QuerySocialSpaceInput!) {
    socialSpace(input: $input) {
      id
      name
    }
  }
`;

export function useGetSocialSpace(input: QuerySocialSpaceInput, onCompleted?: (data: SocialSpace) => void) {
  return useQuery<SocialSpace, SocialSpaceVariables>(GET_SOCIAL_SPACE, {
    variables: {
      input,
    },
    onCompleted,
  });
}

export function useLazyGetSocialSpace(onCompleted?: (data: SocialSpace) => void) {
  return useLazyQuery<SocialSpace, SocialSpaceVariables>(GET_SOCIAL_SPACE, {
    onCompleted,
  });
}

const CREATE_SOCIAL_TABLE = gql`
  mutation CreateSocialTable($input: CreateSocialTableInput!) {
    createSocialTable(input: $input) {
      table {
        id
      }
      videoChatCredentials {
        roomName
        roomPassword
      }
      error {
        type
      }
    }
  }
`;

export function useCreateSocialTable(onCompleted?: (data: CreateSocialTable) => void) {
  return useMutation<CreateSocialTable, CreateSocialTableVariables>(CREATE_SOCIAL_TABLE, {
    onCompleted,
  });
}

const JOIN_NEXT_SOCIAL_TABLE = gql`
  mutation JoinNextSocialTable($input: JoinNextSocialTableInput!) {
    joinNextSocialTable(input: $input) {
      table {
        id
      }
      videoChatCredentials {
        roomName
        roomPassword
      }
      error {
        type
      }
    }
  }
`;

export function useJoinNextSocialTable(onCompleted?: (data: JoinNextSocialTable) => void) {
  return useMutation<JoinNextSocialTable, JoinNextSocialTableVariables>(JOIN_NEXT_SOCIAL_TABLE, {
    onCompleted,
  });
}

const JOIN_SOCIAL_TABLE = gql`
  mutation JoinSocialTable($input: JoinSocialTableInput!) {
    joinSocialTable(input: $input) {
      table {
        id
      }
      videoChatCredentials {
        roomName
        roomPassword
      }
      error {
        type
      }
    }
  }
`;

export function useJoinSocialTable(onCompleted?: (data: JoinSocialTable) => void) {
  return useMutation<JoinSocialTable, JoinSocialTableVariables>(JOIN_SOCIAL_TABLE, {
    onCompleted,
  });
}

const CURRENT_USER_WITH_PROFILE_FRAGMENT = gql`
  fragment CurrentUserWithProfileFragment on User {
    id
    email
    role
    profile {
      id
      name
      company
      socialBlurb
      jobTitle
      twitter
      linkedIn
      pictureUrl
    }
  }
`;

export const GET_USER_WITH_PROFILE = gql`
  query GetUserWithProfile {
    user {
      ...CurrentUserWithProfileFragment
    }
  }
  ${CURRENT_USER_WITH_PROFILE_FRAGMENT}
`;

export function useGetUserWithProfile() {
  return useUserQuery<GetUserWithProfile>(GET_USER_WITH_PROFILE);
}

const UPDATE_PROFILE = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      user {
        ...CurrentUserWithProfileFragment
      }
    }
  }
  ${CURRENT_USER_WITH_PROFILE_FRAGMENT}
`;

export function useUpdateProfile(onCompleted?: (data: UpdateProfile) => void) {
  return useMutation<UpdateProfile, UpdateProfileVariables>(UPDATE_PROFILE, {
    onCompleted,
  });
}

const STORE_IMAGE = gql`
  mutation StoreImage {
    storeImage {
      putUrl
      pictureUrl
      error {
        type
      }
    }
  }
`;

export function useStoreImage() {
  return useMutation<StoreImage>(STORE_IMAGE);
}
