import * as React from 'react';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';

import { TextField, Button, Box, Switch, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FlareIcon from '@material-ui/icons/Flare';

import { FBox, FBoxV, LoadingComponent } from '@badger/design-system';

import Analytics from 'analytics';
import { SocialSpaceContext } from 'components/socialSpace';

import { SpaceOptionFrame } from './SpaceOptionFrame';

import { useGetSocialSpaceFormat, useSetSocialSpaceFormat } from './format.gql';

const useStyles = makeStyles({
  root: {
    alignItems: 'stretch',
    padding: '8px',
    '& .MuiFormControlLabel-root': {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: 0,
      marginRight: 0,
      '& .MuiTextField-root': {
        width: '100px',
      },
      '& .MuiFormHelperText-root.Mui-focused': {
        display: 'none',
      },
    },
  },
});

export const BreakUpTableOption = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { spaceSlug, spaceId } = React.useContext(SocialSpaceContext);

  const [seatsBlowUpLimit, setSeatsBlowUpLimit] = React.useState<string | null>();
  const [error, setError] = React.useState<string>();
  const { data } = useGetSocialSpaceFormat({ slug: spaceSlug });
  const [submitFormat] = useSetSocialSpaceFormat();

  const resetFormat = React.useCallback(() => {
    if (data?.socialSpace?.format) {
      setSeatsBlowUpLimit(
        data.socialSpace.format.seatsBlowUpLimit === null ? null : `${data.socialSpace.format.seatsBlowUpLimit}`
      );
    }
  }, [data]);

  React.useEffect(() => {
    if (seatsBlowUpLimit === undefined) {
      resetFormat();
    }
  }, [seatsBlowUpLimit, resetFormat]);

  if (seatsBlowUpLimit === undefined || !data || !data.socialSpace) {
    return <LoadingComponent />;
  }

  const onEnableBlowUpLimitChanged = (enabled: boolean) => {
    if (enabled) {
      const seatsBlowUpLimit = data?.socialSpace?.format
        ? `${Math.max(data.socialSpace.format.seatsHardLimit - 3, data.socialSpace.format.seatsSoftLimit - 1)}`
        : '5';

      setSeatsBlowUpLimit(seatsBlowUpLimit);
      Analytics.trackManageEnableBreakUpGroups(seatsBlowUpLimit);
    } else {
      setError(undefined);
      setSeatsBlowUpLimit(null);
      Analytics.trackManageDisableBreakUpGroups();
    }
  };

  const onSeatsBlowUpLimitChanged = (input: string, disabled: boolean) => {
    if (disabled) {
      return;
    }

    setSeatsBlowUpLimit(input);

    const parsedSeats = parseInt(input);
    if (!isNaN(parsedSeats)) {
      setError(undefined);
    } else {
      setError(t('manageSpace.breakUpTable.invalidSeatsBlowUpLimit'));
    }

    Analytics.trackManageEnableBreakUpGroups(input);
  };

  const onSubmit = () => {
    let parsedLimit: number | null;
    if (seatsBlowUpLimit === null) {
      parsedLimit = null;
    } else {
      parsedLimit = parseInt(seatsBlowUpLimit);
      if (isNaN(parsedLimit)) {
        return;
      }
    }

    submitFormat({
      variables: {
        input: {
          spaceId,
          ..._.omit(data?.socialSpace?.format, '__typename', 'id'),
          seatsBlowUpLimit: parsedLimit,
        },
      },
    });
  };

  const onReset = () => {
    resetFormat();
  };

  const controlsDisabled =
    !!error ||
    data.socialSpace.format.seatsBlowUpLimit === seatsBlowUpLimit ||
    `${data.socialSpace.format.seatsBlowUpLimit}` === seatsBlowUpLimit;

  return (
    <SpaceOptionFrame
      title={t('manageSpace.breakUpTable.title')}
      description={t('manageSpace.breakUpTable.description')}
      IconComponent={FlareIcon}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit();
        }}
      >
        <FBoxV className={classes.root}>
          <FormControlLabel
            control={
              <Switch
                checked={seatsBlowUpLimit !== null}
                onChange={(event) => onEnableBlowUpLimitChanged(event.target.checked)}
                color="primary"
              />
            }
            label={t('manageSpace.breakUpTable.enableBreakUpTables')}
            labelPlacement="start"
          />
          <FormControlLabel
            disabled={seatsBlowUpLimit === null}
            control={
              <TextField
                error={!!error}
                helperText={error}
                onChange={(event) => onSeatsBlowUpLimitChanged(event.target.value, event.target.disabled)}
                variant="outlined"
                value={seatsBlowUpLimit ?? ''}
              />
            }
            label={t('manageSpace.breakUpTable.breakUpTableLimit')}
            labelPlacement="start"
          />
        </FBoxV>
        <FBox justifyContent="flex-end" mt={2}>
          <Button variant="contained" color="primary" type="submit" disabled={controlsDisabled}>
            {t('common.save')}
          </Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" type="reset" disabled={controlsDisabled} onClick={onReset}>
              {t('common.reset')}
            </Button>
          </Box>
        </FBox>
      </form>
    </SpaceOptionFrame>
  );
};
