import React from 'react';

import { useTranslation } from 'react-i18next';

import NearMeIcon from '@material-ui/icons/NearMe';
import { Button } from '@material-ui/core';

import { useGotoTable } from 'hooks/urls';

import Analytics from 'analytics';

import { SocialSpaceContext } from '../context';

interface Props {
  tableId: string;
  fromTable?: boolean;
}

export function JoinTableButton(props: Props) {
  const { t } = useTranslation();

  const gotoTable = useGotoTable();
  const { spaceSlug } = React.useContext(SocialSpaceContext);

  const onJoinTable = () => {
    Analytics.trackTableOverviewJoinTable();

    gotoTable(spaceSlug, props.tableId, undefined, props.fromTable);
  };

  return (
    <Button onClick={onJoinTable} startIcon={<NearMeIcon />} variant="contained" color="primary">
      {t('joinTableButton.label')}
    </Button>
  );
}
