import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { FBox, AppBar, style, FBoxV } from '@badger/design-system';

import { ReactComponent as GroupsIcon } from 'assets/icons/groups.svg';
import { NavBarButton } from './NavBarButton';
import { NavBarMenu } from '../../navBar/NavBarMenu';
import { ChatButton } from './ChatButton';

import Analytics from 'analytics';

import { SocialSpaceContext } from '../context';
import { TruncateTypography } from 'components/TruncateTypography';
import { NavBarInviteButton } from './NavBarInviteButton';

const useStyles = makeStyles((theme) => ({
  navBar: {
    // FIXME hack until design system has all correct transparent colors
    backgroundColor: `${style.colors.backgroundDark}dd`,
    height: 'var(--navBarHeight, 64px)',
    '& .MuiToolbar-root': {
      height: '100%',
    },
    zIndex: theme.zIndex.drawer + 1,
    color: 'white',
  },
  navBarLayout: {
    display: 'grid',
    gridTemplateColumns: '142px auto 142px',
    width: '100%',
    height: '100%',
    alignItems: 'stretch',
    '& .MuiIconButton-root': {
      height: '100%',
      width: '71px',
      padding: 0,
    },
  },
}));
type LeftMenuItems = 'overview' | 'users';
type RightMenuItems = 'chat';

interface Props {
  currentLeftMenu: LeftMenuItems | undefined;
  setCurrentLeftMenu: (currentMenu: LeftMenuItems | undefined) => void;
  currentRightMenu: RightMenuItems | undefined;
  setCurrentRightMenu: (currentMenu: RightMenuItems | undefined) => void;
}

export const SocialSpaceNavBar = (props: Props) => {
  const classes = useStyles();

  const { spaceId, spaceTablesUsers } = React.useContext(SocialSpaceContext);

  const showInstructions = React.useMemo(() => !!spaceTablesUsers?.instructions, [spaceTablesUsers]);

  const openOrCloseLeft = (menuItem: LeftMenuItems) => {
    const trackCloseLeft = () => {
      if (props.currentLeftMenu === 'overview') {
        Analytics.trackNavBarCloseOverviewDrawer();
      } else if (props.currentLeftMenu === 'users') {
        Analytics.trackNavBarCloseUsersDrawer();
      }
    };
    return () => {
      if (props.currentLeftMenu === menuItem) {
        props.setCurrentLeftMenu(undefined);
        trackCloseLeft();
      } else {
        trackCloseLeft();
        props.setCurrentLeftMenu(menuItem);
        if (menuItem === 'overview') {
          Analytics.trackNavBarOpenOverviewDrawer();
        } else if (menuItem === 'users') {
          Analytics.trackNavBarOpenUsersDrawer();
        }
      }
    };
  };

  const openOrCloseRight = (menuItem: RightMenuItems) => {
    return () => {
      if (props.currentRightMenu === menuItem) {
        Analytics.trackNavBarCloseChatDrawer();
        props.setCurrentRightMenu(undefined);
      } else {
        Analytics.trackNavBarOpenChatDrawer();
        props.setCurrentRightMenu(menuItem);
      }
    };
  };

  return (
    <AppBar className={classes.navBar}>
      <FBox className={classes.navBarLayout}>
        <FBox alignItems="stretch">
          <NavBarButton selected={props.currentLeftMenu === 'overview'} onClick={openOrCloseLeft('overview')}>
            <GroupsIcon />
          </NavBarButton>
          <NavBarInviteButton />
        </FBox>
        <FBoxV alignItems="stretch" justifyContent="center">
          <TruncateTypography align="center" variant="h5" lines={2}>
            {spaceTablesUsers?.name ?? ''}
          </TruncateTypography>
        </FBoxV>
        <FBox alignItems="stretch">
          <ChatButton selected={props.currentRightMenu === 'chat'} onClick={openOrCloseRight('chat')} />
          <NavBarMenu spaceId={spaceId} showInstructions={showInstructions} />
        </FBox>
      </FBox>
    </AppBar>
  );
};
