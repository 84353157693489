import gql from 'graphql-tag';
import { useSubscription } from '@apollo/client';
import { SubscriptionResult } from '@apollo/react-common';

import { SubscribeToOrganizerSocialEventsInput } from 'gql/types/globals';
import { OrganizerSocialEvents, OrganizerSocialEventsVariables } from 'gql/types/OrganizerSocialEvents';

export const SUBSCRIBE_TO_ORGANIZER_SOCIAL_EVENTS = gql`
  subscription OrganizerSocialEvents($input: SubscribeToOrganizerSocialEventsInput!) {
    organizerSocialEvents(input: $input) {
      id
      type
      message
      url
    }
  }
`;

export function useOrganizerSocialEventsSubscription(
  input: SubscribeToOrganizerSocialEventsInput,
  onSubscriptionData?: (data: SubscriptionResult<OrganizerSocialEvents>) => void
) {
  return useSubscription<OrganizerSocialEvents, OrganizerSocialEventsVariables>(SUBSCRIBE_TO_ORGANIZER_SOCIAL_EVENTS, {
    variables: {
      input,
    },
    onSubscriptionData: (data) => {
      onSubscriptionData?.(data.subscriptionData);
    },
  });
}
