import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { SetSpaceTimer, SetSpaceTimerVariables } from 'gql/types/SetSpaceTimer';

export const SET_SPACE_TIMER = gql`
  mutation SetSpaceTimer($input: SetSpaceTimerInput!) {
    setSpaceTimer(input: $input) {
      socialSpace {
        id
        timerStartTime
        timerDurationSeconds
      }
      error {
        type
      }
    }
  }
`;

export function useSetSpaceTimer() {
  return useMutation<SetSpaceTimer, SetSpaceTimerVariables>(SET_SPACE_TIMER);
}
