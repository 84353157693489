const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000';
const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:3000';
const sentryDsn = process.env.REACT_APP_VISITOR_SENTRY_DSN;
const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || 'UA-000000-01';
const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY ?? '';
const chatUrl = process.env.REACT_APP_RC_ROOT_URL || 'http://localhost:3001';
const logRocketAppId = process.env.REACT_APP_LOG_ROCKET_APP_ID;
const datadogClientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? '';
const facebookAppId = process.env.REACT_APP_FB_APP_ID ?? '';
const slackWebhook = process.env.REACT_APP_SLACK_WEBHOOK ?? '';
const postHogHost = process.env.REACT_APP_POST_HOG_HOST ?? '';
const postHogKey = process.env.REACT_APP_POST_HOG_KEY ?? '';

const graphqlUrl = `${backendUrl}/graphql`;

enum DeploymentVersion {
  PRODUCTION = 'PRODUCTION',
  STAGING = 'STAGING',
  DEV = 'DEV',
}

function getDeployment(): DeploymentVersion {
  const deploymentVersion = process.env.REACT_APP_DEPLOYMENT_VERSION;

  if (!deploymentVersion) {
    return DeploymentVersion.DEV;
  } else {
    return deploymentVersion as DeploymentVersion;
  }
}

const Deployment = getDeployment();

const isDev = () => Deployment === DeploymentVersion.DEV;
const isStaging = () => Deployment === DeploymentVersion.STAGING;
const isProduction = () => Deployment === DeploymentVersion.PRODUCTION;

export {
  Deployment,
  isDev,
  isStaging,
  isProduction,
  backendUrl,
  graphqlUrl,
  baseUrl,
  sentryDsn,
  googleAnalyticsId,
  amplitudeApiKey,
  chatUrl,
  logRocketAppId,
  datadogClientToken,
  facebookAppId,
  slackWebhook,
  postHogHost,
  postHogKey,
};
