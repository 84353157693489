import React from 'react';

import { useTranslation } from 'react-i18next';
import ClearIcon from '@material-ui/icons/Clear';
import { LoadingComponent, FBoxV, FBox } from '@badger/design-system';

import { usePublicSpaces } from './PublicSpaces.gql';
import { PublicSpaceCard, PublicSpaceCardProps } from './PublicSpaceCard';
import { LandingAccordion } from './LandingAccordion';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import analytics from 'analytics';
import { useTags } from './useTags';
import { PushSubscriptionDialog } from 'dialogs/PushSubscriptionDialog';

export const PublicSpaces = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const tags = useTags();

  const { loading, data } = usePublicSpaces({
    input: {
      tags,
    },
  });

  const sortedSpaces = React.useMemo(
    () => data?.publicSpaces?.slice(0)?.sort((a, b) => (moment(a.updatedAt).isBefore(b.updatedAt) ? 1 : -1)) ?? [],
    [data]
  );

  const latestSpaces = React.useMemo(() => sortedSpaces.slice(0, 2), [sortedSpaces]);
  const activeSpaces = React.useMemo(
    () => sortedSpaces.filter((space) => space.numActiveUsers > 0).sort((a, b) => b.numActiveUsers - a.numActiveUsers),
    [sortedSpaces]
  );
  const emptySpaces = React.useMemo(() => sortedSpaces.filter((space) => space.numActiveUsers === 0), [sortedSpaces]);

  const renderSpace = (title: string, spaces: PublicSpaceCardProps[]) => {
    return (
      <LandingAccordion defaultExpanded title={title}>
        <FBoxV flexGrow={1}>
          {spaces.map((space) => (
            <FBoxV key={space.slug} pb={1} flexGrow={1}>
              <PublicSpaceCard {...space} />
            </FBoxV>
          ))}
        </FBoxV>
      </LandingAccordion>
    );
  };

  if (loading) {
    return (
      <FBoxV flex={1}>
        <LoadingComponent />
      </FBoxV>
    );
  } else if (tags) {
    const onClearFilter = (withoutTag: string) => {
      analytics.trackPublicSpacesClearFilter(withoutTag);
      const newTags = tags.filter((tag) => tag !== withoutTag);
      if (newTags.length) {
        history.push(`/?tag=${newTags.join(',')}`);
      } else {
        history.push('/');
      }
    };

    return (
      <FBoxV p={2}>
        <Typography style={{ color: 'white', marginBottom: 16 }} variant="h4">
          {t('publicSpaces.filtered')}
        </Typography>
        <FBox mb="64px">
          {tags.map((tag) => (
            <Button
              key={tag}
              style={{ marginRight: 8 }}
              variant="contained"
              onClick={() => onClearFilter(tag)}
              endIcon={<ClearIcon />}
            >
              #{tag}
            </Button>
          ))}
        </FBox>
        {sortedSpaces.map((space) => (
          <FBoxV key={space.slug} pb={1} flexGrow={1}>
            <PublicSpaceCard {...space} />
          </FBoxV>
        ))}
        <PushSubscriptionDialog tags={tags} empty={activeSpaces.length === 0} />
      </FBoxV>
    );
  } else {
    return (
      <FBoxV>
        {renderSpace(t('publicSpaces.latest'), latestSpaces)}
        {renderSpace(t('publicSpaces.active'), activeSpaces)}
        {renderSpace(t('publicSpaces.empty'), emptySpaces)}
      </FBoxV>
    );
  }
};
