import { useGotoTable } from './urls';
import { useJoinSocialTable } from 'social.gql';

interface Props {
  spaceId: string;
  spaceSlug: string;
}
export function useJoinAndGotoTable(props: Props) {
  const gotoTable = useGotoTable();
  const [joinSocialTable] = useJoinSocialTable();

  return async (tableId: string) => {
    const joined = await joinSocialTable({
      variables: {
        input: {
          spaceId: props.spaceId,
          tableId,
        },
      },
    });
    const nextTableId = joined?.data?.joinSocialTable?.table?.id;
    const nextFeedCredentials = joined?.data?.joinSocialTable?.videoChatCredentials;
    if (nextTableId && nextFeedCredentials) {
      gotoTable(props.spaceSlug, nextTableId, nextFeedCredentials);
    }
  };
}
