import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';

import { CreateSocialSpace, CreateSocialSpaceVariables } from 'gql/types/CreateSocialSpace';
import { SocialSpaceSlugs } from 'gql/types/SocialSpaceSlugs';

const GET_SOCIAL_SPACE_SLUGS = gql`
  query SocialSpaceSlugs {
    socialSpaces {
      slug
    }
  }
`;

export function useGetSocialSpaceSlugs(onCompleted?: (data: SocialSpaceSlugs) => void) {
  return useQuery<SocialSpaceSlugs, {}>(GET_SOCIAL_SPACE_SLUGS, {
    onCompleted,
    fetchPolicy: 'no-cache',
  });
}

const CREATE_SOCIAL_SPACE = gql`
  mutation CreateSocialSpace($input: CreateSocialSpaceInput!) {
    createSocialSpace(input: $input) {
      space {
        id
        slug
        name
      }
      error {
        type
      }
    }
  }
`;

export function useCreateSocialSpace(onCompleted?: (data: CreateSocialSpace) => void) {
  return useMutation<CreateSocialSpace, CreateSocialSpaceVariables>(CREATE_SOCIAL_SPACE, {
    onCompleted,
    awaitRefetchQueries: true,
    refetchQueries: ['JoinedSocialSpaces', 'OwnedSocialSpaces'],
  });
}
