import _ from 'lodash';
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TimerIcon from '@material-ui/icons/Timer';
import LockIcon from '@material-ui/icons/Lock';
import NoMeetingRoomIcon from '@material-ui/icons/NoMeetingRoom';

import { style, FBoxV } from '@badger/design-system';

import {
  TablesWithUsers_socialSpace_tables,
  TablesWithUsers_socialSpace_tables_users,
} from 'gql/types/TablesWithUsers';
import { useUserId } from 'auth';

import { SocialProfileListItem } from './SocialProfileListItem';
import { JoinTableButton } from './JoinTableButton';
import { SocialTableActionTag } from 'gql/types/globals';
import { SocialSpaceContext } from '../context';
import { SocialSpaceFormatRoaming } from 'gql/types/globals';

// TODO put somewhere common
import { useGetSocialSpaceFormat } from '../manage/format.gql';

type Table = TablesWithUsers_socialSpace_tables;
type User = TablesWithUsers_socialSpace_tables_users;

const useStyles = makeStyles({
  tableOverview: {
    width: '100%',
    marginTop: 8,
    border: '1px solid',
    borderColor: style.colors.primary,
  },
  currentTableHighlight: {
    borderColor: style.colors.secondary,
  },
});

interface Props {
  table: Table;
  onTable?: boolean;
}

export const TableOverview = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { spaceSlug } = React.useContext(SocialSpaceContext);
  const { data } = useGetSocialSpaceFormat({ slug: spaceSlug });

  const currentUserId = useUserId();
  const currentUserIndex = _.findIndex(props.table.users, (user) => user.id === currentUserId);
  const localUser = currentUserIndex === -1 ? null : props.table.users[currentUserIndex];
  const isCurrentTable = currentUserIndex !== -1;

  const users = props.table.users.filter((user) => user.id !== currentUserId);

  const renderProfile = (user: User, localUser: boolean, showInviteToOneOnOne: boolean) => {
    const profile = user.profile;

    return (
      <SocialProfileListItem
        userId={user.id}
        key={profile.id}
        name={profile.name}
        socialBlurb={profile.socialBlurb}
        jobTitle={profile.jobTitle}
        company={profile.company}
        twitter={profile.twitter}
        linkedIn={profile.linkedIn}
        localUser={localUser}
        showInviteToOneOnOne={showInviteToOneOnOne}
      />
    );
  };

  const showOneOnOneInvite = () => {
    if (
      data?.socialSpace?.format.roaming === SocialSpaceFormatRoaming.NO_ROAMING ||
      !data?.socialSpace?.format.enableOneOnOne
    ) {
      return false;
    }
    return !localUser || users.length > 1;
  };

  const renderProfiles = () => {
    return (
      <FBoxV>
        {localUser && renderProfile(localUser, true, false)}
        {users.map((user) => renderProfile(user, false, showOneOnOneInvite()))}
      </FBoxV>
    );
  };

  const renderAction = () => {
    if (currentUserIndex !== -1) {
      return;
    } else if (props.table.actionTags.includes(SocialTableActionTag.ONE_ON_ONE)) {
      return (
        <Button disabled startIcon={<LockIcon />} variant="contained" color="primary">
          {t('tableOverview.private')}
        </Button>
      );
    } else if (props.table.joinable) {
      if (props.table.actionTags.includes(SocialTableActionTag.BLOWING_UP)) {
        return (
          <Button disabled startIcon={<TimerIcon />} variant="contained" color="primary">
            {t('tableOverview.blowingUp')}
          </Button>
        );
      } else {
        return <JoinTableButton tableId={props.table.id} fromTable={props.onTable} />;
      }
    } else {
      return (
        <Button disabled startIcon={<NoMeetingRoomIcon />} variant="contained" color="primary">
          {t('tableOverview.full')}
        </Button>
      );
    }
  };

  return (
    <Paper
      variant="outlined"
      className={`${classes.tableOverview} ${isCurrentTable ? classes.currentTableHighlight : ''}`}
    >
      <FBoxV p={1}>
        {renderProfiles()}
        <FBoxV mt={1}>{renderAction()}</FBoxV>
      </FBoxV>
    </Paper>
  );
};
