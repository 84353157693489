import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  title: string;
  message: string;
  retry: () => void;
  ignore: () => void;
}

export function RetryErrorDialog(props: Props) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={props.open}
      onClose={props.ignore}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.ignore} color="primary">
          {t('retryErrorDialog.ignore')}
        </Button>
        <Button onClick={props.retry} color="primary" autoFocus>
          {t('retryErrorDialog.retry')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
