import gql from 'graphql-tag';

import { PublicSpaces, PublicSpacesVariables } from 'gql/types/PublicSpaces';
import { useLiveQuery } from 'hooks/useLiveQuery';

export const PUBLIC_SPACES = gql`
  query PublicSpaces($input: PublicSpacesInput!) {
    publicSpaces(input: $input) {
      id
      slug
      name
      numActiveUsers
      updatedAt
      tags
    }
  }
`;

export function usePublicSpaces(variables: PublicSpacesVariables) {
  return useLiveQuery<PublicSpaces, PublicSpacesVariables>(PUBLIC_SPACES, {
    variables,
  });
}
