import React from 'react';

import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FBox, style } from '@badger/design-system';
import { ReactComponent as MicIcon } from 'assets/icons/videoControls/mic.svg';
import { ReactComponent as MicOffIcon } from 'assets/icons/videoControls/micOff.svg';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    backgroundColor: '#ffffffcc',
    borderRadius: '21px',
    overflow: 'hidden',
    opacity: 0.9,
    alignItems: 'center',
  },
  meter: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    backgroundColor: style.colors.primary,
    width: 0,
    zIndex: -1,
  },
  message: {
    marginLeft: 8,
    marginRight: 16,
  },
  micOn: {
    color: 'white',
  },
  micNoSignal: {
    color: style.colors.warning,
  },
  micOff: {
    color: style.colors.error,
  },
});

interface Props {
  audioTrack?: MediaStreamTrack;
}

function createAudioContext() {
  const AudioContext =
    window.AudioContext || // Default
    //@ts-ignore
    window.webkitAudioContext; // Safari and old versions of Chrome
  return new AudioContext();
}

function createMediaStreamTrackSource(audioContext: AudioContext, audioTrack: MediaStreamTrack) {
  // Only Firefox so far
  if (typeof audioContext.createMediaStreamTrackSource === 'function') {
    return audioContext.createMediaStreamTrackSource(audioTrack);
  }
  const stream = new MediaStream();
  stream.addTrack(audioTrack);
  return audioContext.createMediaStreamSource(stream);
}

export function MicrophoneMeter(props: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const meterRef = React.useRef<HTMLDivElement>();

  const [receivedInput, setReceivedInput] = React.useState(true);
  const noInputTimer = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    setReceivedInput(true);
    if (noInputTimer.current) {
      clearTimeout(noInputTimer.current);
    }
    if (props.audioTrack) {
      const audioContext = createAudioContext();
      const microphone = createMediaStreamTrackSource(audioContext, props.audioTrack);
      const analyser = audioContext.createAnalyser();
      analyser.smoothingTimeConstant = 0.8;
      analyser.fftSize = 32;
      microphone.connect(analyser);

      let running = true;
      const sample = new Uint8Array(analyser.frequencyBinCount);
      const updateMeter = () => {
        if (running && meterRef.current) {
          analyser.getByteFrequencyData(sample);
          const average = sample.reduce((a, b) => a + b) / sample.length;
          if (average > 0) {
            if (noInputTimer.current) {
              clearTimeout(noInputTimer.current);
            }
            setReceivedInput(true);
          } else {
            noInputTimer.current = setTimeout(() => {
              setReceivedInput(false);
            }, 1000);
          }
          meterRef.current.style.width = `${average / 2.55}%`;
          requestAnimationFrame(updateMeter);
        }
      };
      requestAnimationFrame(updateMeter);
      return () => {
        running = false;
        if (meterRef.current) {
          meterRef.current.style.width = '0';
        }
      };
    }
  }, [props.audioTrack]);

  return (
    <FBox className={classes.root}>
      <div
        ref={(e) => {
          meterRef.current = e ?? undefined;
        }}
        className={classes.meter}
      />
      {props.audioTrack?.enabled ? (
        receivedInput ? (
          <MicIcon className={classes.micOn} />
        ) : (
          <MicOffIcon className={classes.micNoSignal} />
        )
      ) : (
        <MicOffIcon className={classes.micOff} />
      )}
      <Typography className={classes.message} variant="body1">
        {!props.audioTrack?.enabled
          ? t('microphoneMeter.muted')
          : receivedInput
          ? t('microphoneMeter.ok')
          : t('microphoneMeter.noSignal')}
      </Typography>
    </FBox>
  );
}
