import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
import { VideoConstraints } from './VideoConstraints';
import { captureException } from 'logrocket';

const GRID_GAP = 5;

const useStyles = makeStyles({
  videoLayoutRoot: {
    position: 'relative',
    minWidth: '250px',
    flexGrow: 1,
    '& >*': {
      transition: 'all 2s',
    },
  },
  videoElement: {
    position: 'absolute',
    // for VideoPreview
    paddingTop: '0',
    '& >*': {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    animation: '$fadeIn 2s',
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
});

interface ElementSize {
  bestCols: number;
  leftOffset: number;
  bestWidth: number;
  bestHeight: number;
}

interface Props {
  children: React.ReactNode;
}

const ResizeObserver = window.ResizeObserver || Polyfill;

export const VideoLayout = (props: Props) => {
  const resizeRef = React.useRef<HTMLDivElement | null>();
  const classes = useStyles();
  const [size, setSize] = React.useState<ElementSize | null>(null);

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      try {
        const size = entries[0].contentRect;
        //count also counts false
        //const elements = React.Children.count(props.children);
        const elements = React.Children.toArray(props.children).filter((child) => !!child).length;

        let maxArea = 0;
        let bestCols = 0;
        let bestWidth = 0;
        let bestHeight = 0;
        for (let rows = 1; rows <= elements; ++rows) {
          const cols = Math.ceil(elements / rows);
          const maxWidth = size.width / cols;
          const maxHeight = size.height / rows;
          const width = Math.min(maxWidth, maxHeight * VideoConstraints.aspectRatio);
          const height = Math.min(maxHeight, maxWidth / VideoConstraints.aspectRatio);
          const area = width * height;
          if (area > maxArea) {
            maxArea = area;
            bestCols = cols;
            bestWidth = width;
            bestHeight = height;
          }
        }

        const leftOffset = (size.width - bestCols * bestWidth + GRID_GAP) / 2;

        setSize({ bestCols, leftOffset, bestWidth, bestHeight });
      } catch (error) {
        captureException(error);
      }
    });
    if (resizeRef.current) {
      resizeObserver.observe(resizeRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [props.children]);

  const renderChildren = React.useCallback(() => {
    const children = React.Children.toArray(props.children).filter((child) => !!child);

    if (!size) {
      return;
    }
    return children.map((element, index) => {
      const col = index % size.bestCols;
      const row = Math.floor(index / size.bestCols);

      const value = element.valueOf();
      const key = (typeof value === 'object' && (value as { key?: string }).key) || index;

      return (
        <div
          key={`videoElement${key}`}
          className={classes.videoElement}
          style={{
            left: `${size.leftOffset + col * size.bestWidth}px`,
            width: `${size.bestWidth - GRID_GAP}px`,
            top: `${row * size.bestHeight}px`,
            height: `${size.bestHeight - GRID_GAP}px`,
          }}
        >
          {element}
        </div>
      );
    });
  }, [classes.videoElement, props.children, size]);

  return (
    <div className={classes.videoLayoutRoot} ref={(ref: HTMLDivElement) => (resizeRef.current = ref)}>
      {renderChildren()}
    </div>
  );
};
