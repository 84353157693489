import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import moment from 'moment';
import 'moment/locale/de';

import { de, en } from './localization';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    detection: {
      order: ['navigator'],
    },
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

function updateServiceWorkerLanguage(language: string) {
  navigator.serviceWorker.ready.then((registration) => {
    registration?.active?.postMessage({ language });
  });
}

i18n.on('languageChanged', (language) => {
  moment.locale(language);
  updateServiceWorkerLanguage(language);
});

moment.locale(i18n.language);
updateServiceWorkerLanguage(i18n.language);

export default i18n;
