import _ from 'lodash';
import * as React from 'react';
import { captureException } from '@sentry/browser';
import { Paper, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  FBox,
  FBoxV,
  ProminentButton,
  ProminentTextField,
  ContentGrid,
  OuterPageContainer,
} from '@badger/design-system';

import { useRegisterGuest } from 'auth.gql';
import { useHasAccount } from 'auth';
import Analytics from 'analytics';

import { useCreateSocialSpace, useGetSocialSpaceSlugs } from './Landing.gql';
import { PublicSpaces } from 'components';
import { LandingAccordion } from 'components/LandingAccordion';
import analytics from 'analytics';
import { slackWebhook } from 'config';
import { useTags } from 'components/useTags';

const PUBLIC_SPACE_SURVEY = 'https://form.questionscout.com/602ef05cc441eb417285c862';

const useStyles = makeStyles({
  container: {
    paddingTop: '16px',
    height: '100%',
    justifyContent: 'space-between',
  },
  createContainer: {
    padding: 16,
    paddingBottom: 0,
    flexDirection: 'column',
    flexGrow: 1,
  },
  inputField: {
    flexGrow: 1,
    '& .MuiInputBase-root': {
      borderRadius: 8,
    },
  },
  inputButton: {
    marginTop: 16,
    marginLeft: 4,
  },
});

export const Landing = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [spaceName, setSpaceName] = React.useState('');
  // starting with a space to keep the height consistent. otherwise textfield will
  // jump up when we show an error text.
  const [nameError, setNameError] = React.useState(' ');
  const publicSpaceName = React.useRef<HTMLInputElement | null>(null);
  const [registerGuest] = useRegisterGuest();
  const tags = useTags();
  const history = useHistory();
  const userHasAccount = useHasAccount();

  const [createSocialSpace] = useCreateSocialSpace((data) => {
    if (data.createSocialSpace && !data.createSocialSpace.error && data.createSocialSpace.space) {
      history.push(`in/${data.createSocialSpace.space.slug}/manage`, data.createSocialSpace.space);
    }
  });

  const { data } = useGetSocialSpaceSlugs();

  const setName = (newName: string) => {
    setSpaceName(newName);
    setNameError(' ');
  };

  const onCreateSpace = async () => {
    if (spaceName === '') {
      setNameError(t('landing.createSpaceSection.nameEmptyError'));
    }

    const slug = _.kebabCase(spaceName);

    if (data) {
      if (data.socialSpaces.some((space) => space.slug === slug)) {
        setNameError(t('landing.createSpaceSection.alreadyExistsError'));
      }
    }

    try {
      if (!userHasAccount) {
        const registerGuestResult = await registerGuest();

        if (!registerGuestResult.data || registerGuestResult.data.signUpGuest.error) {
          //TODO: Handle error.
        }
      }

      await createSocialSpace({
        variables: {
          input: {
            name: spaceName,
            slug,
          },
        },
      });

      Analytics.trackCreateSocialSpace();
    } catch (error) {
      captureException(error);
    }
  };

  const onRequestPublicSpace = (event: React.FormEvent<HTMLFormElement>) => {
    analytics.trackLandingRequestPublicSpace();
    event.currentTarget.submit();

    if (slackWebhook) {
      const spaceName = publicSpaceName.current?.value;
      const text = `<!here|here> Public space requested: "${spaceName}"`;
      fetch(slackWebhook, {
        method: 'POST',
        body: JSON.stringify({
          text,
        }),
      });
    }

    event.currentTarget.reset();
    return false;
  };

  return (
    <OuterPageContainer className={classes.container}>
      <ContentGrid>
        <FBoxV>
          <PublicSpaces />
          {!tags && (
            <>
              <LandingAccordion title={t('landing.newPublicSpace.title')} defaultExpanded>
                <Paper className={classes.createContainer}>
                  <form action={PUBLIC_SPACE_SURVEY} method="get" target="_blank" onSubmit={onRequestPublicSpace}>
                    <Typography variant="body1">{t('landing.newPublicSpace.message')}</Typography>
                    <FBox alignItems="flex-start" mb={2}>
                      <ProminentTextField
                        inputRef={(e) => (publicSpaceName.current = e)}
                        className={classes.inputField}
                        name="space"
                        label={t('landing.newPublicSpace.placeholder')}
                      />
                      <ProminentButton
                        className={classes.inputButton}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        {t('landing.newPublicSpace.submit')}
                      </ProminentButton>
                    </FBox>
                  </form>
                </Paper>
              </LandingAccordion>
              <LandingAccordion id="newPrivateSpace" title={t('landing.newPrivateSpace.title')}>
                <Paper className={classes.createContainer}>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      onCreateSpace();
                    }}
                    onReset={(event) => {
                      event.preventDefault();
                      setName('');
                    }}
                  >
                    <Typography variant="body1">{t('landing.newPrivateSpace.message')}</Typography>
                    <FBox alignItems="flex-start">
                      <ProminentTextField
                        className={classes.inputField}
                        error={nameError !== ' '}
                        helperText={nameError}
                        label={t('landing.newPrivateSpace.placeholder')}
                        value={spaceName}
                        onChange={(event) => setName(event.target.value)}
                      />
                      <ProminentButton
                        className={classes.inputButton}
                        disabled={nameError !== ' '}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        {t('landing.newPrivateSpace.submit')}
                      </ProminentButton>
                    </FBox>
                  </form>
                </Paper>
              </LandingAccordion>
            </>
          )}
        </FBoxV>
      </ContentGrid>
      <FBox ml={1} alignItems="center">
        <Typography variant="caption" style={{ color: 'white' }}>
          {t('landing.copyright')}
        </Typography>
        <Link href="https://www.humby.io/legal/imprint">
          <Typography
            style={{ marginLeft: 4, color: 'white', textDecoration: 'underline' }}
            align="center"
            variant="caption"
          >
            {t('landing.imprint')}
          </Typography>
        </Link>
      </FBox>
    </OuterPageContainer>
  );
};
