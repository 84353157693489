import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Box, Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingButton, FBoxV, FBoxVCenter, FBox } from '@badger/design-system';

import { ReactComponent as JoinBroadcastIcon } from 'assets/icons/joinBroadcast.svg';

import Analytics from 'analytics';
import { SocialSpaceContext } from 'components/socialSpace';

import {
  useJoinBroadcast,
  useKickFromBroadcast,
  useLeaveBroadcast,
  useStartBroadcast,
  useStopBroadcast,
} from './Broadcast.gql';
import { logger } from 'logging';
import { VideoStreamContext } from 'components/socialSpace/VideoStreamContext';
import { VideoPreview } from 'components/socialSpace/VideoPreview';
import { VideoLayout } from 'components/socialSpace/table/VideoLayout';
import { BroadcastView } from 'components/socialSpace/BroadcastView';
import { useCountdown } from 'hooks/countdown';
import { usePublishBroadcast } from 'hooks/publishBroadcast';
import { useUserId } from 'auth';
import { useHistory, useLocation } from 'react-router-dom';
import { useAgoraClient } from 'hooks/agoraClient';
import { useAgoraRemoteUsers } from 'hooks/agoraRemoteUsers';
import analytics from 'analytics';
import { BroadcasterType, KickFromBroadcastAction } from 'gql/types/globals';
import { AgoraScreenshare } from 'components/socialSpace/AgoraScreenshare';
import { useWatchBroadcast } from 'hooks/watchBroadcast';
import { ScreenshareView } from 'components/socialSpace/ScreenshareView';
import { parseBroadcasterType } from 'util/broadcast';

interface RouteStateProps {
  credentials?: {
    uid: string;
    channelName: string;
    token: string;
  };
}

const useStyles = makeStyles({
  broadcast: {
    alignItems: 'stretch',
    alignContent: 'stretch',
    flexGrow: 1,
    height: '100%',
  },
  message: {
    padding: '1vh',
    color: 'white',
  },
  videoArea: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: '1vh',
    paddingBottom: '0.5vh',
  },
  videoBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    //padding: '5vw',
  },
  actionPlaceholder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  startStopButton: {
    height: 56,
    minWidth: 120,
    padding: '0 30px',
  },
  '@keyframes pulse': {
    '0% 100%': {
      opacity: '1',
    },
    '50%': {
      opacity: '0.5',
    },
  },
});

export function Broadcast() {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation<RouteStateProps>();
  const { spaceId, spaceSlug, spaceTablesUsers } = React.useContext(SocialSpaceContext);

  const [startBroadcast, { loading: startLoading }] = useStartBroadcast();
  const [stopBroadcast, { loading: stopLoading }] = useStopBroadcast();

  const [joinBroadcast] = useJoinBroadcast();
  const [leaveBroadcast] = useLeaveBroadcast();

  const { credentials: watchingCredentials } = useWatchBroadcast();
  const {
    client: watchingClient,
    connectionState: watchingConnectionState,
    connect: watchingConnect,
  } = useAgoraClient();
  const [hosts] = useAgoraRemoteUsers(watchingClient);

  const { client, connectionState, connect, disconnect } = useAgoraClient();
  const { publish, unpublish, published } = usePublishBroadcast(client);

  const [kickFromBroadcast] = useKickFromBroadcast();

  const [countdown, startCountdown] = useCountdown();

  const startingTimer = React.useRef<NodeJS.Timeout>();

  const { audioTrack, videoTrack } = React.useContext(VideoStreamContext);

  const userId = useUserId();

  const broadcastRunning = React.useMemo(() => spaceTablesUsers?.broadcasting, [spaceTablesUsers]);
  const isOrganizer = React.useMemo(() => spaceTablesUsers?.owner?.id === userId, [spaceTablesUsers, userId]);

  const stopStream = React.useCallback(async () => {
    unpublish();
    disconnect();
    if (startingTimer.current) {
      clearTimeout(startingTimer.current);
      startingTimer.current = undefined;
    }
    startCountdown(undefined);

    await stopBroadcast({
      variables: {
        input: {
          spaceId,
        },
      },
    });
  }, [disconnect, spaceId, startCountdown, stopBroadcast, unpublish]);

  const pushToLobby = React.useCallback(
    (message?: string) => {
      // Throw away credentials, otherwise guest can just go back
      history.replace(history.location.pathname, []);
      history.push(`/in/${spaceSlug}/lobby`, { message });
    },
    [history, spaceSlug]
  );

  React.useEffect(() => {
    if (location.state?.credentials) {
      connect(location.state.credentials, 'host').then(publish);
    } else if (!isOrganizer) {
      pushToLobby(t('broadcast.inviteNeeded'));
    }
  }, [connect, isOrganizer, location.state, publish, pushToLobby, t]);

  React.useEffect(() => {
    if (connectionState === 'KICKED') {
      if (isOrganizer) {
        unpublish();
      } else {
        pushToLobby(t('broadcast.kicked'));
      }
    }
  }, [connectionState, isOrganizer, pushToLobby, t, unpublish]);

  React.useEffect(() => {
    if (watchingCredentials && watchingConnectionState !== 'CONNECTING' && watchingConnectionState !== 'CONNECTED') {
      watchingConnect(watchingCredentials);
    }
  }, [watchingConnect, watchingConnectionState, watchingCredentials]);

  React.useEffect(() => {
    const leave = async () => {
      unpublish();
      await leaveBroadcast({
        variables: {
          input: {
            spaceId,
          },
        },
      });
      // Throw away credentials, otherwise guest can just go back
      history.replace(history.location.pathname, []);
    };
    window.addEventListener('beforeunload', leave);
    window.addEventListener('pagehide', leave);
    return () => {
      leave();
      window.removeEventListener('beforeunload', leave);
      window.removeEventListener('pagehide', leave);
    };
  }, [history, leaveBroadcast, spaceId, unpublish]);

  const onStart = async () => {
    if (client && (audioTrack || videoTrack)) {
      const broadcast = await startBroadcast({
        variables: {
          input: {
            spaceId,
          },
        },
      });
      if (broadcast.data?.startBroadcast.error || !broadcast.data?.startBroadcast.broadcastCredentials) {
        logger.error('Starting broadcast failed', { broadcast });
        return;
      }
      const credentials = broadcast.data.startBroadcast.broadcastCredentials;
      startCountdown(7);
      await connect(credentials, 'host');
      startingTimer.current = setTimeout(async () => {
        publish();
        startingTimer.current = undefined;
      }, 4000);

      startCountdown(6);
    }
  };

  const onStop = () => {
    Analytics.trackBroadcastStop();
    stopStream();
  };

  const onJoin = async () => {
    analytics.trackBroadcastJoin();
    const broadcast = await joinBroadcast({
      variables: {
        input: {
          spaceId,
        },
      },
    });
    if (broadcast.data?.joinBroadcast.error || !broadcast.data?.joinBroadcast.broadcastCredentials) {
      logger.error('Joining broadcast failed', { broadcast });
      return;
    }

    const credentials = broadcast.data.joinBroadcast.broadcastCredentials;
    await connect(credentials, 'host');
    publish();
  };

  const onLeave = async () => {
    analytics.trackBroadcastLeave();
    await leaveBroadcast({
      variables: {
        input: {
          spaceId,
        },
      },
    });
    if (!isOrganizer) {
      pushToLobby();
    }
  };

  const onAskToLeave = (uid: string) => {
    analytics.trackBroadcastAskToLeave();
    kickFromBroadcast({
      variables: {
        input: {
          spaceId,
          uid: parseInt(uid),
          action: KickFromBroadcastAction.ASK_TO_LEAVE,
        },
      },
    });
    // TODO confirmation notification
  };

  const onKick = (uid: string) => {
    analytics.trackBroadcastKick();
    kickFromBroadcast({
      variables: {
        input: {
          spaceId,
          uid: parseInt(uid),
          action: KickFromBroadcastAction.KICK_NOW,
        },
      },
    });
    // TODO confirmation notification
  };

  const renderStartStopButton = () => {
    const onStartStop = () => {
      if (broadcastRunning) {
        onStop();
      } else {
        onStart();
      }
    };

    const text =
      countdown !== undefined
        ? t('broadcast.starting', { count: countdown })
        : broadcastRunning
        ? t('broadcast.stop')
        : t('broadcast.start');

    return (
      <LoadingButton
        disabled={!broadcastRunning && !audioTrack && !videoTrack}
        loading={startLoading || stopLoading}
        onClick={onStartStop}
        variant="contained"
        color={broadcastRunning ? 'secondary' : 'primary'}
        className={classes.startStopButton}
      >
        {text}
      </LoadingButton>
    );
  };

  return (
    <FBoxV className={classes.broadcast}>
      <FBoxVCenter className={classes.message} flexShrink={0}>
        <Box mb="1vh">
          <Typography align="center" variant="h6">
            {t('broadcast.title')}
          </Typography>
        </Box>
        <Typography align="center" variant="body1">
          {isOrganizer ? t('broadcast.message') : t('broadcast.guestMessage')}
        </Typography>
      </FBoxVCenter>
      <VideoLayout>
        {!broadcastRunning || countdown !== undefined || published ? (
          <Box className={classes.videoBox}>
            <VideoPreview />
            {published && (
              <Button onClick={onLeave} variant="contained" color="primary">
                {t('broadcast.leave')}
              </Button>
            )}
          </Box>
        ) : (
          isOrganizer &&
          hosts.size < 2 && (
            <Box className={classes.videoBox}>
              <Paper onClick={onJoin} className={classes.actionPlaceholder}>
                <JoinBroadcastIcon />
              </Paper>
              <Button onClick={onJoin} variant="contained" color="primary">
                {t('broadcast.join')}
              </Button>
            </Box>
          )
        )}
        {broadcastRunning && published && hosts.size === 0 && <AgoraScreenshare />}
        {Array.from(hosts.entries()).map(([uid, host]) => (
          <Box key={uid} className={classes.videoBox}>
            {parseBroadcasterType(uid) === BroadcasterType.SCREENSHARE ? (
              <>
                <ScreenshareView user={host} />
                {isOrganizer && (
                  <Button onClick={() => onKick(uid)} variant="contained" color="primary">
                    {t('broadcast.stopTheirScreenshare')}
                  </Button>
                )}
              </>
            ) : (
              <>
                <BroadcastView user={host} />
                <FBox>
                  {isOrganizer ? (
                    <>
                      <Button onClick={() => onAskToLeave(uid)} variant="contained" color="primary">
                        {t('broadcast.askToLeave')}
                      </Button>
                      <Button onClick={() => onKick(uid)} variant="contained" color="primary">
                        {t('broadcast.kick')}
                      </Button>
                    </>
                  ) : (
                    <Typography align="center" variant="h6" style={{ color: 'white' }}>
                      {t('broadcast.screenshareInfo')}
                    </Typography>
                  )}
                </FBox>
              </>
            )}
          </Box>
        ))}
      </VideoLayout>
      {isOrganizer && (
        <FBoxVCenter p="1vh" pb="2vh">
          {renderStartStopButton()}
        </FBoxVCenter>
      )}
    </FBoxV>
  );
}
