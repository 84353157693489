import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link, MenuList, MenuItem, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Analytics from 'analytics';

import { useJoinedSocialSpaces, useOwnedSocialSpaces } from './NavBarSpacesSubMenu.gql';

const useStyles = makeStyles({
  subList: {
    marginLeft: '8px',
    color: 'inherit',
    textDecoration: 'none',
  },
});

interface Props {
  spaceId?: string;
  onClose?: () => void;
}

export function NavBarSpacesSubMenu(props: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [manageOpen, setManageOpen] = React.useState(false);
  const [joinOpen, setJoinOpen] = React.useState(false);

  const { data: ownedSpacesData } = useOwnedSocialSpaces();
  const { data: joinedSpacesData } = useJoinedSocialSpaces();

  const onOpenManage = () => {
    if (manageOpen) {
      Analytics.trackNavBarMenuCloseManageSubMenu();
    } else {
      Analytics.trackNavBarMenuOpenManageSubMenu();
    }
    setManageOpen(!manageOpen);
  };
  const onClickManage = () => {
    Analytics.trackNavBarMenuClickManage();
    props.onClose?.();
  };

  const onOpenJoin = () => {
    if (joinOpen) {
      Analytics.trackNavBarMenuCloseJoinSubMenu();
    } else {
      Analytics.trackNavBarMenuOpenJoinSubMenu();
    }
    setJoinOpen(!joinOpen);
  };
  const onClickJoin = () => {
    Analytics.trackNavBarMenuClickJoin();
    props.onClose?.();
  };

  const renderOwnedSubMenu = () => (
    <>
      <MenuItem button onClick={onOpenManage}>
        {t('navBarSpacesSubMenu.manage')}
        {manageOpen ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <Collapse in={manageOpen} timeout="auto" unmountOnExit>
        <MenuList disablePadding>
          {ownedSpacesData?.ownedSocialSpaces.map((space) => (
            <MenuItem
              key={space.slug}
              onClick={onClickManage}
              component={Link}
              target="_blank"
              href={`/in/${space.slug}/manage`}
              className={classes.subList}
            >
              {space.id === props.spaceId ? <b>{space.name}</b> : space.name}
            </MenuItem>
          ))}
        </MenuList>
      </Collapse>
    </>
  );

  const renderJoinedSubMenu = () => (
    <>
      <MenuItem button onClick={onOpenJoin}>
        {t('navBarSpacesSubMenu.join')}
        {joinOpen ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <Collapse in={joinOpen} timeout="auto" unmountOnExit>
        <MenuList disablePadding>
          {joinedSpacesData?.joinedSocialSpaces.map((space) => (
            <MenuItem
              key={space.slug}
              onClick={onClickJoin}
              component={Link}
              target="_blank"
              href={`/in/${space.slug}`}
              className={classes.subList}
            >
              {space.id === props.spaceId ? <b>{space.name}</b> : space.name}
            </MenuItem>
          ))}
        </MenuList>
      </Collapse>
    </>
  );

  return (
    <>
      {!!ownedSpacesData?.ownedSocialSpaces.length && renderOwnedSubMenu()}
      {!!joinedSpacesData?.joinedSocialSpaces.length && renderJoinedSubMenu()}
    </>
  );
}
