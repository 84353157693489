import * as React from 'react';

import { TablesWithUsers_socialSpace } from 'gql/types/TablesWithUsers';

interface SocialSpaceContextType {
  spaceId: string;
  spaceSlug: string;
  loading: boolean;
  spaceTablesUsers: TablesWithUsers_socialSpace | null;
}

export const SocialSpaceContext = React.createContext<SocialSpaceContextType>({
  spaceId: '',
  spaceSlug: '',
  loading: true,
  spaceTablesUsers: null,
});
