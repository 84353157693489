import React from 'react';

import { logger } from 'logging';
import { SocialSpaceContext } from 'components/socialSpace';
import { useWatchBroadcastCredentials } from './watchBroadcast.gql';
import { WatchBroadcastCredentials_watchBroadcast_broadcastCredentials } from 'gql/types/WatchBroadcastCredentials';

export function useWatchBroadcast() {
  const { spaceId, spaceTablesUsers } = React.useContext(SocialSpaceContext);
  const [startsInSeconds, setStartsInSeconds] = React.useState<number>();
  const [watchBroadcastCredentials] = useWatchBroadcastCredentials();

  const [credentials, setCredentials] = React.useState<WatchBroadcastCredentials_watchBroadcast_broadcastCredentials>();

  React.useEffect(() => {
    if (!spaceTablesUsers?.broadcasting) {
      setCredentials(undefined);
      return;
    }
    const init = async () => {
      const broadcast = await watchBroadcastCredentials({
        variables: {
          input: {
            spaceId,
          },
        },
      });
      if (broadcast.data?.watchBroadcast.error || !broadcast.data?.watchBroadcast.broadcastCredentials) {
        logger.error('Watching broadcast failed', { broadcast });
        return;
      }
      const startsInSeconds = Math.ceil(5 - (broadcast.data.watchBroadcast.duration ?? 5));
      if (startsInSeconds > 0) {
        setStartsInSeconds(startsInSeconds);
      } else {
        setStartsInSeconds(undefined);
      }
      setCredentials(broadcast.data.watchBroadcast.broadcastCredentials);
    };
    init();
  }, [spaceId, spaceTablesUsers, watchBroadcastCredentials]);

  return { credentials, startsInSeconds };
}
