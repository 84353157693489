import * as React from 'react';

import { FormControlLabel, Switch } from '@material-ui/core';

import { SocialSpaceContext } from 'components/socialSpace';

import { useSetSocialSpacePublic, useGetSocialSpacePublic } from './SetSpacePublicSwitch.gql';
import { useRole } from 'auth';
import { Role } from 'gql/types/globals';

export function SetSpacePublicSwitchInner() {
  const { spaceId } = React.useContext(SocialSpaceContext);
  const { data, loading } = useGetSocialSpacePublic({
    input: {
      id: spaceId,
    },
  });
  const [setPublic] = useSetSocialSpacePublic();

  const onSetPublic = (isPublic: boolean) => {
    setPublic({
      variables: {
        input: {
          spaceId,
          isPublic,
        },
      },
    });
  };

  return (
    <FormControlLabel
      control={
        <Switch
          disabled={loading}
          checked={!!data?.socialSpace?.isPublic}
          onChange={(event) => onSetPublic(event.target.checked)}
          color="primary"
        />
      }
      label="public"
      labelPlacement="start"
    />
  );
}

export function SetSpacePublicSwitch() {
  const role = useRole();

  return role === Role.ADMIN ? <SetSpacePublicSwitchInner /> : null;
}
