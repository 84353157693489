import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';

import { QuerySocialSpaceInput } from 'gql/types/globals';
import { SocialSpaceFormat, SocialSpaceFormatVariables } from 'gql/types/SocialSpaceFormat';
import { SetSocialSpaceFormat, SetSocialSpaceFormatVariables } from 'gql/types/SetSocialSpaceFormat';

const SOCIAL_SPACE_FORMAT_FRAGMENT = gql`
  fragment SocialSpaceFormatFragment on SocialSpaceFormat {
    id
    seatsSoftLimit
    seatsHardLimit
    seatsBlowUpLimit
    roaming
    shuffling
    peerToPeer
    enableOneOnOne
  }
`;

const GET_SOCIAL_SPACE_FORMAT = gql`
  query SocialSpaceFormat($input: QuerySocialSpaceInput!) {
    socialSpace(input: $input) {
      id
      format {
        ...SocialSpaceFormatFragment
      }
    }
  }
  ${SOCIAL_SPACE_FORMAT_FRAGMENT}
`;

export function useGetSocialSpaceFormat(input: QuerySocialSpaceInput, onCompleted?: (data: SocialSpaceFormat) => void) {
  return useQuery<SocialSpaceFormat, SocialSpaceFormatVariables>(GET_SOCIAL_SPACE_FORMAT, {
    variables: {
      input,
    },
    onCompleted,
  });
}

const SET_SOCIAL_SPACE_FORMAT = gql`
  mutation SetSocialSpaceFormat($input: SetSocialSpaceFormatInput!) {
    setSocialSpaceFormat(input: $input) {
      space {
        id
        format {
          ...SocialSpaceFormatFragment
        }
      }
      error {
        type
      }
    }
  }
  ${SOCIAL_SPACE_FORMAT_FRAGMENT}
`;

export function useSetSocialSpaceFormat(onCompleted?: (data: SetSocialSpaceFormat) => void) {
  return useMutation<SetSocialSpaceFormat, SetSocialSpaceFormatVariables>(SET_SOCIAL_SPACE_FORMAT, {
    onCompleted,
  });
}
