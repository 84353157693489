import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@material-ui/core';

import { InviteMenu, useInviteMenu } from '../InviteMenu';
import analytics from 'analytics';

export const ShareLinkButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const { onShare, onClose, anchorEl, share } = useInviteMenu();

  const onOpen = (event: React.MouseEvent) => {
    analytics.trackManageShare();
    onShare(event);
  };

  return (
    <>
      <Button {...props} onClick={onOpen}>
        {t('manageSpace.shareLink')}
      </Button>
      <InviteMenu onClose={onClose} anchorEl={anchorEl} share={share} />
    </>
  );
};
