import React from 'react';

import {
  Participant,
  AudioTrack,
  VideoTrack,
  Track,
  TrackPublication,
  RemoteTrackPublication,
  LocalTrackPublication,
} from 'twilio-video';
import { VideoContainer } from './VideoContainer';

function isAudioTrack(track: Track): track is AudioTrack {
  return track.kind === 'audio';
}

function isVideoTrack(track: Track): track is VideoTrack {
  return track.kind === 'video';
}

function isRemoteTrackPublication(publication: TrackPublication): publication is RemoteTrackPublication {
  return (publication as RemoteTrackPublication).isSubscribed !== undefined;
}

function isLocalTrackPublication(publication: TrackPublication): publication is LocalTrackPublication {
  return !isRemoteTrackPublication(publication) && (publication as LocalTrackPublication).track !== undefined;
}

interface Props {
  participant: Participant;
}

export function TwilioVideoContainer(props: Props) {
  const [audioTrack, setAudioTrack] = React.useState<AudioTrack>();
  const [videoTrack, setVideoTrack] = React.useState<VideoTrack>();
  const [audioMuted, setAudioMuted] = React.useState(false);
  const [videoMuted, setVideoMuted] = React.useState(false);

  const userId = props.participant.identity.split('-').slice(-1)[0];

  React.useEffect(() => {
    props.participant.tracks.forEach((publication) => {
      if (isRemoteTrackPublication(publication) && publication.isSubscribed && publication.track) {
        if (isVideoTrack(publication.track)) {
          setVideoTrack(publication.track);
        }
        if (isAudioTrack(publication.track)) {
          setAudioTrack(publication.track);
        }
      }
      if (isLocalTrackPublication(publication)) {
        if (isVideoTrack(publication.track)) {
          setVideoTrack(publication.track);
        }
        if (isAudioTrack(publication.track)) {
          setAudioTrack(publication.track);
        }
      }
    });
  }, [props.participant]);

  React.useEffect(() => {
    const trackSubscribed = (track: Track) => {
      if (isVideoTrack(track)) {
        setVideoTrack(track);
      }
      if (isAudioTrack(track)) {
        setAudioTrack(track);
      }
    };

    const trackUnsubscribed = (track: Track) => {
      if (isVideoTrack(track)) {
        setVideoTrack(undefined);
      }

      if (isAudioTrack(track)) {
        setAudioTrack(undefined);
      }
    };

    props.participant.on('trackSubscribed', trackSubscribed);
    props.participant.on('trackStarted', trackSubscribed);
    props.participant.on('trackUnsubscribed', trackUnsubscribed);
    props.participant.on('trackUnpublished', trackUnsubscribed);
    props.participant.on('trackStopped', trackUnsubscribed);

    return () => {
      props.participant.off('trackSubscribed', trackSubscribed);
      props.participant.off('trackStarted', trackSubscribed);
      props.participant.off('trackUnsubscribed', trackUnsubscribed);
      props.participant.off('trackUnpublished', trackUnsubscribed);
      props.participant.off('trackStopped', trackUnsubscribed);
    };
  }, [audioTrack, videoTrack, props.participant]);

  React.useEffect(() => {
    if (audioTrack) {
      setAudioMuted(!audioTrack.isEnabled);

      const muted = () => {
        setAudioMuted(true);
      };

      const unmuted = () => {
        setAudioMuted(false);
      };

      audioTrack.on('disabled', muted);
      audioTrack.on('enabled', unmuted);
      return () => {
        audioTrack.off('disabled', muted);
        audioTrack.off('enabled', unmuted);
      };
    }
  }, [audioTrack]);

  React.useEffect(() => {
    if (videoTrack) {
      setVideoMuted(!videoTrack.isEnabled);

      const muted = () => {
        setVideoMuted(true);
      };

      const unmuted = () => {
        setVideoMuted(false);
      };

      videoTrack.on('disabled', muted);
      videoTrack.on('enabled', unmuted);
      return () => {
        videoTrack.off('disabled', muted);
        videoTrack.off('enabled', unmuted);
      };
    }
  }, [videoTrack]);

  return (
    <VideoContainer
      userId={userId}
      audioTrack={audioMuted ? undefined : audioTrack?.mediaStreamTrack}
      videoTrack={videoMuted ? undefined : videoTrack?.mediaStreamTrack}
    />
  );
}
