import React from 'react';
import { useTranslation } from 'react-i18next';
import { duration } from 'moment';

import { Button, Accordion, AccordionSummary, AccordionDetails, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { style } from '@badger/design-system';

import { FBox, FBoxV } from '@badger/design-system';

import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useTimeout } from 'hooks/timeout';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    backgroundColor: style.colors.backgroundDark,
    zIndex: 0,
    '& .MuiTypography-root': {
      color: '#ffffff',
    },
    '& .MuiSvgIcon-root': {
      color: '#ffffff',
    },
    '& .Mui-expanded': {
      '& $expandIcon': {
        transform: 'rotate(180deg)',
        transition: 'transform 150ms',
      },
    },
    '& .MuiAccordionSummary-root': {
      minHeight: '36px',
      padding: 0,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  expandIcon: {},
});

interface Props {
  snackKey: string;
  name: string;
  socialBlurb: string;
  jobTitle: string;
  company: string;
  action: () => void;
  dismiss: () => void;
  timeout: () => void;
}
export function InvitedSnackBar(props: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const { startTimer, cancelTimer } = useTimeout();

  let subtitle = '';
  if (props.jobTitle && props.company) {
    subtitle = t('socialProfile.jobTitleAtCompany', { jobTitle: props.jobTitle, company: props.company });
  } else {
    subtitle = props.jobTitle || props.company;
  }
  const name = props.name || t('socialProfile.noNamePlaceholder');

  const onAction = () => {
    closeSnackbar(props.snackKey);
    cancelTimer();

    props.action();
  };

  const onDismiss = () => {
    closeSnackbar(props.snackKey);
    cancelTimer();

    props.dismiss();
  };

  React.useEffect(() => {
    startTimer(() => {
      closeSnackbar(props.snackKey);
      props.timeout();
    }, duration(2, 'minutes'));
  }, [closeSnackbar, props, startTimer]);

  return (
    <Accordion elevation={0} className={classes.root}>
      <AccordionSummary>
        <FBox justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
          <FBox>
            <PeopleAltIcon />
            <ExpandMoreIcon className={classes.expandIcon} />
          </FBox>
          <Typography>{t('invite.inviteToOneOnOne', { name })}</Typography>
          <FBox
            ml={1}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Button onClick={onAction} variant="contained" color="secondary">
              {t('invite.accept')}
            </Button>
            <IconButton size="small" onClick={onDismiss} aria-label={t('invite.dismiss')}>
              <CloseIcon />
            </IconButton>
          </FBox>
        </FBox>
      </AccordionSummary>
      <AccordionDetails>
        <FBoxV p={1}>
          <FBox justifyContent="space-between" alignItems="center">
            <Typography variant="h6">{name}</Typography>
          </FBox>
          {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
          {props.socialBlurb && (
            <Typography noWrap variant="body1">
              {props.socialBlurb}
            </Typography>
          )}
        </FBoxV>
      </AccordionDetails>
    </Accordion>
  );
}
