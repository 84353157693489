import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FBox, FBoxV } from '@badger/design-system';
import { ActionSnackBar } from './ActionSnackBar';

const useStyles = makeStyles({
  links: {
    '& > a': {
      color: 'white',
    },
  },
});

interface Props {
  snackKey: string;
  onVote: (vote: number) => void;
  onDismiss: () => void;
}

export function NPSSnackBar(props: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { closeSnackbar } = useSnackbar();

  const onDismiss = () => {
    props.onDismiss();
  };

  const onVote = (vote: number) => {
    props.onVote(vote);
    closeSnackbar(props.snackKey);
  };

  return (
    <ActionSnackBar snackKey={props.snackKey} noAction dismiss={onDismiss}>
      <FBoxV alignContent="stretch">
        <Typography variant="caption">{t('npsSnackBar.title')}</Typography>
        <FBox className={classes.links} mt={1} justifyContent="space-between">
          {Array.from({ length: 10 }, (v, i) => (
            <Link key={i} component="button" variant="h5" onClick={() => onVote(i + 1)}>
              {i + 1}
            </Link>
          ))}
        </FBox>
        <FBox justifyContent="space-between">
          <Typography variant="body2">{t('npsSnackBar.footerLeft')}</Typography>
          <Typography variant="body2">{t('npsSnackBar.footerRight')}</Typography>
        </FBox>
      </FBoxV>
    </ActionSnackBar>
  );
}
