import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { playTrack } from '../VideoStreamContext';
import { logger } from 'logging';

const useStyles = makeStyles({
  videoRoot: {
    maxWidth: '70vw',
    maxHeight: '100%',
    flexShrink: 1,
    display: 'flex',
  },
  video: {
    width: '100%',
    objectFit: 'contain',
    //objectPosition: '0 0',
  },
});

interface Props {
  screenTrack?: MediaStreamTrack;
}

export function ScreenshareContainer(props: Props) {
  const classes = useStyles();

  const videoRef = React.useRef<HTMLVideoElement>();

  React.useEffect(() => {
    if (videoRef.current) {
      if (props.screenTrack) {
        playTrack(videoRef.current, props.screenTrack);
      } else {
        videoRef.current.srcObject = null;
      }
    }
  }, [props.screenTrack]);

  logger.debug('Screenshare', props.screenTrack);

  return (
    <div className={classes.videoRoot}>
      <video
        hidden={!props.screenTrack || !props.screenTrack.enabled}
        className={classes.video}
        ref={(e: HTMLVideoElement) => {
          videoRef.current = e ?? undefined;
        }}
        autoPlay={false}
        muted
        controls={false}
      />
    </div>
  );
}
