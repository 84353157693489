import React, { Dispatch, SetStateAction } from 'react';

export function useCountdown(): [number | undefined, Dispatch<SetStateAction<number | undefined>>] {
  const timer = React.useRef<NodeJS.Timeout>();
  const [countdown, setCountdown] = React.useState<number>();

  const stopTimer = React.useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = undefined;
    }
  }, []);

  React.useEffect(() => {
    if (countdown === undefined) {
      stopTimer();
      return;
    }
    if (countdown > 0) {
      timer.current = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else {
      timer.current = setTimeout(() => setCountdown(undefined), 1000);
    }
  }, [countdown, stopTimer]);

  React.useEffect(() => {
    if (timer) {
      return () => {
        stopTimer();
      };
    }
  }, [stopTimer, timer]);

  return [countdown, setCountdown];
}
