import React from 'react';
import moment from 'moment';

export function useTimeout() {
  const [timer, setTimer] = React.useState<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    if (timer) {
      return () => {
        if (timer) {
          clearTimeout(timer);
          setTimer(null);
        }
      };
    }
  }, [timer]);

  const cancelTimer = React.useCallback(() => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
  }, [timer]);

  const startTimer = React.useCallback((fn: () => void, timeout: moment.Duration) => {
    setTimer(setTimeout(fn, timeout.asMilliseconds()));
  }, []);

  return { startTimer, cancelTimer, timerRunning: !!timer };
}
