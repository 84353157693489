import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { VideoStreamContext } from 'components/socialSpace/VideoStreamContext';
import { logger } from 'logging';
import { VideoConstraints } from 'components/socialSpace/table/VideoConstraints';
import { RetryErrorDialog } from 'dialogs';
import { useFilteredVideoTrack, VideoFilterType, VideoStreamFilter } from './VideoStreamFilter';
import { getLocal, setLocal } from 'local';

interface Props {
  children: React.ReactNode;
}

export const VideoStreamContextProvider = (props: Props) => {
  const { t } = useTranslation();

  const [audioMuted, setAudioMuted] = React.useState(getLocal('audioMuted') === 'true');
  const [videoMuted, setVideoMuted] = React.useState(getLocal('videoMuted') === 'true');
  const [audioDevice, setAudioDevice] = React.useState<string>(getLocal('audioDeviceId') ?? '');
  const [videoDevice, setVideoDevice] = React.useState<string>(getLocal('videoDeviceId') ?? '');

  const [initialized, setInitialized] = React.useState(false);

  const [audioTrack, setAudioTrack] = React.useState<MediaStreamTrack>();
  const [videoTrack, setVideoTrack] = React.useState<MediaStreamTrack>();

  const [videoFilter, setVideoFilter] = React.useState<VideoFilterType | null>(null);
  const filteredVideoTrack = useFilteredVideoTrack(videoTrack, videoFilter);

  const [error, setError] = React.useState<{ ignore: () => void; message: string; title: string } | null>();
  const [ignoreAudioError, setIgnoreAudioError] = React.useState(false);
  const [ignoreVideoError, setIgnoreVideoError] = React.useState(false);

  const changeAudioDevice = React.useCallback(async () => {
    try {
      const audio = audioDevice ? { deviceId: audioDevice } : true;
      const newStream = await navigator.mediaDevices.getUserMedia({ audio, video: false });
      const newTrack = newStream.getAudioTracks()[0];

      const actualDeviceId = newTrack?.getSettings().deviceId ?? '';
      if (actualDeviceId !== audioDevice) {
        setAudioDevice(actualDeviceId);
      }

      return newTrack;
    } catch (error) {
      if (!ignoreAudioError) {
        switch (error.name) {
          case 'NotAllowedError':
            setError({
              title: t('videoFeed.audioNotAllowedTitle'),
              message: t('videoFeed.audioNotAllowedMessage'),
              ignore: () => {
                setIgnoreAudioError(true);
                setError(null);
              },
            });
            break;
          case 'NotReadableError':
          case 'AbortError':
            setError({
              title: t('videoFeed.audioNotAvailableTitle'),
              message: t('videoFeed.audioNotAvailableMessage'),
              ignore: () => {
                setIgnoreAudioError(true);
                setError(null);
              },
            });
            break;
          default:
            setError({
              title: t('videoFeed.audioNotAvailableTitle'),
              message: t('videoFeed.audioNotAvailableMessage'),
              ignore: () => {
                setIgnoreAudioError(true);
                setError(null);
              },
            });
            logger.info('Unknown microphone initialization error', { error });
        }
      }
    }
  }, [audioDevice, ignoreAudioError, t]);

  React.useEffect(() => {
    if (initialized && !error) {
      if (audioTrack?.getSettings().deviceId !== audioDevice) {
        changeAudioDevice().then((newTrack) => {
          setAudioTrack(newTrack);
        });
      }
    }
  }, [audioDevice, audioTrack, changeAudioDevice, error, initialized]);

  const changeVideoDevice = React.useCallback(async () => {
    try {
      const video = videoDevice
        ? {
            deviceId: videoDevice,
            ...VideoConstraints,
          }
        : {
            ...VideoConstraints,
            facingMode: 'user',
          };
      const newStream = await navigator.mediaDevices.getUserMedia({ audio: false, video });
      const newTrack = newStream.getVideoTracks()[0];

      if (newTrack.readyState !== 'live') {
        // not sure if this can happen with real hardware or just fake cameras playing video
        logger.warn('Camera is seems to be unavailable, fallback to default one', { newTrack });
        setVideoDevice('');
      }
      // if the device wasn't available, the browser would choose another one, check which
      const actualDeviceId = newTrack.getSettings().deviceId ?? '';
      if (actualDeviceId !== videoDevice) {
        setVideoDevice(actualDeviceId);
      }

      //newTrack.enabled = !videoMuted;
      return newTrack;
    } catch (error) {
      if (!ignoreVideoError) {
        switch (error.name) {
          case 'NotAllowedError':
            setError({
              title: t('videoFeed.videoNotAllowedTitle'),
              message: t('videoFeed.videoNotAllowedMessage'),
              ignore: () => {
                setIgnoreVideoError(true);
                setError(null);
              },
            });
            break;
          case 'NotReadableError':
          case 'AbortError':
            setError({
              title: t('videoFeed.videoNotAvailableTitle'),
              message: t('videoFeed.videoNotAvailableMessage'),
              ignore: () => {
                setIgnoreVideoError(true);
                setError(null);
              },
            });
            break;
          default:
            setError({
              title: t('videoFeed.videoNotAvailableTitle'),
              message: t('videoFeed.videoNotAvailableMessage'),
              ignore: () => {
                setIgnoreVideoError(true);
                setError(null);
              },
            });
            logger.info('Unknown camera initialization error', { error });
        }
      }
    }
  }, [ignoreVideoError, t, videoDevice]);

  React.useEffect(() => {
    if (initialized && !error) {
      if (videoTrack?.getSettings().deviceId !== videoDevice) {
        changeVideoDevice().then((newTrack) => {
          setVideoTrack(newTrack);
        });
      }
    }
  }, [changeVideoDevice, error, initialized, videoDevice, videoTrack]);

  React.useEffect(() => {
    const audioDevice = getLocal('audioDeviceId');
    const videoDevice = getLocal('videoDeviceId');

    const audio = audioDevice ? { deviceId: audioDevice } : true;
    const video = videoDevice ? { deviceId: videoDevice } : { facingMode: 'user' };

    navigator.mediaDevices
      .getUserMedia({ audio, video })
      .then((stream) => {
        const videoTrack = stream.getVideoTracks()[0];
        const actualVideoDevice = videoTrack?.getSettings().deviceId;

        setVideoDevice(actualVideoDevice ?? '');
        setVideoTrack(videoTrack);

        const audioTrack = stream.getAudioTracks()[0];
        const actualAudioDevice = audioTrack?.getSettings().deviceId;

        setAudioDevice(actualAudioDevice ?? '');
        setAudioTrack(audioTrack);

        setInitialized(true);
      })
      .catch((error) => {
        logger.debug('Initializing stream failed', { error });
        setInitialized(true);
      });
  }, []);

  React.useEffect(() => {
    setLocal('audioMuted', `${audioMuted}`);
  }, [audioMuted]);

  React.useEffect(() => {
    setLocal('videoMuted', `${videoMuted}`);
  }, [videoMuted]);

  React.useEffect(() => {
    setLocal('audioDeviceId', audioDevice);
  }, [audioDevice]);

  React.useEffect(() => {
    setLocal('videoDeviceId', videoDevice);
  }, [videoDevice]);

  React.useEffect(() => {
    if (audioTrack) {
      return () => {
        audioTrack.stop();
      };
    }
  }, [audioTrack]);

  React.useEffect(() => {
    if (videoTrack) {
      return () => {
        videoTrack.stop();
      };
    }
  }, [videoTrack]);

  if (audioTrack) {
    audioTrack.enabled = !audioMuted;
  }
  if (videoTrack) {
    videoTrack.enabled = !videoMuted;
  }

  return (
    <VideoStreamContext.Provider
      value={{
        audioMuted,
        videoMuted,
        setAudioMuted,
        setVideoMuted,
        audioDevice,
        videoDevice,
        setAudioDevice,
        setVideoDevice,
        audioTrack: audioMuted ? undefined : audioTrack,
        videoTrack: videoMuted ? undefined : filteredVideoTrack,
        initialized,
        setVideoFilter,
      }}
    >
      <VideoStreamFilter />
      {props.children}
      {error && (
        <RetryErrorDialog
          open={!!error}
          title={error.title}
          message={error.message}
          ignore={error.ignore}
          retry={() => setError(null)}
        />
      )}
    </VideoStreamContext.Provider>
  );
};
