import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

import { WatchBroadcastCredentials, WatchBroadcastCredentialsVariables } from 'gql/types/WatchBroadcastCredentials';

const WATCH_BROADCAST = gql`
  mutation WatchBroadcastCredentials($input: WatchBroadcastInput!) {
    watchBroadcast(input: $input) {
      duration
      broadcastCredentials {
        uid
        channelName
        token
      }
      error {
        type
      }
    }
  }
`;

export function useWatchBroadcastCredentials(onCompleted?: (data: WatchBroadcastCredentials) => void) {
  return useMutation<WatchBroadcastCredentials, WatchBroadcastCredentialsVariables>(WATCH_BROADCAST, {
    onCompleted,
  });
}
