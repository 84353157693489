import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

import { InviteToOneOnOne, InviteToOneOnOneVariables } from 'gql/types/InviteToOneOnOne';
import { InviteToBroadcast, InviteToBroadcastVariables } from 'gql/types/InviteToBroadcast';
import { KickUser, KickUserVariables } from 'gql/types/KickUser';

export const INVITE_TO_ONE_ON_ONE = gql`
  mutation InviteToOneOnOne($input: InviteToOneOnOneInput!) {
    inviteToOneOnOne(input: $input) {
      error {
        type
      }
    }
  }
`;

export function useInviteToOneOnOne() {
  return useMutation<InviteToOneOnOne, InviteToOneOnOneVariables>(INVITE_TO_ONE_ON_ONE);
}

export const INVITE_TO_BROADCAST = gql`
  mutation InviteToBroadcast($input: InviteToBroadcastInput!) {
    inviteToBroadcast(input: $input) {
      error {
        type
      }
    }
  }
`;

export function useInviteToBroadcast() {
  return useMutation<InviteToBroadcast, InviteToBroadcastVariables>(INVITE_TO_BROADCAST);
}

export const KICK_USER = gql`
  mutation KickUser($input: KickUserInput!) {
    kickUser(input: $input) {
      error {
        type
      }
    }
  }
`;

export function useKickUser() {
  return useMutation<KickUser, KickUserVariables>(KICK_USER);
}
