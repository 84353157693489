import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { InvitedSnackBar, ActionSnackBar } from 'components/socialSpace/snackbar';
import { useSnackbar } from 'notistack';

import Analytics from 'analytics';

import {
  useUserSocialEventsSubscription,
  useAcceptInviteToOneOnOne,
  useDeclineInviteToOneOnOne,
  useAcceptInviteToBroadcast,
  useDeclineInviteToBroadcast,
} from './userSocialEvents.gql';
import { UserSocialEventType, DeclineInviteToOneOnOneReason, DeclineInviteToBroadcastReason } from 'gql/types/globals';
import { logger } from 'logging';
import { useGotoTable } from './urls';
import { duration } from 'moment';
import analytics from 'analytics';
import { useLeaveBroadcast } from 'pages/Broadcast.gql';

interface Props {
  spaceId: string;
  spaceSlug: string;
}

export function useUserSocialEvents(props: Props) {
  const { t } = useTranslation();
  const gotoTable = useGotoTable();
  const history = useHistory();

  const [acceptInviteToOneOnOne] = useAcceptInviteToOneOnOne((data) => {
    if (
      !data.acceptInviteToOneOnOne.error &&
      data.acceptInviteToOneOnOne.table &&
      data.acceptInviteToOneOnOne.videoChatCredentials
    ) {
      const tableId = data?.acceptInviteToOneOnOne?.table?.id;
      const feedCredentials = data?.acceptInviteToOneOnOne?.videoChatCredentials;

      gotoTable(props.spaceSlug, tableId, feedCredentials);
    }
  });
  const [declineInviteToOneOnOne] = useDeclineInviteToOneOnOne();

  const [acceptInviteToBroadcast] = useAcceptInviteToBroadcast((data) => {
    if (!data.acceptInviteToBroadcast.error && data.acceptInviteToBroadcast.broadcastCredentials) {
      history.push(`/in/${props.spaceSlug}/broadcast`, {
        credentials: data.acceptInviteToBroadcast.broadcastCredentials,
      });
    }
  });
  const [declineInviteToBroadcast] = useDeclineInviteToBroadcast();

  const [leaveBroadcast] = useLeaveBroadcast();

  const { enqueueSnackbar } = useSnackbar();

  useUserSocialEventsSubscription((message) => {
    const data = message.data?.userSocialEvents;
    if (!data) {
      return;
    }
    const key = data.id;
    switch (data.type) {
      case UserSocialEventType.INVITE_TO_ONE_ON_ONE: {
        const onAcceptInvite = () => {
          Analytics.trackSocialSpaceOneOnOneAccepted();

          acceptInviteToOneOnOne({
            variables: {
              input: {
                inviteId: data.id,
                spaceId: props.spaceId,
              },
            },
          });
        };
        const onDeclineInvite = (reason: DeclineInviteToOneOnOneReason) => {
          Analytics.trackSocialSpaceOneOnOneDeclined();

          declineInviteToOneOnOne({
            variables: {
              input: {
                inviteId: data.id,
                spaceId: props.spaceId,
                reason,
              },
            },
          }).then((result) => {
            if (!result?.data?.declineInviteToOneOnOne.error) {
              enqueueSnackbar(<ActionSnackBar snackKey={key} message={t('invite.declined')} noAction />, { key });
            }
          });
        };

        Analytics.trackSocialSpaceOneOnOneShown();

        enqueueSnackbar(
          <InvitedSnackBar
            {...data.sender.profile}
            snackKey={key}
            action={onAcceptInvite}
            dismiss={() => onDeclineInvite(DeclineInviteToOneOnOneReason.DISMISSED)}
            timeout={() => onDeclineInvite(DeclineInviteToOneOnOneReason.TIMEOUT)}
          />,
          {
            key,
            persist: true,
          }
        );
        break;
      }
      case UserSocialEventType.INVITE_TO_ONE_ON_ONE_ACCEPTED: {
        if (data.newTable?.id && data.videoChatCredentials) {
          logger.info('Invite accepted', { data });
          enqueueSnackbar(<ActionSnackBar snackKey={key} message={t('invite.wasAccepted')} noAction />, {
            key,
          });
          const tableId = data.newTable?.id;
          const feedCredentials = data.videoChatCredentials;

          gotoTable(props.spaceSlug, tableId, feedCredentials);
        }
        break;
      }
      case UserSocialEventType.INVITE_TO_ONE_ON_ONE_DECLINED: {
        enqueueSnackbar(<ActionSnackBar snackKey={key} message={t('invite.wasNotAccepted')} noAction />, {
          key,
        });
        break;
      }
      case UserSocialEventType.INVITE_TO_BROADCAST: {
        const onAcceptInvite = () => {
          Analytics.trackSocialSpaceInviteToBroadcastAccepted();

          acceptInviteToBroadcast({
            variables: {
              input: {
                inviteId: data.id,
                spaceId: props.spaceId,
              },
            },
          });
        };
        const onDeclineInvite = (reason: DeclineInviteToBroadcastReason) => {
          Analytics.trackSocialSpaceInviteToBroadcastDeclined();

          declineInviteToBroadcast({
            variables: {
              input: {
                inviteId: data.id,
                spaceId: props.spaceId,
                reason,
              },
            },
          }).then((result) => {
            if (!result?.data?.declineInviteToBroadcast.error) {
              enqueueSnackbar(<ActionSnackBar snackKey={key} message={t('inviteToBroadcast.declined')} noAction />, {
                key,
              });
            }
          });
        };

        Analytics.trackSocialSpaceInviteToBroadcastShown();

        enqueueSnackbar(
          <ActionSnackBar
            {...data.sender.profile}
            snackKey={key}
            message={t('inviteToBroadcast.invited')}
            action={onAcceptInvite}
            actionText={t('inviteToBroadcast.accept')}
            dismiss={() => onDeclineInvite(DeclineInviteToBroadcastReason.DISMISSED)}
            dismissText={t('inviteToBroadcast.decline')}
            duration={duration(1, 'minute')}
            timeout={() => onDeclineInvite(DeclineInviteToBroadcastReason.TIMEOUT)}
            urgent
          />,
          {
            key,
            persist: true,
          }
        );
        break;
      }
      case UserSocialEventType.INVITE_TO_BROADCAST_ACCEPTED: {
        logger.info('Broadcast invite accepted', { data });
        enqueueSnackbar(<ActionSnackBar snackKey={key} message={t('inviteToBroadcast.wasAccepted')} noAction />, {
          key,
        });
        break;
      }
      case UserSocialEventType.INVITE_TO_BROADCAST_DECLINED: {
        enqueueSnackbar(<ActionSnackBar snackKey={key} message={t('inviteToBroadcast.wasNotAccepted')} noAction />, {
          key,
        });
        break;
      }
      case UserSocialEventType.LEAVE_BROADCAST: {
        const onLeave = async () => {
          analytics.trackBroadcastLeaveViaNotification();
          await leaveBroadcast({
            variables: {
              input: {
                spaceId: props.spaceId,
              },
            },
          });
          // Throw away credentials, otherwise guest can just go back
          history.replace(history.location.pathname, []);
          history.push(`/in/${props.spaceSlug}/lobby`);
        };

        enqueueSnackbar(
          <ActionSnackBar
            snackKey={key}
            message={t('inviteToBroadcast.askedToLeave')}
            action={onLeave}
            actionText={t('inviteToBroadcast.leave')}
          />,
          {
            key,
          }
        );
        break;
      }
    }
  });
}
