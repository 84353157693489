import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';
import { FBoxV } from '@badger/design-system';

export const SpaceMissing = () => {
  const { t } = useTranslation();

  return (
    <FBoxV p={8} width="100%" justifyContent="center" alignItems="center" height="60vh">
      <Typography align="center" variant="h4">
        {t('spaceMissing.message')}
      </Typography>
    </FBoxV>
  );
};
