import React from 'react';

import { CircularProgress, IconButton, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import DeleteIcon from '@material-ui/icons/Delete';
import { ReactComponent as ProfilePictureIcon } from 'assets/icons/profilePicture.svg';

import { style } from '@badger/design-system';
import { VideoConstraints } from './table/VideoConstraints';
import { playTrack } from './VideoStreamContext';
import { useStoreImage, useUpdateProfile } from 'social.gql';
import { useUserProfile } from './table/VideoProfileOverlay.gql';
import { useUserId } from 'auth';
import { useLocalVideo } from './useLocalVideo';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    // FIXME hack until design system has all correct transparent colors
    backgroundColor: `${style.colors.backgroundDark}b2`,
    width: '100%',
    paddingTop: 'calc(100% + 20px)',
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
  },
  canvas: {
    color: 'white',
    position: 'absolute',
    top: 20,
    left: 20,
    width: 'calc(100% - 40px)',
    height: 'calc(100% - 60px)',
  },
  loading: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: '20px',
    margin: 'auto',
  },
  hidden: {
    display: 'none',
  },
  buttonLeft: {
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  buttonMiddle: {
    position: 'absolute',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    bottom: 0,
  },
  buttonRight: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
});

export function OwnProfilePicture() {
  const classes = useStyles();

  const videoTrack = useLocalVideo();
  const [pictureState, setPictureState] = React.useState<'cleared' | 'videoPreview' | 'snapshot'>('cleared');

  const [storeImage, { loading: storeImageLoading }] = useStoreImage();
  const [setProfilePicture, { loading: updateProfileLoading }] = useUpdateProfile();
  const userId = useUserId();
  const { data: profileData, loading: profileLoading } = useUserProfile(userId ?? '');

  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const uploadRef = React.useRef<HTMLInputElement | null>(null);

  const loading = storeImageLoading || updateProfileLoading || profileLoading;

  React.useEffect(() => {
    const pictureUrl = profileData?.userProfile?.profile.pictureUrl;
    if (pictureUrl) {
      const canvas = canvasRef.current;
      if (!canvas) {
        return;
      }

      const image = new Image();
      image.onload = () => {
        const square = Math.min(image.width, image.height);

        canvas.width = square;
        canvas.height = square;

        const context = canvas.getContext('2d');
        if (!context) {
          return;
        }

        context.drawImage(image, 0, 0);
      };
      image.src = pictureUrl;
      setPictureState('snapshot');
    }
  }, [profileData]);

  React.useEffect(() => {
    if (!videoTrack || pictureState !== 'videoPreview') {
      return;
    }
    const video = document.createElement('video');
    playTrack(video, videoTrack);

    const width = videoTrack.getSettings().width ?? VideoConstraints.width;
    const height = videoTrack.getSettings().height ?? VideoConstraints.height;
    const frameRate = videoTrack.getSettings().frameRate ?? VideoConstraints.frameRate;

    const square = Math.min(width, height);
    const leftOffset = (square - width) / 2;
    const topOffset = (square - height) / 2;

    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    canvas.width = square;
    canvas.height = square;

    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    context.beginPath();
    context.arc(square / 2, square / 2, square / 2, 0, 2 * Math.PI);
    context.clip();

    let running = true;
    const loop = () => {
      if (running && !video.ended) {
        if (!video.paused) {
          context.drawImage(video, leftOffset, topOffset);
        }
        setTimeout(loop, 1000 / frameRate);
      }
    };
    loop();
    return () => {
      running = false;
    };
  }, [pictureState, videoTrack]);

  const storeSnapshot = async () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const store = storeImage();
      canvas.toBlob(async (blob) => {
        const putUrl = (await store).data?.storeImage.putUrl;
        const pictureUrl = (await store).data?.storeImage.pictureUrl;

        if (putUrl) {
          const response = await fetch(putUrl, {
            method: 'PUT',
            body: blob,
            headers: {
              'x-amz-acl': 'public-read',
            },
          });
          if (response.ok) {
            await setProfilePicture({
              variables: {
                input: {
                  pictureUrl,
                },
              },
            });
          }
        }
      }, 'image/png');
    }
  };

  const deleteSnapshot = async () => {
    if (profileLoading || profileData?.userProfile?.profile.pictureUrl) {
      await setProfilePicture({
        variables: {
          input: {
            pictureUrl: '',
          },
        },
      });
    }
  };

  const onUpload = () => {
    setPictureState('snapshot');
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const file = uploadRef.current?.files?.[0];
    const url = window.URL || window.webkitURL;

    const image = new Image();
    image.onload = () => {
      const square = Math.min(image.width, image.height);
      const leftOffset = (square - image.width) / 2;
      const topOffset = (square - image.height) / 2;

      canvas.width = square;
      canvas.height = square;

      const context = canvas.getContext('2d');
      if (!context) {
        return;
      }

      context.beginPath();
      context.arc(square / 2, square / 2, square / 2, 0, 2 * Math.PI);
      context.clip();

      context.drawImage(image, leftOffset, topOffset);
      storeSnapshot();
    };

    image.src = url.createObjectURL(file);
  };

  const onSwitchToCamera = () => {
    setPictureState('videoPreview');
  };

  const onTakeSnapshot = () => {
    setPictureState('snapshot');
    storeSnapshot();
  };

  const onClear = () => {
    setPictureState('cleared');
    deleteSnapshot();
    // clear canvas to avoid flickering when re-enabling
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    canvas.width = 0;
  };

  return (
    <Paper className={classes.root}>
      <ProfilePictureIcon className={classes.canvas} />
      <canvas className={`${classes.canvas}`} ref={canvasRef} />
      {loading && <CircularProgress className={classes.loading} />}
      {pictureState === 'cleared' && (
        <>
          <div className={classes.buttonLeft}>
            <input
              ref={uploadRef}
              type="file"
              accept="image/*"
              id="uploadImage"
              onChange={onUpload}
              className={classes.hidden}
            />
            <label htmlFor="uploadImage">
              <IconButton component="span">
                <AddPhotoAlternateIcon />
              </IconButton>
            </label>
          </div>
          <IconButton className={classes.buttonMiddle} onClick={onSwitchToCamera}>
            <PhotoCameraIcon />
          </IconButton>
        </>
      )}
      {pictureState === 'videoPreview' && (
        <IconButton className={classes.buttonMiddle} onClick={onTakeSnapshot}>
          <PhotoCameraIcon />
        </IconButton>
      )}
      {pictureState !== 'cleared' && (
        <IconButton className={classes.buttonRight} onClick={onClear}>
          <DeleteIcon />
        </IconButton>
      )}
    </Paper>
  );
}
